






import { defineComponent } from "@vue/composition-api";
import LoggedInHeader from "@/components/headers/LoggedInHeader/LoggedInHeader.vue";

export default defineComponent({
  name: "Header",
  components: {
    LoggedInHeader,
  },
});
