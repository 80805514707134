import http from "@/core/services/http.service";
import { AxiosResponse } from "axios";
import { PartialDeep } from "type-fest";

import {
  BaseResponseType,
  CustomerRegistrationRequestType,
  ForgottenPasswordRequest,
  ProfileDetails,
  LoginRequest,
  LoginResponse,
  NewForgottenPasswordRequest,
  SessionData,
  User,
  ChangeProfileDetailsRequest,
  ChangeEmailTokenRequestType,
  ChangeEmailActivationTokenRequest,
  CreateBillingAddressRequestType,
  ChangeProfileDetails,
  PasswordChangeRequest,
  ChangeEmailRequest,
  CampaignMessage,
} from "../dataTypes/types";
import {
  transformCustomerRegistration,
  transformProfileDetails,
  transformLoginResponse,
  transformSessionData,
  transformCampaignMessages,
} from "../transformers/customerService.transformer";
import { userFacade } from "@/store/modules/user/user.facade";
import * as StickerService from "@/core/services/sticker.service";

export const login = (requestData: LoginRequest): Promise<LoginResponse> =>
  http
    .post("CustomerService/customer/hd/auth/login", {
      ...requestData,
    })
    .then((response) => transformLoginResponse(response.data));

export const logout = (refreshToken: string): Promise<AxiosResponse<void>> =>
  http.get("CustomerService/customer/hd/auth/logout", {
    headers: { refreshToken },
  });

export const postForgottenPassword = (
  requestData: ForgottenPasswordRequest
): Promise<void> =>
  http
    .post<never>(
      "CustomerService/customer/hd/forgottenPassword/token",
      requestData
    )
    .then((response) => {
      return response.data;
    });

export const putForgottenPassword = (
  requestData: NewForgottenPasswordRequest
): Promise<void> =>
  http
    .put<never>("CustomerService/customer/hd/forgottenPassword", requestData)
    .then((response) => {
      return response.data;
    });

export const getForgottenPasswordToken = (token: string): Promise<void> =>
  http
    .get<never>(`CustomerService/customer/hd/forgottenPassword/token/${token}`)
    .then((response) => {
      return response.data;
    });

export const refresh = (refreshToken: string): Promise<SessionData> => {
  return http
    .get<BaseResponseType & { sessionData: SessionData }>(
      "CustomerService/customer/hd/auth/refresh",
      {
        headers: { refreshToken },
      }
    )
    .then((response) => transformSessionData(response.data.sessionData));
};

export const changePassword = (
  requestData: PasswordChangeRequest,
  userId: string
): Promise<void> =>
  http
    .put<never>(
      `CustomerService/customer/hd/${userId}/changePassword`,
      requestData
    )
    .then((response) => {
      return response.data;
    });

//https://confluence.icellmobilsoft.hu/display/HUGO/modifyCustomer
export const changeProfileDetails = (
  requestData: ChangeProfileDetails
): Promise<ChangeProfileDetailsRequest> =>
  http
    .put<never>(`CustomerService/customer/hd/${requestData.customerId}`, {
      baseCustomerDataType: requestData,
    })
    .then((response) => {
      return response.data;
    });

//https://confluence.icellmobilsoft.hu/display/HUGO/postChanegeEmailToken
export const changeEmail = (
  requestData: ChangeEmailTokenRequestType
): Promise<void> =>
  http
    .post<never>("CustomerService/customer/hd/email/token", requestData)
    .then((response) => {
      return response.data;
    });

//https://confluence.icellmobilsoft.hu/display/HUGO/getChangeEmailToken
export const getChangeEmailToken = (
  requestData: ChangeEmailActivationTokenRequest
): Promise<void> =>
  http
    .get<never>(
      `CustomerService/customer/hd/${requestData.customerNumberEncrypted}/email/${requestData.token}`
    )
    .then((response) => {
      return response.data;
    });

//https://confluence.icellmobilsoft.hu/pages/viewpage.action?spaceKey=HUGO&title=putChangeEmail
export const putChangeEmail = (
  customerId: string,
  requestData: ChangeEmailRequest
): Promise<void> =>
  http
    .put<never>(`CustomerService/customer/hd/${customerId}/email`, requestData)
    .then((response) => {
      return response.data;
    });

export const getCustomer = (userId: string): Promise<ProfileDetails> =>
  http.get(`CustomerService/customer/hd/${userId}`).then((response) => {
    return transformProfileDetails(response.data);
  });

export const createCustomer = (
  requestData: PartialDeep<CustomerRegistrationRequestType>
): Promise<Partial<User>> =>
  http
    .post("CustomerService/customer/hd/", requestData)
    .then((response) => transformCustomerRegistration(response.data));

export const deleteCustomer = (customerId: string): Promise<unknown> =>
  http.delete(`CustomerService/customer/hd/${customerId}`).then((response) => {
    return response.data;
  });

export const activateCustomer = (token: string): Promise<void> =>
  http
    .get<never>(`CustomerService/customer/hd/activationcode/${token}`)
    .then((response) => {
      return response.data;
    });

export const getBillingAddressList = (customerId: string): Promise<unknown> =>
  http
    .get(`CustomerService/customer/hd/${customerId}/address`)
    .then((response) => {
      return response.data;
    });

export const getBillingAddress = (
  customerId: string,
  addressId: string
): Promise<unknown> => {
  const config = {
    headers: {
      ...(userFacade.notLoggedInUser() && {
        "X-ADHOC-ID": StickerService.getAdHocCartToken(),
      }),
    },
  };
  return http
    .get(
      `CustomerService/customer/hd/${customerId}/address/${addressId}`,
      config
    )
    .then((response) => {
      return response.data;
    });
};

export const deleteBillingAddress = (
  customerId: string,
  addressId: string
): Promise<unknown> =>
  http
    .delete(`CustomerService/customer/hd/${customerId}/address/${addressId}`)
    .then((response) => {
      return response.data;
    });

export const createBillingAddress = (
  customerId: string,
  requestData: CreateBillingAddressRequestType
): Promise<unknown> => {
  const config = {
    headers: {
      ...(userFacade.notLoggedInUser() && {
        "X-ADHOC-ID": StickerService.getAdHocCartToken(),
      }),
    },
  };
  const path = userFacade.notLoggedInUser()
    ? `/CustomerService/customer/hd/address/adhoc`
    : `/CustomerService/customer/hd/${customerId}/address`;
  return http.post(path, requestData, config).then((response) => response.data);
};

export const modifyBillingAddress = (
  customerId: string | unknown,
  addressId: string | unknown,
  requestData: CreateBillingAddressRequestType
): Promise<unknown> => {
  const config = {
    headers: {
      ...(userFacade.notLoggedInUser() && {
        "X-ADHOC-ID": StickerService.getAdHocCartToken(),
      }),
    },
  };
  return http.put(
    `/CustomerService/customer/hd/${customerId}/address/${addressId}`,
    requestData,
    config
  );
};

export const putChangeExpiredPassword = (
  requestData: PasswordChangeRequest,
  customerId: string
): Promise<unknown> =>
  http.put(
    `/CustomerService/customer/hd/${customerId}/changeExpiredPassword`,
    requestData
  );

export const getCampaignMessages = (
  customerId: string
): Promise<CampaignMessage[]> =>
  http
    .get(`/CustomerService/customer/hd/campaignMessages/${customerId}`)
    .then((response) => transformCampaignMessages(response.data));

export const getAcknowledgeCampaignMessage = (
  customerId: string,
  campaignMessageId: string
): Promise<unknown> =>
  http.get(
    `/CustomerService/customer/hd/campaignMessages/${customerId}/${campaignMessageId}`
  );
