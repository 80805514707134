




















import { computed, defineComponent } from "@vue/composition-api";
import { userFacade } from "@/store/modules/user/user.facade";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "ForgottenPasswordEmailSentPage",
  setup() {
    const forgottenPasswordEmailAddress = computed(
      userFacade.forgottenPasswordEmailAddress
    );
    return {
      forgottenPasswordEmailAddress,
    };
  },
  metaInfo() {
    return {
      titleTemplate: `${i18n.tc(
        "FORGOTTEN_PASSWORD_PAGE.EMAIL_SENT.PAGE_TITLE"
      )} | %s`,
    };
  },
});
