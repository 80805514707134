import { NavigationGuardNext, Route } from "vue-router";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import { userFacade } from "@/store/modules/user/user.facade";
import * as StickerService from "@/core/services/sticker.service";
import { ERoutes } from "@/core/dataTypes/enum";
import { getLanguageStaticRoute } from "@/core/utils/router.util";

export const successPaymentGuard = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
): void => {
  const notLoggedInUser = userFacade.notLoggedInUser();
  const cartId = notLoggedInUser
    ? StickerService.getAdHocCartId()
    : (stickerFacade.cartId() as string);
  switch (true) {
    case !notLoggedInUser && !cartId:
      next(
        getLanguageStaticRoute(
          `${ERoutes.STICKER_PURCHASE}/${ERoutes.STICKER_CREATION}`
        )
      );
      break;
    case notLoggedInUser && !StickerService.getAdHocCartToken():
      next(
        getLanguageStaticRoute(
          `${ERoutes.STICKER_PURCHASE}/${ERoutes.STICKER_CREATION}`
        )
      );
      break;
    default:
      next();
  }
};
