






























































import { defineComponent, PropType } from "@vue/composition-api";
import HTMLContentRenderer from "@/components/HTMLContentRenderer/HTMLContentRenderer.vue";
import { Content } from "leaflet";

export default defineComponent({
  name: "ContentPage",
  components: {
    HTMLContentRenderer,
  },
  props: {
    content: {
      type: Object as PropType<Content>,
      required: true,
    },
  },
});
