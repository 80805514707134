var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{ref:"autoCompleteElement",attrs:{"id":_vm.id,"items":_vm.items,"name":_vm.name,"label":_vm.label,"disabled":_vm.disabled,"filled":_vm.filled,"multiple":_vm.multiple,"chips":_vm.chips,"small-chips":_vm.smallChips,"deletable-chips":_vm.deletableChips,"clearable":_vm.clearable,"auto-select-first":_vm.autoSelectFirst,"hide-details":_vm.hideDetails,"hide-no-data":_vm.hideNoData,"hint":_vm.hint,"loading":_vm.loading,"eager":_vm.eager,"return-object":_vm.returnObject,"item-text":_vm.itemText,"item-value":_vm.itemValue,"autocomplete":_vm.autocomplete,"error-messages":_vm.errorMessages,"required":_vm.required,"tabindex":_vm.tabindex,"placeholder":_vm.placeholder,"aria-label":_vm.ariaLabel,"aria-required":_vm.ariaRequired,"aria-describedby":((!!_vm.ariaDescribedBy ? _vm.ariaDescribedBy : '') + " " + _vm.id + "--error"),"aria-invalid":_vm.invalid},on:{"input":_vm.onInput,"keyup":_vm.onKeyUp,"blur":_vm.onBlur,"click:clear":_vm.clear},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.required)?_c('span',[_vm._v(_vm._s(_vm.label)),_c('sup',{staticClass:"pl-1"},[_vm._v("*")])]):_c('span',[_vm._v(_vm._s(_vm.label))])]},proxy:true},{key:"message",fn:function(ref){
  var message = ref.message;
return [_c('span',{attrs:{"id":(_vm.id + "--error")}},[_vm._v(" "+_vm._s(message)+" ")])]}},(_vm.customListItem)?{key:"item",fn:function(ref){
  var item = ref.item;
  var on = ref.on;
  var parent = ref.parent;
return [_c('v-list-item',_vm._g({staticClass:"v-list-item-custom pa-0"},on),[(_vm.customListItem === 'countryCode')?[_c('div',{staticClass:"d-flex pl-2 pr-4"},[_c('div',{staticClass:"d-flex align-center mr-4 ml-2"},[(item.isoCode)?_c('img',{staticClass:"country-flag",attrs:{"src":require(("@/assets/flags/" + (item.isoCode.toLowerCase()) + ".svg")),"alt":item.isoCode}}):_vm._e()]),_c('v-list-item-title',{staticClass:"v-list-item-custom__text d-flex align-center",domProps:{"innerHTML":_vm._s(("" + (parent.genFilteredText(item.fullName))))}})],1),(_vm.items.indexOf(item) === _vm.separateListsNumber)?_c('v-divider',{staticClass:"v-list-item-custom__divider mt-2 mb-2"}):_vm._e()]:[_c('v-list-item-title',{staticClass:"v-list-item-custom__text d-flex align-center pl-4 pr-4",domProps:{"innerHTML":_vm._s(
            ("" + (parent.genFilteredText(_vm.itemText ? item[_vm.itemText] : item)))
          )}}),(_vm.items.indexOf(item) === _vm.separateListsNumber)?_c('v-divider',{staticClass:"v-list-item-custom__divider mt-2 mb-2"}):_vm._e()]],2)]}}:null,(_vm.customChip && _vm.chips)?{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"grey lighten-3 chip-close-icon",attrs:{"input-value":data.selected,"ripple":false},on:{"click":data.select}},'v-chip',data.attrs,false),[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"text-body-2 blue--text text--darken-4"},[_vm._v(" "+_vm._s(_vm.itemText ? data.item[_vm.itemText] : data.item)+" ")]),_c('v-btn',{staticClass:"v-icon v-icon--right v-icon--link blue&#45;&#45;text text&#45;&#45;darken-4",attrs:{"icon":"","aria-label":_vm.$t('AUTO_COMPLETE_COMPONENT.CHIP_REMOVE', {
              chip: _vm.itemText ? data.item[_vm.itemText] : data.item,
            })},on:{"click":function($event){return _vm.removeChip(_vm.itemValue ? data.item[_vm.itemValue] : data.item)}}},[_c('v-icon',{staticClass:"chip-close-icon justify-end"},[_vm._v("mdi-close")])],1)],1)])]}}:null],null,true),model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}})}
var staticRenderFns = []

export { render, staticRenderFns }