import { i18n } from "@/locales/i18n";

export const httpErrorMessages = (faultType: string): string => {
  switch (faultType) {
    case "CUSTOMER_NOT_EXISTS":
      return i18n.tc("HTTP_ERRORS.CUSTOMER_NOT_EXISTS");
    case "USER_NOT_VALIDATED":
      return i18n.tc("HTTP_ERRORS.USER_NOT_VALIDATED");
    case "INVALID_BUSINESS_PARTNER_TYPE":
      return i18n.tc("HTTP_ERRORS.INVALID_BUSINESS_PARTNER_TYPE");
    case "CUSTOMER_DELETED":
      return i18n.tc("HTTP_ERRORS.CUSTOMER_DELETED");
    case "PASSWORD_EXPIRED":
      return i18n.tc("HTTP_ERRORS.PASSWORD_EXPIRED");
    case "SESSION_EXPIRED":
      return i18n.tc("HTTP_ERRORS.SESSION_EXPIRED");
    case "OPERATION_FAILED":
      return i18n.tc("HTTP_ERRORS.OPERATION_FAILED");
    case "REPOSITORY_FAILED":
      return i18n.tc("HTTP_ERRORS.REPOSITORY_FAILED");
    case "PASSWORD_IN_HISTORY":
      return i18n.tc("HTTP_ERRORS.PASSWORD_IN_HISTORY");
    case "INVALID_TOKEN":
      return i18n.tc("HTTP_ERRORS.INVALID_TOKEN");
    case "WRONG_OLD_PASSWORD":
      return i18n.tc("HTTP_ERRORS.WRONG_OLD_PASSWORD");
    case "OBJECT_NOT_FOUND":
      return i18n.tc("HTTP_ERRORS.OBJECT_NOT_FOUND");
    case "NOT_AUTHORIZED":
      return i18n.tc("HTTP_ERRORS.NOT_AUTHORIZED");
    case "TOKEN_NOT_VALID":
      return i18n.tc("HTTP_ERRORS.TOKEN_NOT_VALID");
    case "EMAIL_IS_EMPTY":
      return i18n.tc("HTTP_ERRORS.EMAIL_IS_EMPTY");
    case "USER_ALREADY_ACTIVATED":
      return i18n.tc("HTTP_ERRORS.USER_ALREADY_ACTIVATED");
    case "CUSTOMER_ALREADY_EXISTS":
      return i18n.tc("HTTP_ERRORS.CUSTOMER_ALREADY_EXISTS");
    case "MISSING_VAT_COUNTRY":
      return i18n.tc("HTTP_ERRORS.MISSING_VAT_COUNTRY");
    case "MISSING_VAT_NUMBER":
      return i18n.tc("HTTP_ERRORS.MISSING_VAT_NUMBER");
    case "MISSING_INVOICE_GROUP_ID":
      return i18n.tc("HTTP_ERRORS.MISSING_INVOICE_GROUP_ID");
    case "WRONG_INVOICE_GROUP_ID":
      return i18n.tc("HTTP_ERRORS.WRONG_INVOICE_GROUP_ID");
    case "MISSING_EUVAT_NUMBER":
      return i18n.tc("HTTP_ERRORS.MISSING_EUVAT_NUMBER");
    case "MISSING_THIRD_COUNTRY_VAT_NUMBER":
      return i18n.tc("HTTP_ERRORS.MISSING_THIRD_COUNTRY_VAT_NUMBER");
    case "INVALID_VAT_NUMBER":
      return i18n.tc("HTTP_ERRORS.INVALID_VAT_NUMBER");
    case "INVALID_ZIPCODE":
      return i18n.tc("HTTP_ERRORS.INVALID_ZIPCODE");
    case "INVALID_VAT_TYPE":
      return i18n.tc("HTTP_ERRORS.INVALID_VAT_TYPE");
    case "CDV_VALIDATION_ERROR":
      return i18n.tc("HTTP_ERRORS.CDV_VALIDATION_ERROR");
    case "WRONG_OR_MISSING_PARAMETERS":
      return i18n.tc("HTTP_ERRORS.WRONG_OR_MISSING_PARAMETERS");
    case "EMAIL_SENDING_ERROR":
      return i18n.tc("HTTP_ERRORS.EMAIL_SENDING_ERROR");
    case "EMAIL_SENDING_ERROR_EMAIL":
      return i18n.tc("HTTP_ERRORS.EMAIL_SENDING_ERROR_EMAIL");
    case "CUSTOMER_NOT_EXISTS_FORG_PASS_TOKEN":
      return i18n.tc("HTTP_ERRORS.CUSTOMER_NOT_EXISTS_FORG_PASS_TOKEN");
    case "ENTITY_SAVE_FAILED":
      return i18n.tc("HTTP_ERRORS.ENTITY_SAVE_FAILED");
    case "WRONG_PASSWORD":
      return i18n.tc("HTTP_ERRORS.WRONG_PASSWORD");
    case "INVALID_ACTIVATION_CODE":
      return i18n.tc("HTTP_ERRORS.INVALID_ACTIVATION_CODE");
    case "INVALID_TOKEN_EMAIL":
      return i18n.tc("HTTP_ERRORS.INVALID_TOKEN_EMAIL");
    case "INVALID_INPUT":
      return i18n.tc("HTTP_ERRORS.INVALID_INPUT");
    case "CUSTOMER_ALREADY_EXISTS_EMAIL":
      return i18n.tc("HTTP_ERRORS.CUSTOMER_ALREADY_EXISTS_EMAIL");
    case "USER_ALREADY_ACTIVATED_EMAIL":
      return i18n.tc("HTTP_ERRORS.USER_ALREADY_ACTIVATED_EMAIL");
    case "BILLING_ADDRESS_LIMIT_IS_REACHED":
      return i18n.tc("HTTP_ERRORS.BILLING_ADDRESS_LIMIT_IS_REACHED");
    case "CUSTOMER_ADDRESS_NOT_EXISTS":
      return i18n.tc("HTTP_ERRORS.CUSTOMER_ADDRESS_NOT_EXISTS");
    case "CUSTOMER_ADDRESS_NOT_EXISTS_INVOICE":
      return i18n.tc("HTTP_ERRORS.CUSTOMER_ADDRESS_NOT_EXISTS_INVOICE");
    case "DEFAULT_BILLING_ADDRESS_IS_REQUIRED":
      return i18n.tc("HTTP_ERRORS.DEFAULT_BILLING_ADDRESS_IS_REQUIRED");
    case "CUSTOMER_NOT_EXISTS_INVOICE":
      return i18n.tc("HTTP_ERRORS.CUSTOMER_NOT_EXISTS_INVOICE");
    case "CUSTOMER_NOT_EXISTS_EMAIL":
      return i18n.tc("HTTP_ERRORS.CUSTOMER_NOT_EXISTS_EMAIL");
    case "CUSTOMER_NOT_EXISTS_EXPIRED_PASSW":
      return i18n.tc("HTTP_ERRORS.CUSTOMER_NOT_EXISTS_EXPIRED_PASSW");
    case "VEHICLE_IS_EXISTS":
      return i18n.tc("HTTP_ERRORS.VEHICLE_IS_EXISTS");
    case "HD_VEHICLE_LIMIT_IS_REACHED":
      return i18n.tc("HTTP_ERRORS.HD_VEHICLE_LIMIT_IS_REACHED");
    case "INVALID_PRIVATE_PERSON_INPUT":
      return i18n.tc("HTTP_ERRORS.INVALID_PRIVATE_PERSON_INPUT");
    case "INVALID_PLATE_NUMBER":
      return i18n.tc("HTTP_ERRORS.INVALID_PLATE_NUMBER");
    case "CAPTCHA_VALIDATION_FAILED":
      return i18n.tc("HTTP_ERRORS.CAPTCHA_VALIDATION_FAILED");
    case "ENTITY_DELETE_FAILED":
      return i18n.tc("HTTP_ERRORS.ENTITY_DELETE_FAILED");
    case "PRODUCT_NOT_FOUND":
      return i18n.tc("HTTP_ERRORS.PRODUCT_NOT_FOUND");
    case "CART_NOT_FOUND_FOR_CUSTOMER":
      return i18n.tc("HTTP_ERRORS.CART_NOT_FOUND_FOR_CUSTOMER");
    case "INVALID_ADHOC_ID":
      return i18n.tc("HTTP_ERRORS.INVALID_ADHOC_ID");
    case "INVALID_COUNTRY_CODE":
      return i18n.tc("HTTP_ERRORS.INVALID_COUNTRY_CODE");
    default:
      return i18n.tc("HTTP_ERRORS.UNKOWN_ERROR");
  }
};
