






































import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { commonFacade } from "@/store/modules/common/common.facade";

export default defineComponent({
  name: "LanguageSelector",
  props: {
    id: {
      type: String,
      default: "header",
    },
  },
  setup(props, { emit }) {
    const currentLanguage = computed(commonFacade.currentLanguage);
    const language = ref<string>(currentLanguage.value as string);
    const tenant = computed(commonFacade.tenantData);
    const tenantLoading = computed(commonFacade.tenantDataLoading);

    watch(currentLanguage, (curr) => {
      language.value = curr as string;
    });

    const selectionChanged = (languageCode: string) => {
      emit("languageSelected", languageCode);
    };

    return {
      language,
      tenant,
      tenantLoading,
      selectionChanged,
    };
  },
});
