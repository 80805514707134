


































































































import {
  defineComponent,
  reactive,
  onMounted,
  computed,
} from "@vue/composition-api";
import TextInput from "@/components/forms/input/TextInput.vue";
import PasswordValidator from "@/components/passwordValidator/PasswordValidator.vue";
import { userFacade } from "@/store/modules/user/user.facade";
import { NewForgottenPasswordRequest } from "@/core/dataTypes/types";
import { alertFacade } from "@/store/modules/alert/alert.facade";
import { cryptoSHA1 } from "@/core/utils/crypto/cryptoHelpers";
import { getRecaptchaToken } from "@/core/utils/captcha";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "NewPassword",
  components: {
    TextInput,
    PasswordValidator,
  },
  setup(props, { root }) {
    const alert = computed(alertFacade.alert);
    const token = root.$route.query.code as string;
    const forgottenPasswordActive = computed(
      userFacade.forgottenPasswordActive
    );
    const forgottenPasswordTokenLoading = computed(
      userFacade.forgottenPasswordTokenLoading
    );
    const putForgottenPasswordLoading = computed(
      userFacade.putForgottenPasswordLoading
    );
    const formValues = reactive({
      password: "",
      confirmation: "",
    });
    const sendPassword = () => {
      getRecaptchaToken("changeForgottenPassword").then(
        (recaptchaResponseToken: string) => {
          const requestData: NewForgottenPasswordRequest = {
            token: token,
            password: cryptoSHA1(formValues.password),
            recaptchaResponseToken,
          };
          userFacade.changeForgottenPassword(requestData);
        }
      );
    };
    onMounted(() => {
      if (token) {
        userFacade.forgottenPasswordToken(token);
      }
    });
    return {
      formValues,
      forgottenPasswordActive,
      putForgottenPasswordLoading,
      forgottenPasswordTokenLoading,
      sendPassword,
      alert,
    };
  },
  metaInfo() {
    return {
      titleTemplate: `${i18n.tc("NEW_PASSWORD_PAGE.TITLE")} | %s`,
    };
  },
});
