import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueCompositionAPI from "@vue/composition-api";
import AuthService from "@/core/services/auth.service";
import { userFacade } from "@/store/modules/user/user.facade";
import "@/core/utils/validation";
import "@/core/utils/captcha";
import { i18n } from "@/locales/i18n";
import HttpService from "@/core/services/http.service";
import {
  formatCurrency,
  formatDate,
  toFullAddress,
  toLanguageText,
} from "@/core/filters";
import { initRecaptcha } from "@/core/utils/captcha";
import { commonFacade } from "@/store/modules/common/common.facade";
import { keyvalueFacade } from "@/store/modules/keyvalue/keyvalue.facade";
import VueGtag from "vue-gtag";
import VueMeta from "vue-meta";
import { setOptions, bootstrap } from "vue-gtag";
// import { storeFacade } from "@/store/store.facade";

Vue.use(VueCompositionAPI);
Vue.use(VueMeta, { refreshOnceOnNavigation: true });
Vue.use(VueGtag, { bootstrap: false });

Vue.config.productionTip = false;
// Filters
Vue.filter("toFullAddress", toFullAddress);
Vue.filter("formatDate", formatDate);
Vue.filter("formatCurrency", formatCurrency);
Vue.filter("toLanguageText", toLanguageText);

const initializeApp = () => {
  return new Promise<void>((resolve) => {
    Promise.all([
      commonFacade.getTenantData(),
      keyvalueFacade.getKeyValue(),
    ]).then(() => {
      // async loading Google Analytics based on backend key-value
      if (keyvalueFacade.getGoogleAnalyticsKey()?.value) {
        setOptions({
          config: { id: keyvalueFacade.getGoogleAnalyticsKey()?.value ?? "" },
        });
        bootstrap();
      }
      if (AuthService.hasValidToken() && AuthService.hasValidRefreshToken()) {
        userFacade.refresh().finally(() => {
          resolve();
        });
      } else {
        if (AuthService.hasValidToken()) {
          AuthService.sessionExpirationHandler();
        } else {
          AuthService.resetStateAndSessionTokens();
        }
        resolve();
      }
    });
  });
};

initializeApp().then(() => {
  const vm = new Vue({
    store,
    vuetify,
    i18n,
    router,
    render: (h) => h(App),
  }).$mount("#app");
  initRecaptcha(vm);
});

window.onbeforeunload = () => {
  if (HttpService.isPending()) {
    HttpService.cancel();
  }
};
window.onpageshow = (ev) => {
  const isIOS =
    /iPad|iPhone|iPod|Safari/.test(navigator.userAgent) && !window.MSStream;
  if (ev.persisted && isIOS) {
    window.location.reload();
  }
};

// teszt
/*window.onstorage = () => {
  const isNotLoggedInUser = userFacade.notLoggedInUser();
  const isLoggedInUser = AuthService.hasValidToken();
  if (isNotLoggedInUser && isLoggedInUser) {
    const state = JSON.parse(localStorage.ematrica);
    if (state?.user?.user) {
      storeFacade.reloadState(state);
      window.location.reload();
    }
  }
};*/
// console.log("- E-matrica -", process.env.VUE_APP_CAPTCHA_SITE_KEY);
