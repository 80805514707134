







































































import {
  computed,
  ref,
  defineComponent,
  onMounted,
} from "@vue/composition-api";
import { commonFacade } from "@/store/modules/common/common.facade";
import { EMenuTypes } from "@/core/dataTypes/enum/menuTypes.enum";
import { getYear } from "date-fns";
import { getVersion } from "@/core/api/accountService.api";
// import { keyvalueFacade } from "@/store/modules/keyvalue/keyvalue.facade";
// import SocialIconList from "@/components/socialIconList/SocialIconList.vue";
// import HTMLContentRenderer from "@/components/HTMLContentRenderer/HTMLContentRenderer.vue";

export default defineComponent({
  name: "Footer",
  components: {
    // SocialIconList,
    // HTMLContentRenderer,
  },
  setup() {
    const menu = computed(commonFacade.menuItems);
    const menuLoading = computed(commonFacade.menuItemsLoading);
    const currentLang = computed(commonFacade.currentLanguage);
    const contactContent = computed(commonFacade.contactContent);
    const contactContentLoading = computed(commonFacade.contactContentLoading);
    const currentYear = ref(getYear(new Date()));
    // const version = `${process.env.VUE_APP_VERSION_INFORMATION}`;
    const appVersion = ref("");
    commonFacade.getContactContent();
    onMounted(() => {
      getVersion().then((res) => {
        appVersion.value = res;
      });
    });

    return {
      menu,
      menuLoading,
      currentLang,
      EMenuTypes,
      currentYear,
      contactContent,
      contactContentLoading,
      appVersion,
    };
  },
});
