















































































































































































































































































































































































































































































































import {
  computed,
  defineComponent,
  onUnmounted,
  onMounted,
  ref,
} from "@vue/composition-api";
import { userFacade } from "@/store/modules/user/user.facade";
import router from "@/router";
import { ERoutes } from "@/core/dataTypes/enum";
import { i18n } from "@/locales/i18n";
import { commonFacade } from "@/store/modules/common/common.facade";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import { tabFocusService } from "@/core/services/tabFocus.service";
import { Subscription } from "rxjs";
import DesktopMenu from "@/components/headers/DesktopMenu/DesktopMenu.vue";
import MenuList from "@/components/headers/MenuList/MenuList.vue";
import LanguageSelector from "@/components/languageSelector/LanguageSelector.vue";
import { getLanguageStaticRoute } from "@/core/utils/router.util";
import SkipLink from "@/components/skipLink/SkipLink.vue";

export default defineComponent({
  name: "LoggedInHeader",
  components: {
    MenuList,
    DesktopMenu,
    LanguageSelector,
    SkipLink,
  },
  setup(props, { refs }) {
    const tabFocusSubscription = new Subscription();
    const headerVisibility = computed(commonFacade.showHeader);
    const showOnLogin = computed(commonFacade.showOnLogin);
    // const showBasketIcon = computed(commonFacade.showOnBasketIcon);
    const notLoggedInUser = computed(userFacade.notLoggedInUser);
    const vMenuOpen = ref(false);
    const drawer = ref(false);
    const user = computed(userFacade.user);
    const userSession = computed(userFacade.userSession);
    const logoutLoading = computed(userFacade.logoutLoading);
    const currentLanguage = computed(commonFacade.currentLanguage);
    const languages = computed(commonFacade.getLanguages);
    const cartId = computed(stickerFacade.cartId);
    const cartElementsCount = computed(stickerFacade.cartElementsCount);
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const localize = computed(commonFacade.tenantData).value?.localization.find(
      (item) => currentLanguage.value === item.languageCode
    ) as any;
    const tenantLogo = localize.localizedLogo.url;
    const nudLink = ref(
      `${process.env.VUE_APP_NEMZETI_UTDIJ}/${currentLanguage.value}`
    );
    const menuItemsLoading = computed(commonFacade.menuItemsLoading);
    const menuItems = computed(commonFacade.menuItems);

    const handleLanguageSelection = (languageCode: string) => {
      localStorage.setItem("currLang", languageCode);
      setTimeout(() => {
        window.location.href = "/" + languageCode;
      });
    };
    const onLogout = () => {
      userFacade.logout().then(() => {
        vMenuOpen.value = false;
        // location.reload();
      });
    };
    const navigateProfilePage = () => {
      router.push(getLanguageStaticRoute(ERoutes.PROFILE)).catch((err) => err);
      setTimeout(() => {
        vMenuOpen.value = false;
      });
    };
    const navigateLoginPage = () => {
      router.push(getLanguageStaticRoute(ERoutes.LOGIN)).catch((err) => err);
    };
    const navigateRegistrationPage = () => {
      router
        .push(getLanguageStaticRoute(ERoutes.REGISTRATION))
        .catch((err) => err);
    };
    const navigateStickerPurchasePage = () => {
      const path = cartId.value
        ? getLanguageStaticRoute(
            `${ERoutes.STICKER_PURCHASE}/${ERoutes.STICKER_PAY}`
          )
        : getLanguageStaticRoute(ERoutes.EMPTY_CART);
      router.push(path).catch((err) => err);
    };

    const updateHeaderView = () => {
      const routerName = router.app.$route.name;
      // const hasUser = !user.value;
      const hasUser = !userSession.value;
      commonFacade.setShowOnLogin(
        !(routerName === "Login" || routerName === "Registration")
      );
      /*commonFacade.setShowBasketIcon(
        routerName !== "ExpiredPasswordPage"
      );*/
      // if (routerName === "ExpiredPasswordPage") {}
      userFacade.setNotLoggedInUser(hasUser);
    };

    tabFocusSubscription.add(
      tabFocusService.getFocus().subscribe((target) => {
        if (target === "nuszBack") {
          const nuszBackRef = refs.nuszBack as HTMLElement;
          nuszBackRef.focus();
        }
        updateHeaderView();
      })
    );

    onMounted(() => {
      document.documentElement.setAttribute(
        "lang",
        currentLanguage.value as string
      );
      commonFacade.getMenuItems();
      userFacade.resetLogout();
      updateHeaderView();
    });
    onUnmounted(() => {
      tabFocusSubscription.unsubscribe();
    });
    return {
      onLogout,
      navigateProfilePage,
      navigateStickerPurchasePage,
      navigateLoginPage,
      navigateRegistrationPage,
      handleLanguageSelection,
      getLanguageStaticRoute,
      showOnLogin,
      logoutLoading,
      // showBasketIcon,
      notLoggedInUser,
      user,
      userSession,
      menuItems,
      menuItemsLoading,
      vMenuOpen,
      ERoutes,
      drawer,
      languages,
      headerVisibility,
      cartId,
      i18n,
      tenantLogo,
      nudLink,
      cartElementsCount,
    };
  },
});
