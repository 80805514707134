var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex flex-column align-center fill-height main-content-padding",attrs:{"fluid":"","tag":"section"}},[_c('v-card',{staticClass:"px-4 pa-sm-16 remove-card-shadow",attrs:{"width":"560"}},[_c('div',{staticClass:"d-flex pb-sm-1"},[_c('router-link',{attrs:{"aria-label":_vm.$t('GENERAL.BUTTONS.BACK'),"to":_vm.ERoutes.PROFILE}},[_c('i',{staticClass:"backBtn mdi mdi-arrow-left text-h4 blue--text text--darken-4",attrs:{"aria-label":_vm.$t('CHANGE_PASSWORD_PAGE.BUTTONS.BACK')}})]),_c('h1',{staticClass:"text-h4 blue--text text--darken-4 pl-2"},[_vm._v(" "+_vm._s(_vm.$t("CHANGE_PASSWORD_PAGE.HEADER"))+" ")])],1),_c('div',{staticClass:"pt-4 pt-sm-4 pb-8 blue--text text--darken-4 body-1"},[_vm._v(" "+_vm._s(_vm.$t("CHANGE_PASSWORD_PAGE.MESSAGE1"))+" ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.changePassword.apply(null, arguments)}}},[_c('div',{staticClass:"item-controller"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('CHANGE_PASSWORD_PAGE.FIELDS.PASSWORD'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"id":"changePasswordPage--currentPassword","name":"CurrentPassword","showPasswordEye":true,"type":"password","label":_vm.$t('CHANGE_PASSWORD_PAGE.FIELDS.PASSWORD'),"aria-label":_vm.$t('CHANGE_PASSWORD_PAGE.FIELDS.PASSWORD_LABEL'),"aria-required":true,"error-messages":errors,"required":""},model:{value:(_vm.changePasswordForm.currentPassword),callback:function ($$v) {_vm.$set(_vm.changePasswordForm, "currentPassword", $$v)},expression:"changePasswordForm.currentPassword"}})]}}],null,true)})],1),_c('div',{staticClass:"item-controller"},[_c('ValidationProvider',{attrs:{"rules":"customRequired|extendedPassword","vid":"newPassword","name":_vm.$t('CHANGE_PASSWORD_PAGE.FIELDS.NEW_PASSWORD'),"customMessages":{
              min: _vm.$t('CHANGE_PASSWORD_PAGE.FIELDS.PASSWORD_ERROR'),
              lowerUpperCase: _vm.$t(
                'CHANGE_PASSWORD_PAGE.FIELDS.PASSWORD_ERROR'
              ),
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('TextInput',{attrs:{"id":"changePasswordPage--newPassword","name":"NewPassword","showPasswordEye":true,"type":"password","label":_vm.$t('CHANGE_PASSWORD_PAGE.FIELDS.NEW_PASSWORD'),"aria-label":_vm.$t('CHANGE_PASSWORD_PAGE.FIELDS.NEW_PASSWORD_LABEL'),"aria-required":true,"aria-described-by":'password-description',"aria-live":'polite',"error-messages":errors,"required":""},model:{value:(_vm.changePasswordForm.newPassword),callback:function ($$v) {_vm.$set(_vm.changePasswordForm, "newPassword", $$v)},expression:"changePasswordForm.newPassword"}})]}}],null,true)})],1),_c('PasswordValidator',{attrs:{"value":_vm.changePasswordForm.newPassword}}),_c('div',{staticClass:"item-controller pt-6"},[_c('ValidationProvider',{attrs:{"rules":"customRequired|confirmed:newPassword","name":_vm.$t('CHANGE_PASSWORD_PAGE.FIELDS.NEW_PASSWORD_AGAIN')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":_vm.$t('CHANGE_PASSWORD_PAGE.FIELDS.NEW_PASSWORD_AGAIN'),"aria-label":_vm.$t('CHANGE_PASSWORD_PAGE.FIELDS.NEW_PASSWORD_AGAIN_LABEL'),"aria-required":true,"id":"changePasswordPage--newPasswordAgain","showPasswordEye":true,"type":"password","error-messages":errors,"required":""},model:{value:(_vm.changePasswordForm.newPasswordAgain),callback:function ($$v) {_vm.$set(_vm.changePasswordForm, "newPasswordAgain", $$v)},expression:"changePasswordForm.newPasswordAgain"}})]}}],null,true)})],1),_c('div',{staticClass:"submit-content"},[(_vm.changePasswordLoading)?_c('div',{staticClass:"d-sr-only",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.$t("GENERAL.LOADING_BUTTON"))+" ")]):_vm._e(),_c('v-btn',{staticClass:"black white--text mt-5",attrs:{"block":"","type":"submit","color":"primary","id":"changePasswordPage--saveNewPasswordButton","disabled":invalid,"loading":_vm.changePasswordLoading,"aria-label":_vm.$t('CHANGE_PASSWORD_PAGE.BUTTONS.SAVE_NEW_PASSWORD_LABEL')}},[_vm._v(_vm._s(_vm.$t("CHANGE_PASSWORD_PAGE.BUTTONS.SAVE_NEW_PASSWORD"))+" ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }