








































import {
  dialogCloseEmitter,
  dialogResultEmitter,
} from "@/core/utils/dialog/dialogEmitter";
import { defineComponent } from "@vue/composition-api";
import { ConfirmDialogPropsType } from "@/core/dataTypes/types";
import { i18n } from "@/locales/i18n";
import { tabFocusService } from "@/core/services/tabFocus.service";

export default defineComponent({
  name: "ConfirmDialog",
  props: {
    dialogProps: Object as () => ConfirmDialogPropsType,
  },

  setup(props, ctx) {
    const cancelText = props.dialogProps?.submitText
      ? props.dialogProps?.cancelText
      : i18n.tc("CONFIRM_DIALOG.NO");
    const submitText = props.dialogProps?.submitText
      ? props.dialogProps?.submitText
      : i18n.tc("CONFIRM_DIALOG.YES");

    const clickYes = (): void => {
      dialogResultEmitter(ctx, { autoClose: true, payload: true });
      tabFocusService.setFocus("nuszBack");
    };

    const clickNo = (): void => {
      dialogCloseEmitter(ctx);
      tabFocusService.setFocus("nuszBack");
    };

    return {
      clickYes,
      clickNo,
      submitText,
      cancelText,
    };
  },
});
