














































































































import { ref, defineComponent, computed } from "@vue/composition-api";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "StickerPurchasePage",
  setup() {
    stickerFacade.transactionsPaymentProgress(false);
    const step = ref(0);
    const transactionsPaymentProgressLongTime = computed(
      stickerFacade.transactionsPaymentProgressLongTime
    );
    const transactionsPaymentLoading = computed(
      stickerFacade.transactionsPaymentLoading
    );
    const transactionId = computed(stickerFacade.transactionId);
    const cartId = computed(stickerFacade.cartId);
    const onChangeStep = (stepNumber: number) => {
      step.value = stepNumber;
    };
    return {
      step,
      cartId,
      transactionId,
      transactionsPaymentLoading,
      transactionsPaymentProgressLongTime,
      onChangeStep,
    };
  },
  metaInfo() {
    return {
      titleTemplate: `${i18n.tc("STICKER_PURCHASE_PAGE.PAGE_TITLE")} | %s`,
    };
  },
});
