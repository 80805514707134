import { AppState } from "@/store";
import { cloneDeep } from "lodash/fp";
import { Module } from "vuex";
import { Content } from "@/core/dataTypes/types/cmsService.interface";
import { cmsServiceApi } from "@/core/api/cmsService.api";

export interface CustomState {
  content: Content[];
  contentLoading: boolean;
}

export const initialCustomState: CustomState = {
  content: [],
  contentLoading: false,
};

export const customModule: Module<CustomState, AppState> = {
  namespaced: true,
  state: initialCustomState,
  mutations: {
    contentRequest: (state) => {
      state.contentLoading = true;
    },
    contentSuccess: (state, payload: Content[]) => {
      state.content = payload;
      state.contentLoading = false;
    },
    contentFail: (state) => {
      state.contentLoading = false;
    },
    resetModule: (state) => {
      Object.assign(state, cloneDeep(initialCustomState));
    },
  },
  actions: {
    resetModule: ({ commit }) => {
      commit("resetModule");
    },
    getContent: ({ commit }, slug: string) => {
      commit("contentRequest");
      return (
        cmsServiceApi
          .getMenuContentByPublicRoute(slug)
          // .getContentByMenuId(process.env.VUE_APP_TENANT, rootState.common?.currentPageMenuId as string)
          .then((response) => {
            commit("contentSuccess", response);
          })
          .catch((error) => {
            commit("contentFail");
            throw Error(error);
          })
      );
    },
  },
};
