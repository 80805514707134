import cmsHttpService from "../services/cms.http.service.api";
import {
  CmsMediaCollection,
  CmsTableData,
  Content,
  ContentCategories,
  ContentsRequestModel,
  KeyValue,
  Menu,
  NavigationContent,
  TenantData,
} from "@/core/dataTypes/types/cmsService.interface";
import { contentTransformer } from "@/core/transformers/cmsServiceTransformers/content.transformer";
import { mediaTransformer } from "@/core/transformers/cmsServiceTransformers/media.transformer";
import { commonFacade } from "@/store/modules/common/common.facade";

export const getTenantData = (): Promise<TenantData> =>
  cmsHttpService.get<never>(``).then((response) => response.data);

/*
const getMenuItems = (lang: string): Promise<CmsMenuItem[]> =>
  cmsHttpService
    .get<never>(`menu-items/${lang}`)
    .then((response) => response.data);
*/

const getMenuItems = (lang: string): Promise<Menu> =>
  cmsHttpService
    .get<never>(`menu-items/${lang}`)
    .then((response) => contentTransformer.transformMenu(response.data));

const getCreditCardIcons = (
  mediaCollectionId: string | undefined
): Promise<CmsMediaCollection> =>
  cmsHttpService
    .get<never>(`mediacollection/${mediaCollectionId}`)
    .then((response) => response.data);

const getContentByMenuId = (tenant: string, mid: string): Promise<Content[]> =>
  cmsHttpService.get<unknown[]>(`contents/menuitem/${mid}`).then(({ data }) => {
    return data.map((item) => contentTransformer.transformContent(item));
  });

const getMenuContentByPublicRoute = (publicRoute: string): Promise<Content[]> =>
  cmsHttpService
    /* eslint-disable @typescript-eslint/no-explicit-any */
    .get<any>(`contents/menuitem/publicroute/${publicRoute}`)
    .then(({ data }) =>
      data.map((item: any) => contentTransformer.transformContent(item))
    );
const getContentBodyBySlug = (slug: string): Promise<Content> =>
  cmsHttpService.get<unknown>(`contents/slug/${slug}`).then(({ data }) => {
    return contentTransformer.transformContent(data);
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getInformationPanelContent = (keyValue: string) => {
  const queryData = {
    take: 10,
    skip: 0,
    sort: { contentCategoryOrder: "ASC" },
    filter: {
      contentCategoryId: keyValue,
      languageCode:
        localStorage.getItem("currLang") ||
        commonFacade.currentLanguage() ||
        process.env.VUE_APP_I18N_LOCALE,
    },
    contentBodyReturn: true,
  };
  return cmsHttpService
    .post(`contents`, queryData)
    .then((response) => response.data as any);
};

const getContentsByContentCategoryId = (
  keyValue: KeyValue | undefined
): Promise<CmsTableData> => {
  const lang =
    localStorage.getItem("currLang") ||
    commonFacade.currentLanguage() ||
    process.env.VUE_APP_I18N_LOCALE;
  const queryData = {
    take: 10,
    skip: 0,
    sort: {
      featuredContent: true,
      visibilityFrom: "desc",
    },
    filter: {
      contentCategoryId: "",
      languageCode: lang,
    },
  };
  if (keyValue != null) {
    queryData.filter.contentCategoryId = keyValue.value;
  }

  return cmsHttpService.post(`contents`, queryData).then(({ data }) => {
    return data as CmsTableData;
  });
};

const getMediaCollection = (
  tenant: string,
  mediaCollectionID: string
): Promise<CmsMediaCollection> => {
  return cmsHttpService
    .get<CmsMediaCollection>(`/mediacollection/${mediaCollectionID}`)
    .then((response) =>
      mediaTransformer.transformMediaCollection(response.data)
    );
};

const getKeyValueData = (): Promise<KeyValue[]> =>
  cmsHttpService.get(`key-value`).then(({ data }) => {
    return data as KeyValue[];
  });

const getContents = (
  requestData: ContentsRequestModel
): Promise<CmsTableData> =>
  cmsHttpService
    .post(`/contents`, requestData)
    .then((response) =>
      contentTransformer.transformContentsResponse(response.data)
    );
const contentBySlug = (contentSlug: string): Promise<Content> =>
  cmsHttpService
    .get(`contents/slug/${contentSlug}`)
    .then((response) => contentTransformer.transformContent(response.data));

const getContentCategories = (): Promise<ContentCategories[]> =>
  cmsHttpService
    .get<ContentCategories[]>(`content-categories`)
    .then((response) => response.data);

const getNavigationById = (id: string): Promise<NavigationContent> =>
  cmsHttpService
    .get<NavigationContent>(`contents/navigation/${id}`)
    .then((response) => response.data);

export const cmsServiceApi = {
  getMenuItems,
  getContentByMenuId,
  getKeyValueData,
  getContentsByContentCategoryId,
  getContentBodyBySlug,
  getCreditCardIcons,
  getMediaCollection,
  getContents,
  contentBySlug,
  getContentCategories,
  getNavigationById,
  getMenuContentByPublicRoute,
  getInformationPanelContent,
};
