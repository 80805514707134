







import { commonFacade } from "@/store/modules/common/common.facade";
import { computed, defineComponent, watch } from "@vue/composition-api";
import router, { useRoute } from "@/router";
import { customFacade } from "@/store/modules/custom/custom.facade";
import ContentPage from "@/components/contentPage/ContentPage.vue";
// import { getMenuId } from "@/core/utils/findMenuId.util";
import { Content } from "@/core/dataTypes/types/cmsService.interface";

export default defineComponent({
  name: "CustomSlug",
  components: {
    ContentPage,
  },
  setup() {
    customFacade.resetModule();

    const route = useRoute();
    const menuLoading = computed(commonFacade.menuItemsLoading);
    const contents = computed<Content[] | undefined>(customFacade.content);
    const contentLoading = computed(customFacade.contentLoading);
    /*  const getContent = (): void => {
      getMenuId().then((menuId: string | undefined) => {
        menuId ? customFacade.getContent() : router.push({ name: "MainPage" });
      });
    };
    watch(route, () => {
      customFacade.resetModule();
      getContent();
    });
    getContent();*/

    const getContent = () => {
      customFacade
        .getContent(route.value.slug as string)
        .catch(() => router.push({ name: "Login" }));
    };
    getContent();

    watch(route, (curr, prev) => {
      if (curr.path !== prev.path) {
        customFacade.resetModule();
        getContent();
      }
    });

    return {
      menuLoading,
      contents,
      contentLoading,
    };
  },
  metaInfo() {
    const contents = this.contents as Content[];
    const title = contents.length ? contents[0].title : "";
    const description = contents.length ? contents[0].seoLead : "";
    return {
      titleTemplate: title.length ? `${title} | %s` : "%s",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description as string,
        },
      ],
    };
  },
});
