






























































import { ref, defineComponent, computed } from "@vue/composition-api";
import UserDataForm from "@/views/RegistrationPage/components/userDataForm/userDataForm.vue";
import UserPasswordForm from "@/views/RegistrationPage/components/userPasswordForm/userPasswordForm.vue";
import UserBillingDetails from "./components/userBillingDetails/UserBillingDetails.vue";
import { alertFacade } from "@/store/modules/alert/alert.facade";
import { registrationFacade } from "@/store/modules/registration/registration.facade";
import { i18n } from "@/locales/i18n";
import { tabFocusService } from "@/core/services/tabFocus.service";

export default defineComponent({
  name: "Registration",
  components: {
    UserDataForm,
    UserPasswordForm,
    UserBillingDetails,
  },
  setup() {
    const alert = computed(alertFacade.alert);
    const registrationStep = ref(1);
    const changeStep = (step: number) => {
      registrationStep.value = step;
      switch (step) {
        case 1: {
          document.title = i18n.tc("REGISTRATION_PAGE.PAGE_TITLE");
          break;
        }
        case 2: {
          document.title = i18n.tc("REGISTRATION_PAGE.PAGE_TITLE2");
          break;
        }
        case 3: {
          document.title = i18n.tc("REGISTRATION_PAGE.PAGE_TITLE3");
          break;
        }
      }
      tabFocusService.setFocus("nuszBack");
    };
    registrationFacade.resetRegistrationForm();

    return {
      changeStep,
      registrationStep,
      alert,
    };
  },
  metaInfo() {
    return {
      titleTemplate: `${i18n.tc("REGISTRATION_PAGE.PAGE_TITLE")} | %s`,
    };
  },
});
