/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import format from "date-fns/format";
import {
  CMSMenuCategory,
  CMSPublicMenuItem,
  CmsTableData,
  Content,
  HTMLContent,
  Media,
  MediaResponse,
  Menu,
  MenuItem,
} from "@/core/dataTypes/types/cmsService.interface";
import { EhtmlContentTypes } from "@/core/dataTypes/enum/htmlContentTypes.enum";
import { unescape } from "lodash";
import {
  dateAndTimeFormat,
  dateFormat,
} from "@/core/utils/date/dateFormats.util";

const transformHTMLContent = (content: string): HTMLContent[][] => {
  const parser = new DOMParser();
  const parsedHTML: Document = parser.parseFromString(
    unescape(content),
    "text/html"
  );
  const documentBody = parsedHTML.body;

  return Array.from(documentBody.children).map((item) =>
    transformHTMLContentChilds(item)
  );
};
const transformHTMLContentChilds = (item: Element): HTMLContent[] => {
  const extraContentTypes: EhtmlContentTypes[] = [
    EhtmlContentTypes.SLIDESHOW,
    EhtmlContentTypes.GALLERY,
    EhtmlContentTypes.DOCUMENT_LIST,
  ];
  return Array.from(item.children).reduce(
    (
      previousValue: HTMLContent[],
      currentValue: Element,
      index: number
    ): HTMLContent[] => {
      let acc: any[] = previousValue;
      const concatenateHtml = (acc: any[], currentValue: any) => {
        acc[acc.length - 1].data =
          acc[acc.length - 1].data + currentValue.outerHTML;

        return acc;
      };

      if (currentValue.className !== "cms-media-collection") {
        acc =
          index === 0
            ? [
                {
                  type: EhtmlContentTypes.SIMPLE_HTML,
                  data: currentValue.outerHTML,
                },
              ]
            : extraContentTypes.includes(acc[acc.length - 1].type)
            ? [
                ...acc,
                {
                  type: EhtmlContentTypes.SIMPLE_HTML,
                  data: currentValue.outerHTML,
                },
              ]
            : concatenateHtml(acc, currentValue);
      } else {
        const id = currentValue.getAttribute("data-id");
        const name = currentValue.getAttribute("data-name");
        const type = currentValue.getAttribute("data-type");
        const itemCount = currentValue.getAttribute("data-item-count");
        let contentType: EhtmlContentTypes;

        switch (type) {
          case EhtmlContentTypes.SLIDESHOW:
            contentType = EhtmlContentTypes.SLIDESHOW;
            break;
          case EhtmlContentTypes.GALLERY:
            contentType = EhtmlContentTypes.GALLERY;
            break;
          default:
            contentType = EhtmlContentTypes.DOCUMENT_LIST;
            break;
        }

        acc = [
          ...acc,
          {
            type: contentType,
            data: {
              id,
              name,
              itemCount,
            },
          },
        ];
      }

      return acc;
    },
    []
  );
};

const transformMediaItem = (data: any): Media => ({
  url: data.url,
  ext: data.ext,
  ...(data.width && { width: data.width }),
  ...(data.height && { height: data.height }),
});

const transformMediaResponse = (data: any): MediaResponse | undefined => {
  if (!data) return;

  const { alternativeText, caption, formats } = data;

  const imageFormats = formats && {
    thumbnail: transformMediaItem(formats.thumbnail),
    medium: transformMediaItem(formats.medium),
    small: transformMediaItem(formats.small),
  };

  return {
    ...(alternativeText && { altText: alternativeText }),
    caption,
    ...transformMediaItem(data),
    ...(formats && { formats: imageFormats }),
  };
};

const transformContent = (data: any): Content => ({
  id: data.id,
  title: data.title,
  // ...(data.subtitle && { subtitle: transformHTMLContent(data.subtitle) }),
  ...(data.subtitle && { subtitle: data.subtitle }),
  ...(data.lead && { lead: data.lead }),
  visibilityFrom: format(new Date(data.visibilityFrom), "yyyy. MM. dd., HH:mm"),
  ...(data.contentBody && {
    contentBody: transformHTMLContent(data.contentBody),
  }),
  slug: data.slug,
  ...(data.media?.length && {
    media: transformMediaResponse(data.media),
  }),
  ...(data.cover && {
    cover: transformMediaResponse(data.cover),
  }),
  tag: data.tag.length ? data.tag : [],
  ...(data.seoLead && { seoLead: data.seoLead }),
  ...(data.seoTitle && { seoTitle: data.seoTitle }),
  ...(data.seoCover && {
    seoCover: transformMediaResponse(data.seoCover),
  }),
  ...(data.customInsDate &&
    data.customInsDatetimeVisible && {
      customInsDate: data.customInsTimeVisible
        ? dateAndTimeFormat(data.customInsDate)
        : dateFormat(data.customInsDate),
    }),
  ...(data.customModDate &&
    data.modDateVisible && {
      customModDate: data.customModTimeVisible
        ? dateAndTimeFormat(data.customModDate)
        : dateFormat(data.customModDate),
    }),
  modDateVisible: data.modDateVisible,
  socialMediaIconsVisible: data.socialMediaIconsVisible ?? false,
  contentCategoryId: data.contentCategoryId,
  featuredContentCalculated: data.featuredContentCalculated,
  masterContentTitle: data.masterContentTitle,
  // socialMediaIconsVisible: data.socialMediaIconsVisible ?? false,
  // contentCategoryId: data.contentCategory || data.contentCategoryId,
  menuPath: data.menuPath,
});

const transformContentsResponse = (response: any): CmsTableData => ({
  totalNumberOfItems: response.totalNumberOfItems,
  data: response.data.map((item: any) => transformContent(item)),
});

const transformMenu = (data: CMSPublicMenuItem[]): Menu => {
  const transformMenuItem = (current: CMSPublicMenuItem): MenuItem => ({
    id: current.id,
    caption: current.caption,
    path: current.path,
    ...(current.contents?.length && { contents: current.contents }),
    ...(current.url && { url: current.url }),
    children:
      current?.children?.map((menu: CMSPublicMenuItem) => {
        return transformMenuItem(menu);
      }) ?? [],
    menuType: current.menuType,
  });

  return data.reduce((acc: Menu, curr) => {
    let accumulator = acc;
    curr.menuCategory.forEach((category: CMSMenuCategory) => {
      const lowerCaseName = category.name.toLowerCase();
      accumulator = {
        ...accumulator,
        [lowerCaseName]: accumulator[lowerCaseName]?.length
          ? [...accumulator[lowerCaseName], transformMenuItem(curr)]
          : [transformMenuItem(curr)],
      };
    });
    return accumulator;
  }, {});
};

export const contentTransformer = {
  transformMenu,
  transformContent,
  transformContentsResponse,
};
