import {
  CountryCode,
  CreateHdCustomerVehicleRequest,
  HdCustomerVehicle,
  HdCustomerVehicleListResponse,
  VehiclePriceCategory,
} from "@/core/dataTypes/types";
import { AppState } from "@/store";
import { Module } from "vuex";
import * as AccountService from "@/core/api/accountService.api";
import router from "@/router";
import { ERoutes } from "@/core/dataTypes/enum";
import SnackService from "@/core/services/snackbar.service";
import { alertFacade } from "@/store/modules/alert/alert.facade";
import { i18n } from "@/locales/i18n";
import { getLanguageStaticRoute } from "@/core/utils/router.util";

export interface VehicleState {
  hdCustomerVehicleMaxNumber: number;
  hdCustomerVehicleList: HdCustomerVehicle[];
  countries: CountryCode[];
  countriesLoading: boolean;
  vehiclePriceCategory: VehiclePriceCategory[];
  hdCustomerVehicleListLoading: boolean;
  createHdCustomerVehicleLoading: boolean;
  setDefaultHdCustomerVehicleLoading: boolean;
  plateNumberValidationLoading: boolean;
  vehicleFormSubmitLoading: boolean;
}

export const initialVehicleState: VehicleState = {
  hdCustomerVehicleMaxNumber: 0,
  hdCustomerVehicleList: [],
  countries: [],
  vehiclePriceCategory: [],
  countriesLoading: false,
  hdCustomerVehicleListLoading: true,
  createHdCustomerVehicleLoading: false,
  setDefaultHdCustomerVehicleLoading: false,
  plateNumberValidationLoading: false,
  vehicleFormSubmitLoading: false,
};

export const vehicleModule: Module<VehicleState, AppState> = {
  namespaced: true,
  state: initialVehicleState,
  mutations: {
    vehicleListRequest: (state) => {
      state.hdCustomerVehicleListLoading = true;
    },
    vehicleListSuccess: (state) => {
      state.hdCustomerVehicleListLoading = false;
    },
    vehicleListFail: (state) => {
      state.hdCustomerVehicleListLoading = false;
    },
    countriesRequest: (state) => {
      state.countriesLoading = true;
    },
    countriesSuccess: (state) => {
      state.countriesLoading = false;
    },
    countriesFail: (state) => {
      state.countriesLoading = false;
    },
    deleteHdCustomerVehicleRequest: (state) => {
      state.setDefaultHdCustomerVehicleLoading = true;
    },
    deleteHdCustomerVehicleSuccess: (state) => {
      state.setDefaultHdCustomerVehicleLoading = false;
    },
    deleteHdCustomerVehicleFail: (state) => {
      state.setDefaultHdCustomerVehicleLoading = false;
    },
    setDefaultHdCustomerVehicleLoadingRequest: (state) => {
      state.setDefaultHdCustomerVehicleLoading = true;
    },
    setDefaultHdCustomerVehicleLoadingSuccess: (state) => {
      state.setDefaultHdCustomerVehicleLoading = false;
    },
    setDefaultHdCustomerVehicleLoadingFail: (state) => {
      state.setDefaultHdCustomerVehicleLoading = false;
    },
    createHdCustomerVehicleRequest: (state) => {
      state.createHdCustomerVehicleLoading = true;
    },
    createHdCustomerVehicleSuccess: (state) => {
      state.createHdCustomerVehicleLoading = false;
    },
    createHdCustomerVehicleFail: (state) => {
      state.createHdCustomerVehicleLoading = false;
    },
    plateNumberValidationLoading: (state) => {
      state.plateNumberValidationLoading = true;
    },
    plateNumberValidationLoaded: (state) => {
      state.plateNumberValidationLoading = false;
    },
    vehicleFormSubmitLoading: (state) => {
      state.vehicleFormSubmitLoading = true;
    },
    vehicleFormSubmitLoaded: (state) => {
      state.vehicleFormSubmitLoading = false;
    },
    setVehicleList: (state, payload: HdCustomerVehicleListResponse) => {
      state.hdCustomerVehicleList =
        payload.hdCustomerVehicleList.hdCustomerVehicleList;
      state.hdCustomerVehicleMaxNumber = payload.hdCustomerVehicleMaxNumber;
    },
    setCountries: (state, payload: CountryCode[]) => {
      state.countries = payload;
    },
    setVehiclePriceCategories: (state, payload: VehiclePriceCategory[]) => {
      state.vehiclePriceCategory = payload;
    },
  },
  actions: {
    getHdCustomerVehicleList: ({ commit, rootState }) => {
      const customerId = rootState.user?.user?.customerNumber as string;
      commit("vehicleListRequest");
      return AccountService.getHdCustomerVehicleList(customerId)
        .then((response) => {
          commit("setVehicleList", response);
          commit("vehicleListSuccess");
        })
        .catch((error) => {
          commit("vehicleListFail");
          throw Error(error);
        });
    },

    createHdCustomerVehicle: (
      { commit, rootState },
      form: CreateHdCustomerVehicleRequest
    ) => {
      commit("createHdCustomerVehicleRequest");
      alertFacade.resetAlert();
      const customerId = rootState.user?.user?.customerNumber as string;
      AccountService.createHdCustomerVehicle(form, customerId)
        .then(() => {
          commit("createHdCustomerVehicleSuccess");
          router.push(getLanguageStaticRoute(ERoutes.VEHICLE));
          SnackService.open({
            text: i18n.tc("VEHICLE_LIST_PAGE.MESSAGE1"),
          });
        })
        .catch((error) => {
          commit("createHdCustomerVehicleFail");
          throw Error(error);
        });
    },
    setDefaultHdCustomerVehicle: (
      { commit, rootState, dispatch },
      payload: string
    ) => {
      const customerId = rootState.user?.user?.customerNumber as string;
      commit("setDefaultHdCustomerVehicleLoadingRequest");
      AccountService.setDefaultHdCustomerVehicle(customerId, payload)
        .then(() => {
          dispatch("refreshHdCustomerVehicleList").then(
            () => {
              commit("setDefaultHdCustomerVehicleLoadingSuccess");
              SnackService.open({
                text: i18n.tc("VEHICLE_LIST_PAGE.MESSAGE2"),
              });
            },
            () => {
              commit("setDefaultHdCustomerVehicleLoadingFail");
            }
          );
        })
        .catch((error) => {
          commit("setDefaultHdCustomerVehicleLoadingFail");
          throw Error(error);
        });
    },
    deleteHdCustomerVehicle: (
      { commit, rootState, dispatch },
      payload: string
    ) => {
      const customerId = rootState.user?.user?.customerNumber as string;
      commit("deleteHdCustomerVehicleRequest");
      AccountService.deleteHdCustomerVehicle(customerId, payload)
        .then(() => {
          dispatch("refreshHdCustomerVehicleList").then(
            () => {
              commit("deleteHdCustomerVehicleSuccess");
              SnackService.open({
                text: i18n.tc("VEHICLE_LIST_PAGE.MESSAGE3"),
              });
            },
            () => {
              commit("deleteHdCustomerVehicleFail");
            }
          );
        })
        .catch((error) => {
          commit("deleteHdCustomerVehicleFail");
          throw Error(error);
        });
    },

    getVehiclePriceCategories: ({ commit }) => {
      AccountService.getVehiclePriceCategories()
        .then((response) => {
          commit(
            "setVehiclePriceCategories",
            response.vehiclePriceCategoryList.vehiclePriceCategory
          );
        })
        .catch((error) => {
          throw Error(error);
        });
    },

    getCountryCodes: ({ commit }) => {
      commit("countriesRequest");
      AccountService.getCountryCodes()
        .then((response) => {
          commit("countriesSuccess");
          commit("setCountries", response);
        })
        .catch((error) => {
          commit("countriesFail");
          throw Error(error);
        });
    },

    refreshHdCustomerVehicleList: ({ commit, rootState }) => {
      const customerId = rootState.user?.user?.customerNumber as string;
      return AccountService.getHdCustomerVehicleList(customerId)
        .then((response) => {
          commit("setVehicleList", response);
        })
        .catch((error) => {
          commit("vehicleListFail");
          throw Error(error);
        });
    },
    setPlateNumberLoading: ({ commit }, payload: boolean) => {
      if (payload) {
        commit("plateNumberValidationLoading");
      } else {
        commit("plateNumberValidationLoaded");
      }
    },
    setVehicleFormSubmitLoading: ({ commit }, payload: boolean) => {
      if (payload) {
        commit("vehicleFormSubmitLoading");
      } else {
        commit("vehicleFormSubmitLoaded");
      }
    },
  },
};
