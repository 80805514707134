
































import { onUnmounted, ref, watch } from "@vue/composition-api";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "CheckboxInput",
  props: {
    value: [Boolean, Number, String, Array],
    label: String,
    id: String,
    disabled: Boolean,
    hint: String,
    ripple: {
      type: Boolean,
      default: true,
    },
    backgroundColor: String,
    color: String,
    dense: {
      type: Boolean,
      default: false,
    },
    error: Boolean,
    errorCount: Number,
    errorMessages: [String, Array],
    falseValue: {
      // előre lehet definiálni, hogy az FALSE érték mi legyen
      type: [Number, String, Array, Boolean],
      default: false,
    },
    trueValue: {
      // előre lehet definiálni, hogy az TRUE érték mi legyen
      type: [Number, String, Array, Boolean],
      default: true,
    },
    hideDetails: {
      type: [Boolean, String],
      default: false,
    },
    messages: [String, Array],
    multiple: {
      type: Boolean,
      default: false,
    },
    success: Boolean,
    successMessages: [String, Array],
    ariaLabel: String,
    ariaRequired: Boolean,
  },

  setup(props, { emit }) {
    const innerValue = ref(props.value);
    const stopWatch = watch(
      () => props.value,
      (newVal, oldVal) => {
        if (oldVal !== newVal) {
          innerValue.value = newVal;
        }
      }
    );
    onUnmounted(() => {
      stopWatch();
    });
    const onChange = (value: boolean) => {
      emit("input", value);
    };
    return {
      innerValue,
      onChange,
    };
  },
});

// Ha multi checkbox-ot szeretnénk használni, azaz több checkbox-ot ahol a v-model egy tömb, akkor nem elég az onChange eseményt emittálni, mert az felülírja a tömb elemeit minden alkalommal.
// https://jsfiddle.net/robertkern/oovb8ym7/
