import Vue from "vue";
import DialogWrapper from "@/components/dialog/DialogWrapper.vue";
import AlertDialog from "@/components/dialog/AlertDialog.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import vuetify from "@/plugins/vuetify";
import { i18n } from "@/locales/i18n";
import {
  AlertDialogPropsType,
  DialogOptionsType,
  DialogResultType,
  ConfirmDialogPropsType,
} from "../dataTypes/types";
import { alertFacade } from "@/store/modules/alert/alert.facade";

class Dialog {
  dialog: Vue;

  constructor(dialogOptions: DialogOptionsType) {
    const ComponentClass = Vue.extend(DialogWrapper);
    this.dialog = new ComponentClass({
      vuetify,
      i18n,
      propsData: dialogOptions,
    });

    this.dialog.$mount();
    this._handleDialogCloseEvent();
    this._handleDialogResultEvent();
  }

  dialogUnmounted(callback: () => void): void {
    this.dialog.$on("dialogUnmounted", callback);
  }

  private _destroyDialog(): void {
    this.dialog.$destroy();
  }

  private _handleDialogCloseEvent(): void {
    this.dialog.$on("dialogClose", (): void => this._destroyDialog());
  }

  private _handleDialogResultEvent(): void {
    this.dialog.$on("dialogResult", (result: DialogResultType): void => {
      if (result && result.autoClose) {
        this._destroyDialog();
      }
    });
  }
}

class DialogService {
  dialogInstances: Vue[] = [];
  open(dialogOptions: DialogOptionsType): Vue {
    const dialog = new Dialog({
      ...dialogOptions,
      dialogLevel: this.getDialogInstancesCount(),
    });
    dialog.dialogUnmounted(() => this._removeDialogInstance(dialog.dialog));
    this.dialogInstances.push(dialog.dialog);
    alertFacade.setAlertDialogVisibility(true);

    return dialog.dialog;
  }

  getDialogInstancesCount(): number {
    return this.dialogInstances.length;
  }

  alertDialog(
    dialogOptions: Omit<DialogOptionsType<AlertDialogPropsType>, "component">
  ): Vue {
    return this.open({ ...dialogOptions, component: AlertDialog });
  }

  confirmDialog(
    dialogOptions: Omit<DialogOptionsType<ConfirmDialogPropsType>, "component">
  ): Vue {
    return this.open({ ...dialogOptions, component: ConfirmDialog });
  }

  private _removeDialogInstance(dialog: Vue): void {
    this.dialogInstances = this.dialogInstances.filter((i) => i !== dialog);
    if (!this.dialogInstances.length) {
      alertFacade.setAlertDialogVisibility(false);
    }
  }

  closeAll(): void {
    if (this.dialogInstances.length) {
      this.dialogInstances.map((dialog) => {
        dialog.$destroy();
      });
      alertFacade.setAlertDialogVisibility(false);
    }
  }
}

export default new DialogService();
