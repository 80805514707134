import {
  CountyListRequest,
  CountyListResponse,
  PreemptionResponse,
  StickerPriceRequest,
  StickerPriceResponse,
  StickerPurchasePeriodList,
  StickerPurchasePeriodRequest,
  StickerType,
  StickerValidityEndRequest,
  StickerValidityEndResponse,
  StickerValidityStartRequest,
  StickerValidityStartResponse,
  CheckStickerOverlapResponseType,
  CheckStickerOverlapRequestType,
  CartElementsRequest,
  ActiveCartResponse,
  CartResponse,
  EmailNotifyValidityType,
  BillingAddressRequest,
  CartHistoryQueryRequest,
  CustomerADHOC,
  CartAdhocExtraData,
  AdHocValidityCheck,
  AdhocPaymentResult,
  CartHistoryQueryRow,
  CartHighwayStickerQueryResponse,
} from "../dataTypes/types";
import {
  transformAdhocHistoryData,
  transformGetPreemption,
  transformInvoiceData,
  transformStickerPurchasePeriod,
  transformStickerTypeList,
} from "@/core/transformers/sticker.transformer";
import * as StickerService from "@/core/services/sticker.service";
import httpService from "../services/http.service";
import { userFacade } from "@/store/modules/user/user.facade";

export const getStickerTypeList = (
  vehiclePriceCategory: string
): Promise<StickerType[]> =>
  httpService
    .get<never>(
      `HighwayPurchaseService/highwayPurchase/stickerType/${vehiclePriceCategory}/list`
    )
    .then((response) => transformStickerTypeList(response.data));

export const postStickerPurchasePeriod = (
  requestData: StickerPurchasePeriodRequest
): Promise<StickerPurchasePeriodList[]> =>
  httpService
    .post<never>(
      `HighwayPurchaseService/highwayPurchase/stickerPurchasePeriod`,
      requestData
    )
    .then((response) => transformStickerPurchasePeriod(response.data));

export const postStickerValidityStart = (
  requestData: StickerValidityStartRequest
): Promise<StickerValidityStartResponse> =>
  httpService
    .post<never>(
      `HighwayPurchaseService/highwayPurchase/stickerValidityStart`,
      requestData
    )
    .then((response) => response.data);

export const postStickerValidityEnd = (
  requestData: StickerValidityEndRequest
): Promise<StickerValidityEndResponse> =>
  httpService
    .post<never>(
      `HighwayPurchaseService/highwayPurchase/stickerValidityEnd`,
      requestData
    )
    .then((response) => response.data);

export const postCountyList = (
  requestData: CountyListRequest
): Promise<CountyListResponse> =>
  httpService
    .post<never>(
      `HighwayPurchaseService/highwayPurchase/county/list`,
      requestData
    )
    .then((response) => response.data);

export const getPreemption = (
  stickerType: string
): Promise<PreemptionResponse> =>
  httpService
    .get<never>(
      `HighwayPurchaseService/highwayPurchase/preemption/${stickerType}`
    )
    .then((response) => transformGetPreemption(response.data));

export const postStickerPrice = (
  requestData: StickerPriceRequest
): Promise<StickerPriceResponse> =>
  httpService
    .post<never>(
      `HighwayPurchaseService/highwayPurchase/stickerPrice`,
      requestData
    )
    .then((response) => response.data);

export const postCheckStickerOverlap = (
  requestData: CheckStickerOverlapRequestType
): Promise<CheckStickerOverlapResponseType> =>
  httpService
    .post<never>(
      `HighwayPurchaseService/highwayPurchase/checkStickerOverlap`,
      requestData
    )
    .then((response) => response.data);

export const getAdhocPaymentResult = (
  cartId: string
): Promise<AdhocPaymentResult> => {
  const config = {
    headers: {
      ...(userFacade.notLoggedInUser() && {
        "X-ADHOC-ID": StickerService.getAdHocCartToken(),
      }),
    },
  };
  return httpService
    .get<never>(
      `HighwayPurchaseService/highwayPurchase/cart/${cartId}/adhocPaymentResult`,
      config
    )
    .then((response) => transformAdhocHistoryData(response.data));
};

export const postCartHistoryQuery = (
  customerId: string,
  requestData: CartHistoryQueryRequest
): Promise<CartHistoryQueryRow[]> =>
  httpService
    .post<never>(
      `HighwayPurchaseService/highwayPurchase/customer/${customerId}/cartHistory/query`,
      requestData
    )
    .then((response) => transformInvoiceData(response.data));

export const postHighwayStickerQuery = (
  customerId: string,
  requestData: CartHighwayStickerQueryResponse
): Promise<CartHighwayStickerQueryResponse> =>
  httpService
    .post<never>(
      `HighwayPurchaseService/highwayPurchase/customer/${customerId}/highwaySticker/query`,
      requestData
    )
    .then((response) => response.data);

export const getInvoice = (
  customerId: string,
  invoiceId: string
): Promise<Blob> =>
  httpService
    .get<never>(
      `BillingService/invoice/customer/${customerId}/document/${invoiceId}`,
      {
        responseType: "blob",
      }
    )
    .then((response) => response.data);

export const getDocument = (
  customerId: string,
  requestId: string
): Promise<Blob> =>
  httpService
    .get<never>(
      `DocumentService/document/${customerId}/HIGHWAY_STICKER/${requestId}`,
      {
        // headers: {
        //   "Content-Type": "application/pdf",
        // },
        // responseType: "arraybuffer",
        responseType: "blob",
      }
    )
    .then((response) => response.data);

export const deleteCartElement = (
  customerId: string,
  cartElementId: string
): Promise<void> => {
  const config = {
    headers: {
      ...(userFacade.notLoggedInUser() && {
        "X-ADHOC-ID": StickerService.getAdHocCartToken(),
      }),
    },
  };
  return httpService
    .delete<never>(
      `HighwayPurchaseService/highwayPurchase/customer/${customerId}/cartElement/${cartElementId}`,
      config
    )
    .then((response) => response.data);
};

export const postCartElements = (
  customerId: string,
  requestData: CartElementsRequest
): Promise<void | CustomerADHOC> => {
  const config = {
    headers: {
      ...(userFacade.notLoggedInUser() && {
        "X-ADHOC-ID": StickerService.getAdHocCustomer().token,
      }),
    },
  };
  return httpService
    .post<never>(
      `HighwayPurchaseService/highwayPurchase/customer/${customerId}/cartElements`,
      requestData,
      config
    )
    .then((response) => response.data);
};

export const getActiveCart = (
  customerId: string
): Promise<ActiveCartResponse> =>
  httpService
    .get<never>(
      `HighwayPurchaseService/highwayPurchase/customer/${customerId}/cart`
    )
    .then((response) => response.data);

export const getAdHocValidityCheck = (
  cartId: string
): Promise<AdHocValidityCheck> => {
  const config = {
    headers: {
      ...(userFacade.notLoggedInUser() && {
        "X-ADHOC-ID": StickerService.getAdHocCartToken(),
      }),
    },
  };
  return httpService
    .get<never>(
      `HighwayPurchaseService/highwayPurchase/adhocValidityCheck/cart/${cartId}`,
      config
    )
    .then((response) => response.data);
};

export const getCart = (
  customerId: string,
  cartId: string
): Promise<CartResponse> => {
  const config = {
    headers: {
      ...(userFacade.notLoggedInUser() && {
        "X-ADHOC-ID": StickerService.getAdHocCartToken(),
      }),
    },
  };
  return httpService
    .get<never>(
      `HighwayPurchaseService/highwayPurchase/customer/${customerId}/cart/${cartId}`,
      config
    )
    .then((response) => response.data);
};
export const getAdhocExtraData = (
  cartId: string | unknown
): Promise<CartAdhocExtraData> => {
  return httpService
    .get<never>(
      `HighwayPurchaseService/highwayPurchase/cart/${cartId}/adhocData`,
      {
        headers: {
          "X-ADHOC-ID": StickerService.getAdHocCartToken(),
        },
      }
    )
    .then((response) => response.data);
};

export const putAdhocExtraData = (
  cartId: string | unknown,
  payload: CartAdhocExtraData
): Promise<void> => {
  return httpService
    .put<never>(
      `HighwayPurchaseService/highwayPurchase/cart/${cartId}/adhocData`,
      payload,
      {
        headers: {
          "X-ADHOC-ID": StickerService.getAdHocCartToken(),
        },
      }
    )
    .then((response) => response.data);
};
export const putNotificationSettings = (
  customerId: string,
  cartId: string,
  payload: EmailNotifyValidityType
): Promise<void> =>
  httpService
    .put<never>(
      `HighwayPurchaseService/highwayPurchase/customer/${customerId}/cart/${cartId}/notificationSettings`,
      {
        notifyValidityStart: payload.notifyValidityStartValue,
        notifyValidityEnd: payload.notifyValidityEndValue,
      }
    )
    .then((response) => response.data);

export const putBillingAddress = (
  customerId: string,
  cartId: string,
  requestData: BillingAddressRequest
): Promise<void> =>
  httpService
    .put<never>(
      `HighwayPurchaseService/highwayPurchase/customer/${customerId}/cart/${cartId}/billingAddress`,
      requestData
    )
    .then((response) => response.data);

export const putFinalizeInvoiceContact = (
  customerId: string,
  cartId: string
): Promise<void> => {
  const config = {
    headers: {
      ...(userFacade.notLoggedInUser() && {
        "X-ADHOC-ID": StickerService.getAdHocCustomer().token,
      }),
    },
  };
  return httpService
    .put<never>(
      `HighwayPurchaseService/highwayPurchase/customer/${customerId}/cart/${cartId}`,
      {},
      config
    )
    .then((response) => response.data);
};
