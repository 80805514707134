





















import { defineComponent, ref } from "@vue/composition-api";
import SnackService from "@/core/services/snackbar.service";

export default defineComponent({
  name: "Snackbar",
  props: {
    id: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      default: true,
    },
    text: String,
    timeout: {
      type: Number,
      default: 4000,
    },
    color: {
      type: String,
      default: "#1e1e1e",
    },
    maxWidth: {
      type: Number,
      default: 345,
    },
    minWidth: {
      type: Number,
      default: 300,
    },
  },
  setup(props, { emit }) {
    const show = ref(props.open);
    const onInput = () => {
      emit("snackbarClose", props.id);
    };

    const onClose = () => {
      SnackService.close(props.id);
    };

    return {
      props,
      show,
      onInput,
      onClose,
    };
  },
});
