import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    disable: false, // Kikapcsolja a Vuetify stylesheet alapjait.
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#0054A6",
        secondary: "#FF9F12",
        accent: "#A2C5E3", //#85AEFA
        error: "#D0201F",
        // info: "#2196F3",
        success: "#008500",
        // warning: "#FFC107",
      },
    },
  },
});
