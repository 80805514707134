import { AxiosRequestConfig } from "axios";
import authService from "../services/auth.service";
import { userFacade } from "@/store/modules/user/user.facade";
import store from "@/store";

export const awaitRefresh = async (
  requestConfig: AxiosRequestConfig
): Promise<void> => {
  await new Promise((resolve) => {
    if (
      userFacade.refreshLoading() &&
      authService.hasValidToken() &&
      requestConfig.url !== authService.refreshApiUrl
    ) {
      const waitRefreshUnsubscribe = store.subscribe((mutation, state) => {
        if (!state.user?.refreshLoading) {
          waitRefreshUnsubscribe();
          resolve(true);
        }
      });
    } else {
      resolve(true);
    }
  });
  authService.refreshSessionHandler(requestConfig.url as string);
};
