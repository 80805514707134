/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ValidationRuleFunction } from "vee-validate/dist/types/types";
import { validationRegExp } from "./validationRegExp";
import { i18n } from "@/locales/i18n";

export const scriptTagCharacter = (value: string): boolean =>
  !/[<>]+/.test(value);
export const phoneNumberCharacter = (value: string): boolean =>
  value.split("")[0] !== "0";
export const specialCharacter = (value: string): boolean =>
  /[!@€#ł$~ß%^£¥•¤&*()_+\-=\\[\]{};':"\\|,.<>\\/?]+/.test(value);
export const numberCharacter = (value: string): boolean => /\d/.test(value);
export const lowerUpperCaseCharacter = (value: string): boolean =>
  /[A-ZÁ-ÚŐŰÜ]/.test(value) && /[a-zá-úőűü]/.test(value);
export const minCharacter = (value: string): boolean => value.length >= 8;
export const customRequired = (value: string): boolean => !!value.length;
export const checkBox = (value: boolean): boolean => value;
export const taxGroupId = (value: string): boolean => {
  const splitValue = value.split("-");
  return splitValue[1] === "5";
};
export const emailWithAccent = (value: string): boolean =>
  // eslint-disable-next-line no-control-regex
  /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,24}$/g.test(value);
export const hungarienTaxNumberWithSample: ValidationRuleFunction = (
  value: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { pattern }: Record<string, any>
): boolean => {
  const regex = pattern
    ? new RegExp(pattern)
    : validationRegExp.defaultHungarienTaxNumber;
  const defaultPatternTest = regex.test(value);
  const splitValue = value.split("-");

  if (defaultPatternTest && splitValue.length === 3) {
    const torzsSzam = splitValue[0].split("");
    const afaKod: number = +splitValue[1];
    const hatosagKod: number = +splitValue[2];
    const acdv = [9, 7, 3, 1];

    const testAfaKod: boolean = [1, 2, 3, 4, 5].includes(afaKod);
    const testHatosagKod: boolean =
      (hatosagKod > 1 && hatosagKod < 21) ||
      (hatosagKod > 21 && hatosagKod < 45) ||
      hatosagKod === 51;

    let xcdv = 0;

    for (let i = 0; i < 7; i++) {
      xcdv = xcdv + parseInt(torzsSzam[i]) * acdv[i % 4];
    }

    const testTorzsSzam: boolean = +torzsSzam[7] == (10 - (xcdv % 10)) % 10;

    return testAfaKod && testHatosagKod && testTorzsSzam;
  } else {
    return false;
  }
};

export const customRegexWithSample: ValidationRuleFunction = (
  value: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { pattern }: Record<string, any>
): boolean => {
  return pattern.test(value);
};

export const extendedPassword = (value: string): string | boolean => {
  const errors = [];
  if (!numberCharacter(value)) {
    errors.push(i18n.tc("NEW_PASSWORD_PAGE.ERRORS.NUMBER_CHARACTERS"));
  }
  if (!lowerUpperCaseCharacter(value)) {
    errors.push(i18n.tc("NEW_PASSWORD_PAGE.ERRORS.LOWER_UPPERCASE"));
  }
  if (!specialCharacter(value)) {
    errors.push(i18n.tc("NEW_PASSWORD_PAGE.ERRORS.SPECIAL_CHARACTERS"));
  }
  if (value.length < 8) {
    errors.push(i18n.tc("NEW_PASSWORD_PAGE.ERRORS.MIN_CHARACTERS"));
  }
  return errors.length === 0 ? true : errors.join(", ");
};
