





































import { defineComponent } from "@vue/composition-api";
import {
  dialogCloseEmitter,
  dialogResultEmitter,
} from "@/core/utils/dialog/dialogEmitter";
import router from "@/router";
import { ERoutes } from "@/core/dataTypes/enum";
import authService from "@/core/services/auth.service";
import { tabFocusService } from "@/core/services/tabFocus.service";
import { getLanguageStaticRoute } from "@/core/utils/router.util";

export default defineComponent({
  name: "PasswordExpiredDialog",
  setup(props, ctx) {
    const changePassword = (): void => {
      router.push(getLanguageStaticRoute(ERoutes.EXPIRED_PASSWORD));
      dialogResultEmitter(ctx, { autoClose: true, payload: null });
    };

    const close = (): void => {
      if (router.currentRoute.path !== ERoutes.LOGIN) {
        authService.resetStateAndSessionTokens();
        router.push(getLanguageStaticRoute(ERoutes.LOGIN)).catch((err) => err);
      }
      dialogCloseEmitter(ctx);
      tabFocusService.setFocus("nuszBack");
    };

    return {
      changePassword,
      close,
    };
  },
});
