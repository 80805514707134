import { AlertData } from "@/core/dataTypes/types";
import store from "@/store";

const alert = (): AlertData | undefined => store.state.alert?.alert;
const setAlert = (payload: AlertData): Promise<void> =>
  store.dispatch("alert/setAlert", payload);
const resetAlert = (): Promise<void> => store.dispatch("alert/resetAlert");
const visibleInDialog = (): boolean | undefined =>
  store.state.alert?.visibleInDialog;
const setAlertDialogVisibility = (payload: boolean): Promise<void> =>
  store.dispatch("alert/setAlertVisibility", payload);

export const alertFacade = {
  alert,
  setAlert,
  resetAlert,
  visibleInDialog,
  setAlertDialogVisibility,
};
