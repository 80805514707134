













































import { computed, defineComponent } from "@vue/composition-api";
import MenuListItem from "@/components/headers/MenuList/MenuListItem.vue";

export default defineComponent({
  name: "MenuList",
  components: {
    MenuListItem,
  },
  props: {
    menuList: {
      type: Array,
      required: true,
    },
    vListAttr: Object,
    mobileMenu: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, root }) {
    const routeParams = computed(() => root.$route.params);
    const clickedOnMenuItem = () => {
      emit("clickedOnMenuItem");
    };
    return {
      routeParams,
      clickedOnMenuItem,
    };
  },
});
