import {
  CategoryIcon,
  OtherCategoryHelperResult,
} from "@/core/dataTypes/types";
import { i18n } from "@/locales/i18n";

export const vehiclePriceCategories = [
  {
    icon: "mdi-motorbike",
    name: "D1M",
  },
  {
    icon: "mdi-car-hatchback",
    name: "D1",
  },
  {
    icon: "mdi-truck",
    name: "D2",
  },
  {
    icon: "mdi-bus-side",
    name: "B2",
  },
  {
    icon: "mdi-truck-trailer",
    name: "U",
  },
];

export const vehiclePriceCategoriesNewCar = [
  {
    icon: "mdi-motorbike",
    name: "D1M",
  },
  {
    icon: "mdi-car-hatchback",
    name: "D1",
  },
  {
    icon: "mdi-truck",
    name: "D2",
  },
  /*  {
    icon: "mdi-bus-side",
    name: "B2",
  },*/
  {
    icon: "mdi-truck-trailer",
    name: "U",
  },
];

export const getVehiclePriceCategoryIcon = (name: string): string =>
  <string>(
    vehiclePriceCategories.find((category) => category.name === name)?.icon
  );

export const hugoCategories: string[] = ["N2", "N3", "O3", "O4"];

export const freeCategories: string[] = [
  "LJ",
  "L1e",
  "L2e",
  "L6e",
  "Ra1",
  "Ra2",
  "Ra3",
  "Ra4",
  "Rb1",
  "Rb2",
  "Rb3",
  "Rb4",
  "Sa1",
  "Sa2",
  "Sb1",
  "Sb2",
  "T1",
  "T2",
  "T3",
  "T4",
  "T5",
];

export const stickerD1: CategoryIcon = {
  icon: "mdi-car-hatchback",
  name: "D1",
};
export const stickerD2: CategoryIcon = {
  icon: "mdi-truck",
  name: "D2",
};
export const stickerD1M: CategoryIcon = {
  icon: "mdi-motorbike",
  name: "D1M",
};
export const stickerB2: CategoryIcon = {
  icon: "mdi-bus-side",
  name: "B2",
};
export const stickerU: CategoryIcon = {
  icon: "mdi-truck-trailer",
  name: "U",
};

export const otherCategoryHelperSelector = (
  category: string
): OtherCategoryHelperResult => {
  switch (category) {
    case "LJ":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.LJ"),
      };
    case "L1e":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.L1e"),
      };
    case "L2e":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.L2e"),
      };
    case "L3e":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.L3e"),
        stickers: [stickerD1],
      };
    case "L4e":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.L4e"),
        stickers: [stickerD1],
      };
    case "L5e":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.L5e"),
        stickers: [stickerD1],
      };
    case "L6e":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.L6e"),
      };
    case "L7e":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.L7e"),
        stickers: [stickerD1],
      };
    case "M1Max7":
    case "M1GMax7":
      return {
        text: i18n.tc(
          "VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.M1_M1G_MAX_7"
        ),
        stickers: [stickerD1],
      };
    case "M1":
    case "M1G":
      return {
        text: i18n.tc(
          "VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.M1_M1G"
        ),
        stickers: [stickerD2],
      };
    case "M2":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.M2"),
        stickers: [stickerB2],
      };
    case "M3":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.M3"),
        stickers: [stickerB2],
      };
    case "N1":
    case "N1G":
      return {
        text: i18n.tc(
          "VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.N1_N1G"
        ),
        stickers: [stickerD2],
      };
    case "N2":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.N2"),
      };
    case "N3":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.N3"),
      };
    case "O1":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.O1"),
        stickers: [stickerU],
      };
    case "O2":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.O2"),
        stickers: [stickerU],
      };
    case "O3":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.O3"),
      };
    case "O4":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.O4"),
      };
    case "Ra1":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.Ra1"),
      };
    case "Ra2":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.Ra2"),
      };
    case "Ra3":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.Ra3"),
      };
    case "Ra4":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.Ra4"),
      };
    case "Rb1":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.Rb1"),
      };
    case "Rb2":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.Rb2"),
      };
    case "Rb3":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.Rb3"),
      };
    case "Rb4":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.Rb4"),
      };
    case "Sa1":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.Sa1"),
      };
    case "Sa2":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.Sa2"),
      };
    case "Sb1":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.Sb1"),
      };
    case "Sb2":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.Sb2"),
      };
    case "T1":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.T1"),
      };
    case "T2":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.T2"),
      };
    case "T3":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.T3"),
      };
    case "T4":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.T4"),
      };
    case "T5":
      return {
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.OTHER_CATEGORY_RESULT_TEXT.T5"),
      };
    default:
      return { text: "" };
  }
};
