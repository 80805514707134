import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from "axios";
import { httpCMSErrorHandler } from "../interceptorHandlers/httpCMSErrorHandler";
import { uuid } from "../utils/uuid";
import { awaitRefresh } from "@/core/interceptorHandlers/awaitRefreshHandler";
import { commonFacade } from "@/store/modules/common/common.facade";

class CmsHttpService {
  http: AxiosInstance;
  protected source: CancelTokenSource;
  private _isPending = 0;
  private tenant = process.env.VUE_APP_TENANT ?? "ematrica";

  constructor() {
    this.http = axios.create({
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      },
      baseURL: `${process.env.VUE_APP_CMS_SERVICE}/api/public/${this.tenant}/`,
    });
    this.source = axios.CancelToken.source();

    this.http.interceptors.request.use(
      async (request) => {
        this._isPending++;
        await awaitRefresh(request);
        return this._createRequestConfig(request);
      },
      (error) => {
        this._decreasePending();
        httpCMSErrorHandler(error.response);
      }
    );
    this.http.interceptors.response.use(
      (response) => {
        this._decreasePending();
        return response;
      },
      (error) => {
        this._decreasePending();
        httpCMSErrorHandler(error);
        return Promise.reject(error);
      }
    );
  }

  get<R>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    return this.http.get(url, {
      ...config,
    });
  }

  post<R, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<R>> {
    return this.http.post(url, data, {
      ...config,
    });
  }

  put<R, D = unknown>(
    url: string,
    data: D,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<R>> {
    return this.http.put(url, data, {
      ...config,
    });
  }

  delete<R>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<R>> {
    return this.http.delete(url, {
      ...config,
    });
  }

  isPending(): boolean {
    return !!this._isPending;
  }

  cancel(): void {
    this.source.cancel("http kérés megszakítva");
    this.source = axios.CancelToken.source();
  }

  private _decreasePending(): void {
    this._isPending > 0 ? this._isPending-- : undefined;
  }

  public setBaseURL(baseUrl: string) {
    this.http.defaults.baseURL = baseUrl;
  }

  public setTenant(tenant: string) {
    this.tenant = tenant;
  }

  public getTenant(tenant: string) {
    this.tenant = tenant;
  }

  private _createRequestConfig(
    request: AxiosRequestConfig
  ): AxiosRequestConfig {
    const lang =
      localStorage.getItem("currLang") || commonFacade.currentLanguage();
    const requestConfig = {
      ...request,
      headers: {
        ...request.headers,
        ...(localStorage.getItem("loginName") && {
          login: localStorage.getItem("loginName"),
        }),
        ...(localStorage.getItem("token") && {
          sessionToken: localStorage.getItem("token"),
        }),
        ["X-SOURCE"]: "HD_WEB",
        ["X-LANGUAGE"]: lang?.toUpperCase(),
      },
      data: {
        ...request.data,
        context: {
          requestId: uuid(),
        },
      },
      cancelToken: this.source.token,
    };

    return requestConfig;
  }
}

export default new CmsHttpService();
