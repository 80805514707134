/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CmsLanguage } from "@/core/dataTypes/types/cmsService.interface";
import { HeaderLanguage } from "@/core/dataTypes/types/common.interface";

export const transformCmsLanguages = (
  data: CmsLanguage[]
): HeaderLanguage[] => {
  return data.map((lang: CmsLanguage) => {
    return {
      flag: lang.code,
      country: lang.englishName,
    } as HeaderLanguage;
  });
};
