




















import { computed, defineComponent } from "@vue/composition-api";
import { userFacade } from "@/store/modules/user/user.facade";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "RegistrationSent",
  setup() {
    document.title = i18n.tc("REGISTRATION_SEND_PAGE.PAGE_TITLE");
    const user = computed(userFacade.user);
    return {
      user,
    };
  },
});
