






















































































import { defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  name: "CardSelector",
  props: {
    name: String,
    customClass: String,
    value: [Object, String, Number],
    customStyle: [Object, String],
    items: Array,
    itemValue: String,
    primitiveType: {
      type: Boolean,
      default: true,
    },
    itemText: String,
    itemDescription: String,
    labelSlot: {
      type: Boolean,
      default: false,
    },
    // Amikor billentyűzettel léptetünk, akkor emittáljuk-e az input eventet.
    emitOnKeyboard: {
      type: Boolean,
      default: true,
    },
    scrollOnFocus: {
      type: Boolean,
      default: false,
    },
    groupLabel: String,
  },
  setup(props, { emit, refs }) {
    const innerValue = ref(props.value);
    watch(
      () => props.value,
      (newVal, oldVal) => {
        if (oldVal !== newVal) {
          innerValue.value = newVal;
        }
      }
    );

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const onChange = (item: any) => {
      emit("input", item);
    };

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const onFocus = () => {
      if (props.scrollOnFocus) {
        const cardSelectorBody = refs.cardSelectorBody as any;
        cardSelectorBody.childNodes[0].scrollIntoView();
      }
      //if (props.emitOnKeyboard) {
      //  emit("input", val.target.id);
      //}
    };

    const onFocusOut = () => {
      if (!props.emitOnKeyboard) {
        innerValue.value = null;
      }
    };

    return {
      innerValue,
      onChange,
      onFocus,
      onFocusOut,
    };
  },
});
