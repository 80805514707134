import axios, { AxiosError } from "axios";
import { httpErrorMessages } from "./httpErrorMessages";
import SnackService from "@/core/services/snackbar.service";
import { alertFacade } from "@/store/modules/alert/alert.facade";
import { EAlertType } from "../dataTypes/enum";
import { userFacade } from "@/store/modules/user/user.facade";
import httpService from "../services/http.service";

export const httpErrorHandler = (error: AxiosError): void => {
  try {
    if (axios.isCancel(error)) return;
    const response = error.response;
    if (!response) return;
    const faultType = response?.data?.faultType;

    switch (true) {
      case response.status === 400 ||
        (response.status === 410 &&
          response.data.faultType !== "INVALID_COUNTRY_CODE"):
        if (faultType === "NOT_AUTHORIZED") {
          userFacade.logout();
        }
        alertFacade.setAlert({
          message: httpErrorMessages(faultType),
          type: EAlertType.ERROR,
        });
        break;

      case response.status === 410 &&
        response.data.faultType === "INVALID_COUNTRY_CODE":
        httpService.setCountryCodeError();
        break;

      case response.status === 401:
        SnackService.open({
          text: httpErrorMessages(faultType),
        });
        userFacade.logout();
        break;

      case response.status === 502 || response.status === 504: // Bad Gateway, Gateway Timeout
        SnackService.open({
          text: httpErrorMessages("OPERATION_FAILED"),
        });
        break;

      default:
        SnackService.open({
          text: httpErrorMessages(faultType),
        });
    }

    /*if (response.status === 400) {
      if (faultType === "NOT_AUTHORIZED") {
        userFacade.logout();
      }
      alertFacade.setAlert({
        message: httpErrorMessages(faultType),
        type: EAlertType.ERROR,
      });
    } else if (response.status === 401) {
      SnackService.open({
        text: httpErrorMessages(faultType),
      });
      userFacade.logout();
    } else {
      SnackService.open({
        text: httpErrorMessages(faultType),
      });
    }*/
  } catch (error) {
    SnackService.open({
      text: "Ismeretlen hiba.",
    });
  }
};
