import { AddressType } from "@/core/dataTypes/types";
import { format } from "date-fns";
import { i18n } from "@/locales/i18n";

export const formatDate = (value: string, param: string): string =>
  format(new Date(value as string), param);
export const toFullAddress = (address: Partial<AddressType>): string => {
  let addressText = "";
  addressText = addressText.concat(
    address.zipCode ? address.zipCode.toString() + " " : "",
    address.city ? address.city + ", " : "",
    address.street ? address.street + " " : "",
    address.streetType ? address.streetType + " " : "",
    address.streetNumber ? address.streetNumber + " " : "",
    address.floor ? address.floor + " " : "",
    address.door ? address.door.toString() : ""
  );
  return addressText;
};
export const formatCurrency = (number: number): string =>
  new Intl.NumberFormat("hu-HU").format(number);

export const toLanguageText = (language: string): string => {
  const languages = [
    { value: "HU", title: i18n.tc("PROFILE_DETAIL_PAGE.LANG_HU") },
    { value: "EN", title: i18n.tc("PROFILE_DETAIL_PAGE.LANG_EN") },
    { value: "DE", title: i18n.tc("PROFILE_DETAIL_PAGE.LANG_DE") },
  ];
  return languages.find((lang) => lang.value === language)?.title as string;
};
