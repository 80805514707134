



















































































import { computed, defineComponent, reactive } from "@vue/composition-api";
import TextInput from "@/components/forms/input/TextInput.vue";
import { ERoutes } from "@/core/dataTypes/enum";
import { userFacade } from "@/store/modules/user/user.facade";
import { alertFacade } from "@/store/modules/alert/alert.facade";
import { ChangeEmailTokenRequestType } from "@/core/dataTypes/types";
import { cryptoSHA1 } from "@/core/utils/crypto/cryptoHelpers";
import { getRecaptchaToken } from "@/core/utils/captcha";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "ChangeEmailPage",
  components: {
    TextInput,
  },
  setup() {
    document.title = i18n.tc("CHANGE_EMAIL_PAGE.PAGE_TITLE");
    const changeEmailLoading = computed(userFacade.changeEmailLoading);

    const alert = computed(alertFacade.alert);
    const formValues = reactive({
      email: "",
      password: "",
    });

    const changeProfileDetails = () => {
      getRecaptchaToken("changeEmail").then(
        (recaptchaResponseToken: string) => {
          const requestData: ChangeEmailTokenRequestType = {
            originalEmail: "", //ezt majd az auth.service-ben töltjük ki
            newEmail: formValues.email,
            password: cryptoSHA1(formValues.password),
            recaptchaResponseToken,
          };
          userFacade.changeEmail(requestData);
        }
      );
    };

    return {
      formValues,
      changeProfileDetails,
      alert,
      changeEmailLoading,
      ERoutes,
    };
  },
});
