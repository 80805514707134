

























































import { computed, defineComponent } from "@vue/composition-api";
import { commonFacade } from "@/store/modules/common/common.facade";
import { EMenuTypes } from "@/core/dataTypes/enum/menuTypes.enum";
import { ERoutes } from "@/core/dataTypes/enum";
import DropDownMenu from "@/components/headers/DropDownMenu/DropDownMenu.vue";
import { getLanguageStaticRoute } from "@/core/utils/router.util";

export default defineComponent({
  name: "DesktopMenu",
  components: {
    DropDownMenu,
  },
  setup() {
    const menu = computed(commonFacade.menuItems);
    const menuLoading = computed(commonFacade.menuItemsLoading);
    const currentLanguage = computed(commonFacade.currentLanguage);

    return {
      menu,
      menuLoading,
      EMenuTypes,
      ERoutes,
      currentLanguage,
      getLanguageStaticRoute,
    };
  },
});
