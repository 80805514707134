import store from "@/store";
import { KeyValue } from "@/core/dataTypes/types/cmsService.interface";

const keyvalues = (): KeyValue[] | undefined => store.state.keyvalue?.data;
const keyvaluesLoading = (): boolean | undefined =>
  store.state.keyvalue?.dataLoading;
const getKeyValue = (): Promise<void> => store.dispatch("keyvalue/getData");

const getInformationPanelId = (): KeyValue | undefined =>
  store.state.keyvalue?.data
    .filter((item) => item.key === "informationPanelCategoryId")
    .pop();

const getMainPageId = (): KeyValue | undefined =>
  store.state.keyvalue?.data
    .filter((item) => item.key === "ematricaMainPage")
    .pop();

const getCardIconsMediaCollectionId = (): KeyValue | undefined =>
  store.state.keyvalue?.data.filter((item) => item.key === "CardIcons").pop();

const getDashboardContentId = (): KeyValue | undefined =>
  store.state.keyvalue?.data
    .filter((item) => item.key === "ematricaMainPage")
    .pop();

const getGoogleAnalyticsKey = (): KeyValue | undefined =>
  store.state.keyvalue?.data.filter((item) => item.key === "GTMKey").pop();

const getAszf = (lang: string): KeyValue | undefined =>
  store.state.keyvalue?.data
    .filter((item) => item.key === `aszf-${lang}`)
    .pop();

const getContactCategory = (lang: string): KeyValue | undefined =>
  store.state.keyvalue?.data
    .filter((item) => item.key === `ContactCategory-${lang}`)
    .pop();

const getDataProtection = (lang: string): KeyValue | undefined =>
  store.state.keyvalue?.data
    .filter((item) => item.key === `adatkezeles-${lang}`)
    .pop();

const getCookieId = (): KeyValue | undefined =>
  store.state.keyvalue?.data.filter((item) => item.key === "cookie").pop();
const resetModule = (): Promise<void> => store.dispatch("keyvalue/resetModule");

export const keyvalueFacade = {
  keyvalues,
  keyvaluesLoading,
  getKeyValue,
  resetModule,
  getCookieId,
  getMainPageId,
  getCardIconsMediaCollectionId,
  getDashboardContentId,
  getGoogleAnalyticsKey,
  getAszf,
  getContactCategory,
  getDataProtection,
  getInformationPanelId,
};
