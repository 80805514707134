/* Vee Validate */
import Vue from "vue";
import * as rules from "vee-validate/dist/rules";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from "vee-validate";

import {
  checkBox,
  hungarienTaxNumberWithSample,
  lowerUpperCaseCharacter,
  numberCharacter,
  customRequired,
  specialCharacter,
  taxGroupId,
  customRegexWithSample,
  minCharacter,
  emailWithAccent,
  scriptTagCharacter,
  extendedPassword,
  phoneNumberCharacter,
} from "@/core/utils/validation/customValidations";

import { i18n } from "@/locales/i18n";

localize({
  en: {
    messages: {
      required: (name) => i18n.tc("VALIDATION.REQUIRED", 0, { name }),
      customRequired: (name) =>
        i18n.tc("VALIDATION.CUSTOM_REQUIRED", 0, { name }),
      email: (name) => i18n.tc("VALIDATION.EMAIL", 0, { name }),
      confirmed: i18n.tc("VALIDATION.CONFIRMED"),
      emailWithAccent: (name) =>
        i18n.tc("VALIDATION.EMAIL_WITH_ACCENT", 0, { name }),
      min: (name, { length }) => i18n.tc("VALIDATION.MIN", 0, { name, length }),
      max: (name, { length }) => i18n.tc("VALIDATION.MAX", 0, { name, length }),
      regex: (name) => i18n.tc("VALIDATION.REGEX", 0, { name, length }),
      customRegexWithSample: (name, { sample }) =>
        i18n.tc("VALIDATION.CUSTOM_REXEXP_WITH_SAMPLE", 0, { name, sample }),
      hungarienTaxNumberWithSample: (name, { sample }) =>
        i18n.tc("VALIDATION.HUNGARIAN_TAXNUMBER_WITH_SAMPLE", 0, {
          name,
          sample,
        }),
      specialCharacter: i18n.tc("VALIDATION.SPECIAL_CHARACTER"),
      scriptTagCharacter: (name) =>
        i18n.tc("VALIDATION.SCRIPT_TAG_CHARACTER", 0, { name }),
      numberCharacter: i18n.tc("VALIDATION.NUMBER_CHARACTER"),
      lowerUpperCaseCharacter: i18n.tc("VALIDATION.LOWER_UPPER_CASE_CHARACTER"),
      minCharacter: i18n.tc("VALIDATION.MIN_CHARACTER"),
      phoneNumber: (name) => i18n.tc("VALIDATION.PHONE_NUMBER", 0, { name }),
      taxGroupId: (name) => i18n.tc("VALIDATION.TAX_GROUP_ID", 0, { name }),
    },
  },
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Object.keys(rules).forEach((rule: string): void => {
  extend(rule, rules[rule as never]);
});

/**
 Custom Validations
 **/
extend("scriptTagCharacter", scriptTagCharacter);
extend("specialCharacter", specialCharacter);
extend("numberCharacter", numberCharacter);
extend("lowerUpperCase", lowerUpperCaseCharacter);
extend("customRequired", { validate: customRequired, computesRequired: true });
extend("checkBox", checkBox);
extend("minCharacterValidation", minCharacter);
extend("phoneNumber", phoneNumberCharacter);
extend("extendedPassword", (value) => extendedPassword(value));

extend("hungarienTaxNumberWithSample", {
  validate: hungarienTaxNumberWithSample,
  params: ["pattern", "sample"],
});

extend("taxGroupId", taxGroupId);

extend("customRegexWithSample", {
  validate: customRegexWithSample,
  params: ["pattern", "sample"],
});

extend("emailWithAccent", emailWithAccent);
