import {
  LoginRequest,
  SessionData,
  User,
  ForgottenPasswordRequest,
  NewForgottenPasswordRequest,
  ChangeProfileDetails,
  ChangeEmailTokenRequestType,
  ChangeEmailActivationTokenRequest,
  AddressType,
  ValidResponseType,
  CreateAndModifyBillingAddressFormState,
  CreateBillingAddressRequestType,
  PasswordChangeRequest,
  ChangeEmailRequest,
  CampaignMessage,
} from "@/core/dataTypes/types";
import store from "@/store";

const user = (): Partial<User> | undefined => store.state.user?.user;
const userSession = (): SessionData | undefined => store.state.user?.session;

const loginLoading = (): boolean | undefined => store.state.user?.loginLoading;
const login = (payload: {
  requestData: LoginRequest;
  firstLogin: boolean;
}): Promise<void> => store.dispatch("user/login", payload);

const logoutLoading = (): boolean | undefined =>
  store.state.user?.logoutLoading;
const logout = (): Promise<void> => store.dispatch("user/logout");

const forgottenPassword = (payload: ForgottenPasswordRequest): Promise<void> =>
  store.dispatch("user/forgottenPassword", payload);
const forgottenPasswordLoading = (): boolean | undefined =>
  store.state.user?.forgottenPasswordLoading;

const forgottenPasswordTokenLoading = (): boolean | undefined =>
  store.state.user?.forgottenPasswordTokenLoading;
const putForgottenPasswordLoading = (): boolean | undefined =>
  store.state.user?.putForgottenPasswordLoading;
const forgottenPasswordActive = (): boolean | undefined =>
  store.state.user?.forgottenPasswordActive;
const forgottenPasswordToken = (payload: string): Promise<void> =>
  store.dispatch("user/forgottenPasswordToken", payload);
const registrationToken = (payload: string): Promise<void> =>
  store.dispatch("user/activateCustomer", payload);
const changeForgottenPassword = (
  payload: NewForgottenPasswordRequest
): Promise<void> => store.dispatch("user/changeForgottenPassword", payload);
const forgottenPasswordEmailAddress = (): string | undefined =>
  store.state.user?.forgottenPasswordEmailAddress;

const changePassword = (payload: PasswordChangeRequest): Promise<void> =>
  store.dispatch("user/changePassword", payload);
const changePasswordLoading = (): boolean | undefined =>
  store.state.user?.changePasswordLoading;

const changeEmailToken = (
  payload: ChangeEmailActivationTokenRequest
): Promise<void> =>
  store.dispatch("user/changeEmailActivationTokenRequest", payload);

const putChangeEmailActive = (password: ChangeEmailRequest): Promise<void> =>
  store.dispatch("user/putChangeEmailActive", password);

const changeEmail = (payload: ChangeEmailTokenRequestType): Promise<void> =>
  store.dispatch("user/changeEmail", payload);

const changeEmailLoading = (): boolean | undefined =>
  store.state.user?.changeEmailLoading;

const changeEmailActivationLoading = (): boolean | undefined =>
  store.state.user?.changeEmailActivationLoading;

const newEmailAddress = (): string | undefined =>
  store.state.user?.newEmailAddress;

const refreshLoading = (): boolean | undefined =>
  store.state.user?.refreshLoading;
const refresh = (): Promise<void> => store.dispatch("user/refresh");

const changeProfileDetailsLoading = (): boolean | undefined =>
  store.state.user?.changeProfileDetailsLoading;
const changeProfileDetails = (payload: ChangeProfileDetails): Promise<void> =>
  store.dispatch("user/changeProfileDetails", payload);

const getCustomer = (): Promise<Partial<User>> =>
  store.dispatch("user/getCustomer");
const defaultBillingAddress = (): Partial<AddressType> | undefined =>
  store.state.user?.defaultBillingAddress;
const maxBillingAddressSize = (): number | undefined =>
  store.state.user?.maxBillingAddressSize;
const billingAddressList = (): Partial<AddressType>[] | undefined =>
  store.state.user?.billingAddressList;
const billingAddress = (): CreateBillingAddressRequestType | undefined =>
  store.state.user?.billingAddress;
const deleteCustomer = (): Promise<void> =>
  store.dispatch("user/deleteCustomer");
const getBillingAddress = (addressId: string): Promise<void> =>
  store.dispatch("user/getBillingAddress", addressId);
const deleteCustomerLoading = (): boolean | undefined =>
  store.state.user?.deleteCustomerLoading;
const deleteBillingAddress = (payload: string): Promise<void> =>
  store.dispatch("user/deleteBillingAddress", payload);
const deleteBillingAddressLoading = (): boolean | undefined =>
  store.state.user?.deleteBillingAddressLoading;

const validZIPCode = (): ValidResponseType | undefined =>
  store.state.user?.validZIPCode;
const validZIPCodeLoading = (): boolean | undefined =>
  store.state.user?.validZIPCodeLoading;
const getValidZIPCode = (countryId: string): Promise<void> =>
  store.dispatch("user/getValidZIPCode", countryId);

const validVATNumber = (): ValidResponseType | undefined =>
  store.state.user?.validVATNumber;
const validVATNumberLoading = (): boolean | undefined =>
  store.state.user?.validVATNumberLoading;
const getValidVATNumberCode = (countryId: string): Promise<void> =>
  store.dispatch("user/getValidVATNumberCode", countryId);

const createBillingAddressLoading = (): boolean | undefined =>
  store.state.user?.createBillingAddressLoading;
const createBillingAddress = (
  payload: Partial<CreateAndModifyBillingAddressFormState>
): Promise<void> => store.dispatch("user/createBillingAddress", payload);

const modifyBillingAddress = (
  payload: Partial<CreateAndModifyBillingAddressFormState>
): Promise<void> => store.dispatch("user/modifyBillingAddress", payload);
const modifyBillingAddressLoading = (): boolean | undefined =>
  store.state.user?.modifyBillingAddressLoading;

const expiredPasswordChange = (payload: PasswordChangeRequest): Promise<void> =>
  store.dispatch("user/expiredPasswordChange", payload);
const expiredPasswordChangeLoading = (): boolean | undefined =>
  store.state.user?.expiredPasswordLoading;
const setExpiredCustomerNumber = (payload: string | undefined): Promise<void> =>
  store.dispatch("user/setExpiredCustomerNumber", payload);
const expiredCustomerNumber = (): string | undefined =>
  store.state.user?.expiredCustomerNumber;

const campaignMessages = (): Promise<void> =>
  store.dispatch("user/campaignMessages");
const removeFirstCampaignMessageFromList = (): Promise<void> =>
  store.dispatch("user/removeFirstCampaignMessageFromList");
const campaignMessageList = (): CampaignMessage[] | undefined =>
  store.state.user?.campaignMessageList;
const campaignMessageListLoading = (): boolean | undefined =>
  store.state.user?.campaignMessageListLoading;
const campaignListHasCheckItem = (): boolean =>
  store.getters("campaignListHasCheckItem");

const acceptCampaignMessage = (campaignMessageId: string): Promise<void> =>
  store.dispatch("user/acceptCampaignMessageRequest", campaignMessageId);
const acceptCampaignMessageLoading = (): boolean | undefined =>
  store.state.user?.acceptCampaignMessageLoading;

const setNotLoggedInUser = (payload: boolean): Promise<void> =>
  store.dispatch("user/setNotLoggedInUser", payload);

const resetLogout = (): Promise<void> => store.dispatch("user/resetLogout");

const notLoggedInUser = (): boolean | undefined =>
  store.state.user?.notLoggedInUser;
export const userFacade = {
  user,
  userSession,
  loginLoading,
  login,
  logoutLoading,
  logout,
  refreshLoading,
  refresh,
  changePassword,
  changePasswordLoading,
  forgottenPassword,
  forgottenPasswordLoading,
  forgottenPasswordToken,
  forgottenPasswordActive,
  putForgottenPasswordLoading,
  changeForgottenPassword,
  forgottenPasswordEmailAddress,
  forgottenPasswordTokenLoading,
  changeProfileDetails,
  changeProfileDetailsLoading,
  getCustomer,
  defaultBillingAddress,
  billingAddressList,
  registrationToken,
  changeEmail,
  changeEmailLoading,
  changeEmailToken,
  changeEmailActivationLoading,
  newEmailAddress,
  deleteCustomer,
  deleteCustomerLoading,
  putChangeEmailActive,
  deleteBillingAddress,
  deleteBillingAddressLoading,
  validZIPCode,
  validZIPCodeLoading,
  getValidZIPCode,
  validVATNumber,
  validVATNumberLoading,
  getValidVATNumberCode,
  createBillingAddressLoading,
  createBillingAddress,
  billingAddress,
  modifyBillingAddress,
  modifyBillingAddressLoading,
  getBillingAddress,
  expiredPasswordChange,
  expiredPasswordChangeLoading,
  campaignMessages,
  campaignMessageList,
  campaignMessageListLoading,
  campaignListHasCheckItem,
  removeFirstCampaignMessageFromList,
  acceptCampaignMessage,
  acceptCampaignMessageLoading,
  maxBillingAddressSize,
  setNotLoggedInUser,
  notLoggedInUser,
  resetLogout,
  setExpiredCustomerNumber,
  expiredCustomerNumber,
};
