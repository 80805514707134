


















































import { computed, defineComponent, ref } from "@vue/composition-api";
import CheckboxInput from "@/components/forms/checkbox/CheckboxInput.vue";
import {
  ECampaignMessageCategory,
  ECampaignMessageType,
} from "@/core/dataTypes/enum";
import { userFacade } from "@/store/modules/user/user.facade";

export default defineComponent({
  name: "CampaignMessageComponent",
  components: {
    CheckboxInput,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isCheckType = computed(
      () => props.campaign.messageType === ECampaignMessageType.CHECK
    );
    const isWarningCategory = computed(
      () => props.campaign.messageCategory === ECampaignMessageCategory.WARNING
    );
    const acceptCampaignMessageLoading = computed(
      userFacade.acceptCampaignMessageLoading
    );
    const campaignMessageText = computed(() =>
      props.campaign?.messageText.replace(/(\r\n|\r|\n)/g, "<br>")
    );
    const acceptCampaignCheckbox = ref<boolean>(false);

    const acceptCampaign = () => {
      userFacade
        .acceptCampaignMessage(props.campaign.campaignMessageId)
        .then(() => {
          userFacade.removeFirstCampaignMessageFromList();
          if (isCheckType.value) {
            acceptCampaignCheckbox.value = false;
          }
        });
    };

    return {
      acceptCampaignCheckbox,
      acceptCampaign,
      isCheckType,
      isWarningCategory,
      acceptCampaignMessageLoading,
      campaignMessageText,
    };
  },
});
