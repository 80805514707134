import { i18n } from "@/locales/i18n";

export const languages = [
  {
    title: i18n.tc("HEADER.LANG1"),
    img: require(`@/assets/flags/hu.svg`),
    value: "HU",
  },
  {
    title: i18n.tc("HEADER.LANG2"),
    img: require(`@/assets/flags/en.svg`),
    value: "EN",
  },
  {
    title: i18n.tc("HEADER.LANG3"),
    img: require(`@/assets/flags/de.svg`),
    value: "DE",
  },
];
