import store from "@/store";
import {
  CheckStickerOverlapRequestType,
  County,
  CountyListRequest,
  HdCustomerVehicle,
  StickerPriceRequest,
  StickerPurchasePeriodList,
  StickerPurchasePeriodRequest,
  StickerShop,
  StickerType,
  StickerValidityEndRequest,
  StickerValidityStartRequest,
  ValidityYear,
  AddressType,
  StickerOverlapType,
  CartElementsRequest,
  CartElement,
  EmailNotifyValidityType,
  CreateAndModifyBillingAddressFormState,
  CreateBillingAddressRequestType,
  BillingAddressRequest,
  ActiveCartResponse,
  CartHistoryQueryRequest,
  CartHistoryQueryRow,
  CartAdhocExtraData,
  AdHocValidityCheck,
  CartHighwayStickerQueryResponse,
} from "@/core/dataTypes/types";
import { StartTransactionRequest } from "@/core/dataTypes/types/vpos.interface";

const saveStickerShopCart = (stickerData: StickerShop): Promise<void> =>
  store.dispatch("sticker/saveStickerShop", stickerData);
const hdVehicle = (): Partial<HdCustomerVehicle> | undefined =>
  store.state.sticker?.stickerShop?.hdVehicle;
const getStickerTypeList = (payload: string): Promise<StickerType[]> =>
  store.dispatch("sticker/getStickerTypeList", payload);
const getActiveCart = (): Promise<ActiveCartResponse> =>
  store.dispatch("sticker/getActiveCart");
const getAdHocValidityCheck = (
  payload?: boolean
): Promise<AdHocValidityCheck> =>
  store.dispatch("sticker/getAdHocValidityCheck", payload);
const getCart = (): Promise<void> => store.dispatch("sticker/getCart");
const putFinalizeInvoiceContact = (): Promise<void> =>
  store.dispatch("sticker/putFinalizeInvoiceContact");
const getAdhocExtraData = (): Promise<void> =>
  store.dispatch("sticker/getAdhocExtraData");
const putAdhocExtraData = (payload: CartAdhocExtraData): Promise<void> =>
  store.dispatch("sticker/putAdhocExtraData", payload);
const putAdhocExtraDataLoading = (): boolean | undefined =>
  store.state.sticker?.putAdhocExtraDataLoading;
const cartAdhocExtraDataIsValid = (): boolean | undefined =>
  store.state.sticker?.cartAdhocExtraDataIsValid;
const cartAdhocExtraData = (): CartAdhocExtraData | undefined =>
  store.state.sticker?.cartAdhocExtraData;
const resetAdhocExtraDataIsValid = (): Promise<void> =>
  store.dispatch("sticker/resetAdhocExtraDataIsValid");
const getBillingAddress = (): Promise<void> =>
  store.dispatch("sticker/getBillingAddress");
const getBillingAddressList = (): Promise<void> =>
  store.dispatch("sticker/getBillingAddressList");
const billingAddressListLoading = (): boolean | undefined =>
  store.state.sticker?.billingAddressListLoading;

const billingAddressLoading = (): boolean | undefined =>
  store.state.sticker?.billingAddressLoading;
const validAdHocId = (): boolean | undefined =>
  store.state.sticker?.validAdHocId;
const billingAddressList = ():
  | Partial<AddressType>[]
  | undefined
  | CreateBillingAddressRequestType[] =>
  store.state.sticker?.billingAddressList;
const maxBillingAddressNum = (): number | undefined =>
  store.state.sticker?.maxBillingAddressNum;
const getPreemption = (payload: string): Promise<void> =>
  store.dispatch("sticker/getPreemption", payload);
const resetBillingAddressList = (): Promise<void> =>
  store.dispatch("sticker/resetBillingAddressList");
const resetBillingAddress = (): Promise<void> =>
  store.dispatch("sticker/resetBillingAddress");
const postStickerPurchasePeriod = (
  payload: StickerPurchasePeriodRequest
): Promise<void> =>
  store.dispatch("sticker/postStickerPurchasePeriod", payload);

const postStickerValidityStart = (
  payload: StickerValidityStartRequest
): Promise<void> => store.dispatch("sticker/postStickerValidityStart", payload);

const postStickerValidityEnd = (
  payload: StickerValidityEndRequest
): Promise<void> => store.dispatch("sticker/postStickerValidityEnd", payload);

const postStickerPrice = (payload: StickerPriceRequest): Promise<void> =>
  store.dispatch("sticker/postStickerPrice", payload);

const postCheckStickerOverlap = (
  payload: CheckStickerOverlapRequestType
): Promise<void> => store.dispatch("sticker/postCheckStickerOverlap", payload);

const postCartElements = (payload: CartElementsRequest): Promise<void> =>
  store.dispatch("sticker/postCartElements", payload);

const stickerOverlap = (): StickerOverlapType[] | undefined =>
  store.state.sticker?.stickerOverlap;

const stickerTypeList = (): StickerType[] | undefined =>
  store.state.sticker?.stickerTypeList;

const cartElementList = (): CartElement[] | undefined =>
  store.state.sticker?.cartElementList;
const finalCost = (): number | undefined => store.state.sticker?.cost;

const stickerPurchasePeriodList = (): StickerPurchasePeriodList[] | undefined =>
  store.state.sticker?.stickerPurchasePeriodList;

const stickerValidityStart = (): Date | undefined =>
  store.state.sticker?.stickerValidityStart;
const stickerValidityEnd = (): Date | undefined =>
  store.state.sticker?.stickerValidityEnd;

const preemption = (): boolean | undefined => store.state.sticker?.preemption;
const stickerTypeListLoading = (): boolean | undefined =>
  store.state.sticker?.stickerTypeListLoading;
const checkStickerOverlapLoading = (): boolean | undefined =>
  store.state.sticker?.checkStickerOverlapLoading;
const cartElementsLoading = (): boolean | undefined =>
  store.state.sticker?.cartElementsLoading;
const stickerPrice = (): number | undefined =>
  store.state.sticker?.stickerPrice;

const validityYear = (): ValidityYear[] | undefined =>
  store.state.sticker?.validityYear;

const productId = (): string[] | undefined => store.state.sticker?.productId;

const countyList = (): County[] | undefined => store.state.sticker?.countyList;
const cartElementsCount = (): number | undefined =>
  store.state.sticker?.cartElementsCount;
const cartId = (): string | unknown => store.state.sticker?.cartId;
const transactionId = (): string | unknown =>
  store.state.sticker?.transactionId;

const postCountyList = (payload: CountyListRequest): Promise<void> =>
  store.dispatch("sticker/postCountyList", payload);

const resetStickerChoice = (): Promise<void> =>
  store.dispatch("sticker/resetStickerChoice");
const resetCartPurchasedHistory = (): Promise<void> =>
  store.dispatch("sticker/resetCartPurchasedHistory");
const resetValidationYears = (): Promise<void> =>
  store.dispatch("sticker/resetValidationYears");

const resetCardStatus = (): Promise<void> =>
  store.dispatch("sticker/resetCardStatus");
const resetCart = (): Promise<void> => store.dispatch("sticker/resetCart");
const resetCartId = (): Promise<void> => store.dispatch("sticker/resetCartId");

const resetStickerPrice = (): Promise<void> =>
  store.dispatch("sticker/resetStickerPrice");
const resetHdVehicle = (): Promise<void> =>
  store.dispatch("sticker/resetHdVehicle");

const billingAddress = (): Partial<AddressType> | undefined =>
  store.state.sticker?.billingAddress;

const setActualBillingAddress = (
  payload: CreateBillingAddressRequestType | unknown
): Promise<void> => store.dispatch("sticker/setActualBillingAddress", payload);

const setEmailNotification = (
  payload: EmailNotifyValidityType
): Promise<void> =>
  store.dispatch("sticker/putEmailNotificationSettings", payload);

const validityStartNotification = (): boolean | undefined =>
  store.state.sticker?.validityStartNotification;

const validityEndNotification = (): boolean | undefined =>
  store.state.sticker?.validityEndNotification;

const createBillingAddressLoading = (): boolean | undefined =>
  store.state.sticker?.createBillingAddressLoading;
const modifyBillingAddressLoading = (): boolean | undefined =>
  store.state.sticker?.modifyBillingAddressLoading;

const createBillingAddress = (
  payload: Partial<CreateAndModifyBillingAddressFormState>
): Promise<void> => store.dispatch("sticker/createBillingAddress", payload);

const modifyBillingAddress = (
  payload: Partial<CreateAndModifyBillingAddressFormState>
): Promise<void> => store.dispatch("sticker/modifyBillingAddress", payload);

const deleteCartElement = (payload: string): Promise<void> =>
  store.dispatch("sticker/deleteCartElement", payload);

const startCartTransaction = (
  payload: StartTransactionRequest
): Promise<void> => store.dispatch("sticker/startCartTransaction", payload);

const getTransactions = (): Promise<void> =>
  store.dispatch("sticker/getTransactions");

const transactionsPaymentProgress = (payload: boolean): Promise<void> =>
  store.dispatch("sticker/transactionsPaymentProgress", payload);
const setTransactionsPaymentProgressLongTime = (
  payload: boolean | unknown
): Promise<void> =>
  store.dispatch("sticker/setTransactionsPaymentProgressLongTime", payload);

const transactionsPaymentProgressLongTime = (): boolean | unknown =>
  store.state.sticker?.transactionsPaymentProgressLongTime;
const transactionsPaymentLoading = (): boolean | undefined =>
  store.state.sticker?.transactionsPaymentLoading;

const putBillingAddress = (payload: BillingAddressRequest): Promise<void> =>
  store.dispatch("sticker/putBillingAddress", payload);

const putBillingAddressLoading = (): boolean | undefined =>
  store.state.sticker?.putBillingAddressLoading;

const startCartTransactionLoading = (): boolean | undefined =>
  store.state.sticker?.startCartTransactionLoading;

const getTransactionsLoading = (): boolean | undefined =>
  store.state.sticker?.getTransactionsLoading;

const deleteCartElementLoading = (): boolean | undefined =>
  store.state.sticker?.deleteCartElementLoading;
const postCartHistoryQueryLoading = (): boolean | undefined =>
  store.state.sticker?.postCartHistoryQueryLoading;
const cartLoading = (): boolean | undefined => store.state.sticker?.cartLoading;
const cartStatus = (): string | undefined => store.state.sticker?.cartStatus;
const activeCartLoading = (): boolean | undefined =>
  store.state.sticker?.activeCartLoading;

const postCartHistoryQuery = (
  payload: CartHistoryQueryRequest
): Promise<void> => store.dispatch("sticker/postCartHistoryQuery", payload);

const postHighwayStickerQuery = (
  payload: CartHistoryQueryRequest
): Promise<CartHighwayStickerQueryResponse> =>
  store.dispatch("sticker/postHighwayStickerQuery", payload);

const getAdhocPaymentResult = (): Promise<void> =>
  store.dispatch("sticker/getAdhocPaymentResult");

const getCartPurchaseHistory = (
  payload: CartHistoryQueryRequest
): Promise<CartHistoryQueryRow[]> =>
  store.dispatch("sticker/getCartPurchaseHistory", payload);

const cartPurchaseHistory = (): CartHistoryQueryRow[] | undefined =>
  store.state.sticker?.cartPurchaseHistory;
const cartPurchaseHistoryLoading = (): boolean | undefined =>
  store.state.sticker?.cartPurchaseHistoryLoading;

const adhocPaymentResultLoading = (): boolean | undefined =>
  store.state.sticker?.adhocPaymentResultLoading;

const cartHistoryQueryRow = (): CartHistoryQueryRow[] | undefined =>
  store.state.sticker?.cartHistoryQueryRow;

const billingAddressId = (): string | unknown =>
  store.state.sticker?.billingAddressId;
const adHocValidityCheckLoading = (): boolean | unknown =>
  store.state.sticker?.adHocValidityCheckLoading;

const getInvoice = (invoiceId: {
  invoiceId: string;
  index: number;
}): Promise<void> => store.dispatch("sticker/getInvoice", invoiceId);
// const invoiceDownloadLoading = (
//   payload: CartHistoryItemLoading
// ): Promise<void> => store.dispatch("sticker/invoiceDownloadLoading", payload);
// const documentDownloadLoading = (
//   payload: CartHistoryItemLoading
// ): Promise<void> => store.dispatch("sticker/documentDownloadLoading", payload);
const getDocument = (payload: {
  requestId: string;
  index?: number;
  cartIndex?: number;
}): Promise<void> => store.dispatch("sticker/getDocument", payload);

const getInvoiceLoading = (): boolean | undefined =>
  store.state.sticker?.getInvoiceLoading;
const getDocumentLoading = (): boolean | undefined =>
  store.state.sticker?.getDocumentLoading;

export const stickerFacade = {
  getInvoice,
  getDocument,
  getInvoiceLoading,
  getDocumentLoading,
  saveStickerShopCart,
  resetStickerChoice,
  resetValidationYears,
  resetStickerPrice,
  hdVehicle,
  getStickerTypeList,
  getPreemption,
  stickerTypeList,
  postStickerPurchasePeriod,
  postStickerValidityStart,
  postStickerValidityEnd,
  stickerPurchasePeriodList,
  stickerTypeListLoading,
  stickerValidityStart,
  stickerValidityEnd,
  preemption,
  validityYear,
  countyList,
  postCountyList,
  postStickerPrice,
  stickerPrice,
  resetHdVehicle,
  postCheckStickerOverlap,
  postCartElements,
  productId,
  stickerOverlap,
  setActualBillingAddress,
  checkStickerOverlapLoading,
  cartElementsLoading,
  getActiveCart,
  getCart,
  putFinalizeInvoiceContact,
  getAdhocExtraData,
  getBillingAddress,
  getBillingAddressList,
  billingAddress,
  cartElementList,
  finalCost,
  cartElementsCount,
  validityStartNotification,
  validityEndNotification,
  setEmailNotification,
  createBillingAddressLoading,
  createBillingAddress,
  billingAddressListLoading,
  billingAddressList,
  maxBillingAddressNum,
  deleteCartElement,
  deleteCartElementLoading,
  billingAddressLoading,
  putBillingAddress,
  putBillingAddressLoading,
  cartId,
  resetCartId,
  transactionId,
  resetCart,
  activeCartLoading,
  resetBillingAddressList,
  resetBillingAddress,
  startCartTransaction,
  startCartTransactionLoading,
  getTransactions,
  getTransactionsLoading,
  cartLoading,
  transactionsPaymentProgress,
  transactionsPaymentProgressLongTime,
  setTransactionsPaymentProgressLongTime,
  transactionsPaymentLoading,
  postCartHistoryQuery,
  postHighwayStickerQuery,
  cartHistoryQueryRow,
  cartStatus,
  billingAddressId,
  modifyBillingAddressLoading,
  modifyBillingAddress,
  putAdhocExtraData,
  putAdhocExtraDataLoading,
  cartAdhocExtraData,
  getCartPurchaseHistory,
  cartPurchaseHistory,
  cartPurchaseHistoryLoading,
  adHocValidityCheckLoading,
  getAdHocValidityCheck,
  validAdHocId,
  cartAdhocExtraDataIsValid,
  resetAdhocExtraDataIsValid,
  getAdhocPaymentResult,
  adhocPaymentResultLoading,
  resetCartPurchasedHistory,
  postCartHistoryQueryLoading,
  resetCardStatus,
  // invoiceDownloadLoading,
  // documentDownloadLoading,
};
