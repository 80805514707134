import { ValidResponseType } from "@/core/dataTypes/types";
import { AppState } from "@/store";
import { Module } from "vuex";
import * as PublicServiceApi from "@/core/api/publicService.api";

export interface BillingAddressFormState {
  validZIPCode: ValidResponseType | undefined;
  validVATNumber: ValidResponseType | undefined;
  validZIPCodeLoading: boolean;
  validVATNumberLoading: boolean;
}

export const initialBillingAddressFormState: BillingAddressFormState = {
  validZIPCode: undefined,
  validVATNumber: undefined,
  validZIPCodeLoading: false,
  validVATNumberLoading: false,
};

export const billingAddressFormModule: Module<
  BillingAddressFormState,
  AppState
> = {
  namespaced: true,
  state: initialBillingAddressFormState,
  mutations: {
    validZIPCodeRequest: (state) => {
      state.validZIPCodeLoading = true;
    },
    validZIPCodeSuccess: (state, payload: ValidResponseType) => {
      state.validZIPCode = payload;
      state.validZIPCodeLoading = false;
    },
    validZIPCodeFail: (state) => {
      state.validZIPCodeLoading = false;
    },

    validVATNumberCodeRequest: (state) => {
      state.validVATNumberLoading = true;
    },
    validVATNumberCodeSuccess: (state, payload: ValidResponseType) => {
      state.validVATNumber = payload;
      state.validVATNumberLoading = false;
    },
    validVATNumberCodeFail: (state) => {
      state.validVATNumberLoading = false;
    },
  },
  actions: {
    getValidZIPCode: ({ commit }, countryId) => {
      commit("validZIPCodeRequest");
      PublicServiceApi.getValidZIPCode(countryId)
        .then((response) => {
          commit("validZIPCodeSuccess", response);
        })
        .catch((error) => {
          commit("validZIPCodeFail");
          throw Error(error);
        });
    },
    getValidVATNumberCode: ({ commit }, countryId) => {
      commit("validVATNumberCodeRequest");
      PublicServiceApi.getValidVATNumber(countryId)
        .then((response) => {
          commit("validVATNumberCodeSuccess", response);
        })
        .catch((error) => {
          commit("validVATNumberCodeFail");
          throw Error(error);
        });
    },
  },
};
