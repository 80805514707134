import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import Login from "@/views/LoginPage/LoginPage.vue";
import RegistrationPage from "@/views/RegistrationPage/RegistrationPage.vue";
import StickerPurchasePage from "@/views/StickerPurchasePage/StickerPurchasePage.vue";
import ForgottenPassword from "@/views/ForgottenPasswordPage/ForgottenPasswordPage.vue";
import NewPassword from "@/views/NewPasswordPage/NewPasswordPage.vue";
import ForgottenPasswordEmailSentPage from "@/views/ForgottenPasswordPage/ForgottenPasswordEmailSentPage/ForgottenPasswordEmailSentPage.vue";
import RegistrationSent from "@/views/RegistrationPage/RegistrationSentPage/RegistrationSentPage.vue";
import RegistrationActivationPage from "@/views/RegistrationPage/RegistrationActivationPage/RegistrationActivationPage.vue";
import ChangeEmailActivationPage from "@/views/ProfilePage/ChangeEmailPage/ChangeEmailActivationPage/ChangeEmailActivationPage.vue";
import ChangeEmailActivationEmailSent from "@/views/ProfilePage/ChangeEmailPage/ChangeEmailActivationEmailSent/ChangeEmailActivationEmailSent.vue";
import ChangeEmailActivationEmailFailed from "@/views/ProfilePage/ChangeEmailPage/ChangeEmailActivationEmailFailed/ChangeEmailActivationEmailFailed.vue";
import ChangeEmailTokenValidationPage from "@/views/ProfilePage/ChangeEmailPage/ChangeEmailTokenValidation/ChangeEmailTokenValidationPage.vue";
import ChangePassword from "@/views/ProfilePage/ChangePasswordPage/ChangePasswordPage.vue";
import ChangeProfileDetailsPage from "@/views/ProfilePage/ChangeProfileDetailsPage/ChangeProfileDetailsPage.vue";
import ChangeEmailPage from "@/views/ProfilePage/ChangeEmailPage/ChangeEmailPage.vue";
import { ERoutes } from "@/core/dataTypes/enum";
import { authedGuard } from "@/core/guards/authed.guard";
import { notAuthedGuard } from "@/core/guards/notAuthed.guard";
import ProfilePage from "@/views/ProfilePage/ProfilePage.vue";
import VehiclePage from "@/views/VehiclePage/VehiclePage.vue";
import ExpiredPasswordPage from "@/views/ExpiredPasswordPage/ExpiredPasswordPage.vue";
import CampaignPage from "@/views/CampaignPage/CampaignPage.vue";
import { stickerCreationGuard } from "@/core/guards/sticker.creation.guard";
import { stickerPayGuard } from "@/core/guards/sticker.pay.guard";
import { stickerEmptyGuard } from "@/core/guards/sticker.empty.guard";
import { successPaymentGuard } from "@/core/guards/success.payment.guard";
import { tabFocusService } from "@/core/services/tabFocus.service";
// import MainPage from "@/views/MainPage/MainPage.vue";
import CustomSlug from "@/views/CustomSlug.vue";
import SimpleRouterView from "@/components/simpleRouterView/SimpleRouterView.vue";
import { i18n } from "@/locales/i18n";
import { computed, ComputedRef } from "@vue/composition-api";
import { languageGuard } from "@/core/guards/language.guard";
import AuthService from "@/core/services/auth.service";
import { commonFacade } from "@/store/modules/common/common.facade";
import { accessibilityFacade } from "@/store/modules/accessibility/accessibility.facade";
import { RouteWithSlug } from "@/core/dataTypes/types";
import { memoizedSlugs } from "@/core/utils/router.util";

Vue.use(VueRouter);
const routes: Array<RouteConfig> = [
  {
    path: "",
    redirect: () => {
      const lang =
        commonFacade.currentLanguage() || process.env.VUE_APP_I18N_LOCALE;
      const splitPath = window.location.pathname.split("/");
      const pathLang = splitPath.find((i: string) => i.length === 2);
      if (!pathLang) {
        localStorage.setItem("currLang", lang);
      }
      const path = AuthService.hasValidToken()
        ? ERoutes.DASHBOARD
        : ERoutes.LOGIN;
      return {
        path: `/${lang}/${path}`,
      };
    },
  },
  {
    path: "/:lang",
    component: SimpleRouterView,
    children: [
      /*    {
        path: ERoutes.MAIN_PAGE,
        name: "MainPage",
        component: MainPage,
        beforeEnter: notAuthedGuard,
      },*/
      {
        path: ERoutes.DASHBOARD,
        name: "Dashboard",
        component: () => import("../views/DashboardPage/DashboardPage.vue"),
        beforeEnter: authedGuard,
      },
      {
        path: ERoutes.LOGIN,
        name: "Login",
        component: Login,
        beforeEnter: notAuthedGuard,
      },
      {
        path: ERoutes.EXPIRED_PASSWORD,
        name: "ExpiredPasswordPage",
        component: ExpiredPasswordPage,
        beforeEnter: notAuthedGuard,
      },
      {
        path: ERoutes.REGISTRATION,
        name: "Registration",
        component: RegistrationPage,
        beforeEnter: notAuthedGuard,
      },
      {
        path: ERoutes.FORGOTTEN_PASSWORD,
        name: "ForgottenPassword",
        component: ForgottenPassword,
        beforeEnter: notAuthedGuard,
      },
      {
        path: ERoutes.FORGOTTEN_PASSWORD_EMAIL_SENT,
        name: "ForgottenPasswordEmailSentPage",
        component: ForgottenPasswordEmailSentPage,
      },
      {
        path: ERoutes.REGISTRATION_SENT,
        name: "RegistrationSent",
        component: RegistrationSent,
      },
      {
        path: ERoutes.REGISTRATION_ACTIVATION,
        name: "RegistrationActivation",
        component: RegistrationActivationPage,
      },
      {
        path: ERoutes.NEW_PASSWORD,
        name: "NewPassword",
        component: NewPassword,
        // beforeEnter: notAuthedGuard,
      },
      {
        path: ERoutes.CHANGE_PASSWORD,
        name: "ChangePassword",
        component: ChangePassword,
        beforeEnter: authedGuard,
      },
      {
        path: ERoutes.CHANGE_PROFILE_DETAILS,
        name: "ChangeProfileDetailsPage",
        component: ChangeProfileDetailsPage,
        beforeEnter: authedGuard,
      },
      {
        path: ERoutes.CHANGE_EMAIL,
        name: "ChangeEmailPage",
        component: ChangeEmailPage,
        beforeEnter: authedGuard,
      },
      {
        path: ERoutes.CHANGE_EMAIL_ACTIVATION_EMAIL_SENT,
        name: "ChangeEmailActivationEmailSent",
        component: ChangeEmailActivationEmailSent,
        beforeEnter: authedGuard,
      },
      {
        path: ERoutes.CHANGE_EMAIL_TOKEN_VALIDATION,
        name: "ChangeEmailTokenValidationPage",
        component: ChangeEmailTokenValidationPage,
      },
      {
        path: ERoutes.CHANGE_EMAIL_ACTIVATION,
        name: "ChangeEmailActivationPage",
        component: ChangeEmailActivationPage,
      },
      {
        path: ERoutes.CHANGE_EMAIL_ACTIVATION_EMAIL_FAILED,
        name: "ChangeEmailActivationEmailFailed",
        component: ChangeEmailActivationEmailFailed,
      },
      {
        path: ERoutes.CAMPAIGN,
        name: "CampaignPage",
        component: CampaignPage,
        beforeEnter: authedGuard,
      },

      {
        path: ERoutes.STICKER_VALID,
        name: "ValidStickersPage",
        component: () =>
          import("../views/ValidStickersPage/ValidStickersPage.vue"),
        beforeEnter: authedGuard,
      },
      {
        path: ERoutes.PROFILE,
        component: ProfilePage,
        beforeEnter: authedGuard,
        children: [
          {
            path: "",
            name: "ProfileDetails",
            component: () =>
              import(
                "@/views/ProfilePage/ProfileDetailsPage/ProfileDetailsPage.vue"
              ),
          },
          {
            path: ERoutes.ADD_NEW_BILLING_ADDRESS,
            name: "NewBillingAddress",
            component: () =>
              import(
                "@/views/ProfilePage/NewBillingAddressPage/NewBillingAddressPage.vue"
              ),
          },
          {
            path: `${ERoutes.MODIFY_BILLING_ADDRESS}/:addressId`,
            name: "ModifyBillingAddress",
            component: () =>
              import(
                "@/views/ProfilePage/ModifyBillingAddressPage/ModifyBillingAddressPage.vue"
              ),
          },
        ],
      },
      {
        path: ERoutes.VEHICLE,
        component: VehiclePage,
        beforeEnter: authedGuard,
        children: [
          {
            path: "",
            name: "VehicleListPage",
            component: () =>
              import("@/views/VehiclePage/VehicleListPage/VehicleListPage.vue"),
          },
          {
            path: ERoutes.VEHICLE_NEW,
            name: "VehicleAddPage",
            component: () =>
              import("@/views/VehiclePage/VehicleNewPage/VehicleNewPage.vue"),
          },
        ],
      },
      {
        // path: i18n.tc("ROUTER.STICKER_PURCHASE"),
        path: ERoutes.STICKER_PURCHASE,
        name: "StickerPurchasePage",
        component: StickerPurchasePage,
        beforeEnter: authedGuard,
        children: [
          {
            // path: i18n.tc("ROUTER.STICKER_CREATION"),
            path: ERoutes.STICKER_CREATION,
            name: "StickerCreation",
            beforeEnter: stickerCreationGuard,
            meta: {
              notRequiresAuth: true,
            },
            component: () =>
              import(
                "@/views/StickerPurchasePage/components/StickerCreation/StickerCreation.vue"
              ),
          },
          {
            // path: i18n.tc("ROUTER.STICKER_PAY"),
            path: ERoutes.STICKER_PAY,
            name: "StickerPay",
            beforeEnter: stickerPayGuard,
            meta: {
              notRequiresAuth: true,
            },
            component: () =>
              import(
                "@/views/StickerPurchasePage/components/StickerPay/StickerPay.vue"
              ),
          },
          {
            path: "",
            redirect: { name: "StickerCreation" },
          },
        ],
      },
      {
        path: ERoutes.EMPTY_CART,
        name: "EmptyCartPage",
        beforeEnter: stickerEmptyGuard,
        component: () =>
          import(
            "../views/StickerPurchasePage/EmptyCartPage/EmptyCartPage.vue"
          ),
      },
      {
        path: ERoutes.STICKER_SUCCESS,
        name: "StickerSuccessPage",
        beforeEnter: successPaymentGuard,
        component: () =>
          import(
            "../views/StickerPurchasePage/StickerSuccessPage/StickerSuccessPage.vue"
          ),
      },
      {
        path: ERoutes.MY_PURCHASES,
        name: "MyPurchasesPage",
        beforeEnter: authedGuard,
        component: () => import("../views/MyPurchasesPage/MyPurchasesPage.vue"),
      },
      {
        path: ":slug1",
        component: SimpleRouterView,
        // beforeEnter: notAuthedGuard,
        children: [
          {
            path: "",
            // beforeEnter: notAuthedGuard,
            component: CustomSlug,
          },
          {
            path: ":slug2",
            component: SimpleRouterView,
            // beforeEnter: notAuthedGuard,
            children: [
              {
                path: "",
                // beforeEnter: notAuthedGuard,
                component: CustomSlug,
              },
              {
                path: ":slug3",
                // beforeEnter: notAuthedGuard,
                component: SimpleRouterView,
                children: [
                  {
                    path: "",
                    // beforeEnter: notAuthedGuard,
                    component: CustomSlug,
                  },
                  {
                    path: "*",
                    // beforeEnter: notAuthedGuard,
                    redirect: i18n.locale || process.env.VUE_APP_I18N_LOCALE,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "",
        redirect: () => {
          const name = AuthService.hasValidToken() ? "Dashboard" : "Login";
          return {
            name: name,
          };
        },
      },
      {
        path: "*",
        redirect: i18n.locale || process.env.VUE_APP_I18N_LOCALE,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "routerLinkActive",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(languageGuard);

router.afterEach((to: Route) => {
  routeData.route = { ...to, slug: cachedSlugs(to.path) };
  // routeData.route = to;
  tabFocusService.setFocus("nuszBack");
  accessibilityFacade.setTopDialogLevel(-1);
  accessibilityFacade.setDialogOpen(false);
});

// const routeData = Vue.observable<{ route: Route }>({ route: {} as Route });
const routeData = Vue.observable<{
  route: RouteWithSlug;
}>({ route: {} as RouteWithSlug });

const cachedSlugs = memoizedSlugs();
/*export const useRoute = (): ComputedRef<Route> => {
  return computed(() => routeData.route);
};*/

export const useRoute = (): ComputedRef<RouteWithSlug> => {
  return computed(() => routeData.route);
};

export default router;
