var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var fields = ref.fields;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('ValidationProvider',[_c('legend',{staticClass:"d-none"},[_vm._v(" "+_vm._s(_vm.$t("USER_BILLING_DETAILS.BILLING_TYPE_LEGEND"))+" ")]),_c('BillingTypeSelector',{staticClass:"pb-8",attrs:{"id":(_vm.pageId + "--corporation")},on:{"input":_vm.billingTypeChanged},model:{value:(_vm.formValues.corporation),callback:function ($$v) {_vm.$set(_vm.formValues, "corporation", $$v)},expression:"formValues.corporation"}})],1),_c('div',{staticClass:"pb-2 body-2 blue--text text--darken-4"},[_vm._v(" "+_vm._s(_vm.$t("USER_BILLING_DETAILS.MESSAGE1"))+" ")]),_c('ValidationProvider',{attrs:{"rules":"required|max:150","name":_vm.$t('USER_BILLING_DETAILS.FIELDS.BILLING_NAME')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"id":(_vm.pageId + "--billing-name"),"error-messages":errors,"label":_vm.$t('USER_BILLING_DETAILS.FIELDS.BILLING_NAME'),"aria-label":_vm.$t('USER_BILLING_DETAILS.FIELDS.BILLING_NAME'),"aria-required":true,"required":""},model:{value:(_vm.formValues.billingName),callback:function ($$v) {_vm.$set(_vm.formValues, "billingName", $$v)},expression:"formValues.billingName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('USER_BILLING_DETAILS.FIELDS.COUNTRY')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AutocompleteInput',{attrs:{"id":(_vm.pageId + "--country"),"label":_vm.$t('USER_BILLING_DETAILS.FIELDS.COUNTRY'),"aria-label":_vm.$t('USER_BILLING_DETAILS.FIELDS.COUNTRY'),"aria-required":true,"name":"orszag","items":_vm.countries,"itemText":"name","itemValue":"id","clearable":true,"autocomplete":"orszag","autoSelectFirst":true,"disabled":_vm.countriesLoading,"loading":_vm.countriesLoading,"error-messages":errors,"separate-lists-number":7,"hideNoData":"","required":"","custom-list-item":""},on:{"change":_vm.countrySelectionChanged},model:{value:(_vm.formValues.country),callback:function ($$v) {_vm.$set(_vm.formValues, "country", $$v)},expression:"formValues.country"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|max:50","name":_vm.$t('USER_BILLING_DETAILS.FIELDS.CITY')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"id":(_vm.pageId + "--city"),"error-messages":errors,"required":true,"label":_vm.$t('USER_BILLING_DETAILS.FIELDS.CITY'),"aria-label":_vm.$t('USER_BILLING_DETAILS.FIELDS.CITY'),"aria-required":true},model:{value:(_vm.formValues.city),callback:function ($$v) {_vm.$set(_vm.formValues, "city", $$v)},expression:"formValues.city"}})]}}],null,true)}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":{
            required: true,
            min: 2,
            max: 15,
            customRegexWithSample:
              _vm.validZIPCode && _vm.validZIPCode.jrgxPattern
                ? {
                    pattern: new RegExp(_vm.validZIPCode.jrgxPattern),
                    sample: _vm.validZIPCode.sample,
                  }
                : {
                    pattern: _vm.validationRegExp.defaultZIPCode,
                    sample: '',
                  },
          },"name":_vm.$t('USER_BILLING_DETAILS.FIELDS.ZIP_CODE')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('TextInput',{attrs:{"id":(_vm.pageId + "--zipCode"),"error-messages":errors,"disabled":!_vm.formValues.country || _vm.validZIPCodeLoading,"required":true,"label":_vm.$t('USER_BILLING_DETAILS.FIELDS.ZIP_CODE'),"aria-label":_vm.$t('USER_BILLING_DETAILS.FIELDS.ZIP_CODE'),"aria-required":true,"only-upper-case":""},model:{value:(_vm.formValues.zipCode),callback:function ($$v) {_vm.$set(_vm.formValues, "zipCode", $$v)},expression:"formValues.zipCode"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|max:150","name":_vm.$t('USER_BILLING_DETAILS.FIELDS.STREET')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('TextInput',{attrs:{"id":(_vm.pageId + "--street"),"error-messages":errors,"required":true,"label":_vm.$t('USER_BILLING_DETAILS.FIELDS.STREET'),"aria-label":_vm.$t('USER_BILLING_DETAILS.FIELDS.STREET'),"aria-required":true},model:{value:(_vm.formValues.street),callback:function ($$v) {_vm.$set(_vm.formValues, "street", $$v)},expression:"formValues.street"}})]}}],null,true)})],1)],1),_c('ValidationProvider',{attrs:{"rules":"required|max:50","name":_vm.$t('USER_BILLING_DETAILS.FIELDS.STREET_TYPE')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('Combobox',{attrs:{"id":(_vm.pageId + "--streetType"),"label":_vm.$t('USER_BILLING_DETAILS.FIELDS.STREET_TYPE'),"aria-label":_vm.$t('USER_BILLING_DETAILS.FIELDS.STREET_TYPE'),"aria-required":true,"items":_vm.publicPlaces,"itemText":"name","itemValue":"name","autoSelectFirst":true,"clearable":true,"disabled":_vm.publicPlacesLoading,"loading":_vm.publicPlacesLoading,"autocomplete":"kozterulet-tipusa","error-messages":errors,"separate-lists-number":7,"custom-list-item":"","required":""},model:{value:(_vm.formValues.streetType),callback:function ($$v) {_vm.$set(_vm.formValues, "streetType", $$v)},expression:"formValues.streetType"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|max:10","name":_vm.$t('USER_BILLING_DETAILS.FIELDS.STREET_NUMBER')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('TextInput',{attrs:{"id":(_vm.pageId + "--streetNumber"),"error-messages":errors,"required":true,"label":_vm.$t('USER_BILLING_DETAILS.FIELDS.STREET_NUMBER'),"aria-label":_vm.$t('USER_BILLING_DETAILS.FIELDS.STREET_NUMBER'),"aria-required":true},model:{value:(_vm.formValues.streetNumber),callback:function ($$v) {_vm.$set(_vm.formValues, "streetNumber", $$v)},expression:"formValues.streetNumber"}})]}}],null,true)}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('USER_BILLING_DETAILS.FIELDS.FLOOR'),"rules":"max:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('TextInput',{key:"billingDetailsForm--floor",attrs:{"id":(_vm.pageId + "--floor"),"error-messages":errors,"label":_vm.$t('USER_BILLING_DETAILS.FIELDS.FLOOR'),"aria-label":_vm.$t('USER_BILLING_DETAILS.FIELDS.FLOOR')},model:{value:(_vm.formValues.floor),callback:function ($$v) {_vm.$set(_vm.formValues, "floor", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formValues.floor"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('USER_BILLING_DETAILS.FIELDS.DOOR'),"rules":"max:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('TextInput',{key:"billingDetailsForm--door",attrs:{"id":(_vm.pageId + "--door"),"error-messages":errors,"label":_vm.$t('USER_BILLING_DETAILS.FIELDS.DOOR'),"aria-label":_vm.$t('USER_BILLING_DETAILS.FIELDS.DOOR')},model:{value:(_vm.formValues.door),callback:function ($$v) {_vm.$set(_vm.formValues, "door", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formValues.door"}})]}}],null,true)})],1)],1),(_vm.formValues.corporation)?[_c('div',{staticClass:"text-subtitle-1 pt-4 pb-4"},[_vm._v(" "+_vm._s(_vm.$t("USER_BILLING_DETAILS.TAX_NUMBER_TITLE"))+" ")]),_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('USER_BILLING_DETAILS.FIELDS.TAX_NATIONATILY')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('AutocompleteInput',{key:"billingDetailsForm--vatCountry",attrs:{"id":(_vm.pageId + "--vatCountry"),"label":_vm.$t('USER_BILLING_DETAILS.FIELDS.TAX_NATIONATILY'),"aria-label":_vm.$t('USER_BILLING_DETAILS.FIELDS.TAX_NATIONATILY'),"aria-required":true,"items":_vm.countries,"returnObject":true,"itemText":"name","clearable":true,"disabled":false,"loading":_vm.countriesLoading,"error-messages":errors,"separate-lists-number":7,"autocomplete":"adoszam","custom-list-item":"","hide-no-data":"","required":""},model:{value:(_vm.formValues.vatCountry),callback:function ($$v) {_vm.$set(_vm.formValues, "vatCountry", $$v)},expression:"formValues.vatCountry"}})]}}],null,true)}),(_vm.formValues.vatCountry)?[(_vm.formValues.vatCountry.id === 'H')?_c('ValidationProvider',{attrs:{"vid":"vatNumber","name":_vm.$t('USER_BILLING_DETAILS.FIELDS.HUN_TAX_NUMBER'),"rules":{
            required: true,
            hungarienTaxNumberWithSample:
              _vm.validVATNumber && _vm.validVATNumber.jrgxPattern
                ? {
                    pattern: new RegExp(_vm.validVATNumber.jrgxPattern),
                    sample: _vm.validVATNumber.sample,
                  }
                : { pattern: null, sample: '11111111-1-11' },
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('TextInput',{key:"billingDetailsForm--vatNumber",attrs:{"inputmode":"numeric","id":(_vm.pageId + "--vatNumber"),"error-messages":errors,"label":_vm.$t('USER_BILLING_DETAILS.FIELDS.HUN_TAX_NUMBER'),"aria-label":_vm.$t('USER_BILLING_DETAILS.FIELDS.HUN_TAX_NUMBER'),"aria-required":true,"autocomplete":"adozas-szerinti-honossag","hungarienTaxNumberFormat":"","required":""},on:{"input":_vm.vatNumberChanged},model:{value:(_vm.formValues.vatNumber),callback:function ($$v) {_vm.$set(_vm.formValues, "vatNumber", $$v)},expression:"formValues.vatNumber"}})]}}],null,true)}):_vm._e(),(
            _vm.formValues.vatCountry.id === 'H' &&
            fields.vatNumber &&
            fields.vatNumber.valid &&
            _vm.invoiceGroupIdIsVisible
          )?_c('ValidationProvider',{attrs:{"rules":{
            required: true,
            hungarienTaxNumberWithSample:
              _vm.validVATNumber && _vm.validVATNumber.jrgxPattern
                ? {
                    pattern: new RegExp(_vm.validVATNumber.jrgxPattern),
                    sample: '11111111-5-11',
                  }
                : { pattern: null, sample: '11111111-5-11' },
            taxGroupId: true,
          },"name":_vm.$t('USER_BILLING_DETAILS.FIELDS.GROUP_ID')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('TextInput',{key:"billingDetailsForm--invoiceGroupId",attrs:{"inputmode":"numeric","id":(_vm.pageId + "--invoiceGroupId"),"error-messages":errors,"label":_vm.$t('USER_BILLING_DETAILS.FIELDS.GROUP_ID'),"aria-label":_vm.$t('USER_BILLING_DETAILS.FIELDS.GROUP_ID'),"aria-required":true,"autocomplete":"csoportazonosito","hungarienTaxNumberFormat":"","required":""},model:{value:(_vm.formValues.invoiceGroupId),callback:function ($$v) {_vm.$set(_vm.formValues, "invoiceGroupId", $$v)},expression:"formValues.invoiceGroupId"}})]}}],null,true)}):_vm._e(),(
            _vm.formValues.vatCountry.memberOfEu &&
            _vm.formValues.vatCountry.id !== 'H' &&
            !_vm.formValues.hasNotEUTaxNumber
          )?_c('ValidationProvider',{attrs:{"rules":{
            required: true,
            customRegexWithSample:
              _vm.validVATNumber && _vm.validVATNumber.jrgxPattern
                ? {
                    pattern: new RegExp(_vm.validVATNumber.jrgxPattern),
                    sample: _vm.validVATNumber.sample,
                  }
                : {
                    pattern: _vm.validationRegExp.defaultEUVatNumber,
                    sample: 'AZ123456789-',
                  },
          },"name":_vm.$t('USER_BILLING_DETAILS.FIELDS.EU_TAX_NUMBER')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('TextInput',{key:"billingDetailsForm--euVatNumber",attrs:{"id":(_vm.pageId + "--euVatNumber"),"error-messages":errors,"onlyUpperCase":"","required":true,"label":_vm.$t('USER_BILLING_DETAILS.FIELDS.EU_TAX_NUMBER'),"aria-label":_vm.$t('USER_BILLING_DETAILS.FIELDS.EU_TAX_NUMBER'),"aria-required":true},model:{value:(_vm.formValues.euVatNumber),callback:function ($$v) {_vm.$set(_vm.formValues, "euVatNumber", $$v)},expression:"formValues.euVatNumber"}})]}}],null,true)}):_vm._e(),(
            _vm.formValues.vatCountry.id !== 'H' &&
            (!_vm.formValues.vatCountry.memberOfEu ||
              _vm.formValues.hasNotEUTaxNumber)
          )?_c('ValidationProvider',{attrs:{"rules":{
            required: true,
            customRegexWithSample: {
              pattern: _vm.formValues.vatCountry.memberOfEu
                ? _vm.validationRegExp.defaultEUCountryVatNumber
                : _vm.validationRegExp.defaultNotEUCountryVatNumber,
              sample: _vm.formValues.vatCountry.memberOfEu
                ? '123456789-B-23'
                : 'MK123456789',
            },
          },"name":_vm.$t('USER_BILLING_DETAILS.FIELDS.OTHER_TAX_NUMBER'),"customMessages":{
            customRegexWithSample: _vm.formValues.vatCountry.memberOfEu
              ? _vm.$t('USER_BILLING_DETAILS.MESSAGE2.TAX_EU')
              : _vm.$t('USER_BILLING_DETAILS.MESSAGE2.TAX_NOT_EU'),
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('TextInput',{key:"billingDetailsForm--thirdCountryVatNumber",attrs:{"id":(_vm.pageId + "--thirdCountryVatNumber"),"error-messages":errors,"required":true,"onlyUpperCase":"","label":_vm.$t('USER_BILLING_DETAILS.FIELDS.OTHER_TAX_NUMBER'),"aria-label":_vm.$t('USER_BILLING_DETAILS.FIELDS.OTHER_TAX_NUMBER'),"aria-required":true},model:{value:(_vm.formValues.thirdCountryVatNumber),callback:function ($$v) {_vm.$set(_vm.formValues, "thirdCountryVatNumber", $$v)},expression:"formValues.thirdCountryVatNumber"}})]}}],null,true)}):_vm._e(),(
            _vm.formValues.vatCountry.memberOfEu &&
            _vm.formValues.vatCountry.id !== 'H'
          )?_c('SwitchesInput',{staticClass:"mb-3",attrs:{"id":(_vm.pageId + "--hasNotEUTaxNumber"),"label":_vm.$t('USER_BILLING_DETAILS.FIELDS.HAS_NOT_EU_TAX_NUMBER'),"aria-label":_vm.$t('USER_BILLING_DETAILS.FIELDS.HAS_NOT_EU_TAX_NUMBER')},on:{"input":_vm.hasNotEUTaxNumberChanged},model:{value:(_vm.formValues.hasNotEUTaxNumber),callback:function ($$v) {_vm.$set(_vm.formValues, "hasNotEUTaxNumber", $$v)},expression:"formValues.hasNotEUTaxNumber"}}):_vm._e()]:_vm._e()]:_vm._e(),(_vm.defaultCheckBox)?_c('CheckboxInput',{staticClass:"mb-3",attrs:{"label":_vm.$t('NEW_BILLING_ADDRESS_PAGE.MESSAGE1'),"aria-label":_vm.$t('NEW_BILLING_ADDRESS_PAGE.MESSAGE1'),"id":(_vm.pageId + "--defaultBillingAddress")},model:{value:(_vm.formValues.defaultBillingAddress),callback:function ($$v) {_vm.$set(_vm.formValues, "defaultBillingAddress", $$v)},expression:"formValues.defaultBillingAddress"}}):_vm._e(),(_vm.loading)?_c('div',{staticClass:"custom-visible d-sr-only",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.$t("GENERAL.LOADING_BUTTON"))+" ")]):_vm._e(),_c('v-btn',{staticClass:"mt-1",attrs:{"id":(_vm.pageId + "--submitButton"),"type":"submit","color":"primary","ripple":false,"loading":_vm.loading,"disabled":invalid,"aria-disabled":(invalid || _vm.loading).toString(),"aria-label":_vm.submitLabel,"depressed":"","block":""}},[_vm._v(" "+_vm._s(_vm.submitLabel)+" ")])],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }