import store from "@/store";
import { Content } from "@/core/dataTypes/types/cmsService.interface";

const content = (): Content[] | undefined => store.state.custom?.content;
const contentLoading = (): boolean | undefined =>
  store.state.custom?.contentLoading;
// const getContent = (): Promise<void> => store.dispatch("custom/getContent");
const getContent = (slug: string): Promise<void> =>
  store.dispatch("custom/getContent", slug);
const resetModule = (): Promise<void> => store.dispatch("custom/resetModule");

export const customFacade = {
  content,
  contentLoading,
  getContent,
  resetModule,
};
