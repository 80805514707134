/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CmsMedia,
  CmsMediaCollection,
  CmsMediaCollectionItem,
  Media,
  MediaResponse,
} from "@/core/dataTypes/types/cmsService.interface";

const transformCreditCardIcons = (
  medias: CmsMediaCollectionItem[]
): CmsMedia[] => {
  return medias.map((item) => {
    return item.media;
  });
};
const transformMediaItem = (data: any): Media => ({
  url: data.url,
  ext: data.ext,
  ...(data.width && { width: data.width }),
  ...(data.height && { height: data.height }),
});

const transformMediaResponse = (data: any): MediaResponse | undefined => {
  if (!data) return;

  const { alternativeText, caption, thumbnails } = data;

  return {
    ...(alternativeText && { altText: alternativeText }),
    caption,
    ...transformMediaItem(data),
    ...(thumbnails && { thumbnails: thumbnails }),
  };
};

const transformMediaCollection = (data: any): CmsMediaCollection => {
  return {
    ...data,
    items: data.items.map((item: any) => ({
      ...item,
      media: transformMediaResponse(item.media),
    })),
  };
};
export const mediaTransformer = {
  transformMediaResponse,
  transformMediaCollection,
  transformCreditCardIcons,
};
