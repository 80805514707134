import { LoginRequest, LoginResponse, SessionData } from "../dataTypes/types";
import * as customerServiceApi from "@/core/api/customerService.api";
import { AxiosResponse } from "axios";
import { storeFacade } from "@/store/store.facade";
import {
  differenceInMilliseconds,
  isAfter,
  subMinutes,
  max,
  isBefore,
  addMilliseconds,
} from "date-fns";
import DialogService from "@/core/services/dialog.service";
import SessionExpiredDialog from "@/components/dialog/SessionExpiredDialog.vue";
import router from "@/router";
import { ERoutes } from "../dataTypes/enum";
import { userFacade } from "@/store/modules/user/user.facade";
import LoginDialog from "@/components/dialog/LoginDialog.vue";
import { getLanguageStaticRoute } from "@/core/utils/router.util";
class AuthService {
  refreshPendingTimeInMinutes = 10;
  private _sessionExpirationTimer: ReturnType<typeof setTimeout> | undefined;
  private _refreshExpirationTimer: ReturnType<typeof setTimeout> | undefined;
  private _refreshTimer: ReturnType<typeof setTimeout> | undefined;
  refreshApiUrl = "CustomerService/customer/hd/auth/refresh";
  private _refreshExcludedUrls: string[] = [
    "customer/hd/auth/logout",
    this.refreshApiUrl,
  ];
  // 5 minutes
  private _refreshPeriod = 300000;
  private _refreshNeeded = false;

  login(requestData: LoginRequest): Promise<LoginResponse> {
    return customerServiceApi.login(requestData).then((response) => {
      if (response.sessionData?.sessionToken) {
        this._setSessionTokens(response.sessionData);
        this.sessionExpirationHandler();
      }
      return response;
    });
  }

  logout(refreshToken: string): Promise<AxiosResponse<void>> {
    return customerServiceApi.logout(refreshToken).finally(() => {
      this.resetStateAndSessionTokens();
      this.clearSessionTimer();
      this.clearRefreshExpirationTimer();
      this._clearRefreshTimer();
      DialogService.closeAll();
      router.push({
        path: getLanguageStaticRoute(ERoutes.LOGIN),
        query: { alert: "1" },
      });
    });
  }

  refresh(refreshToken: string): Promise<SessionData> {
    return customerServiceApi
      .refresh(refreshToken)
      .then((response) => {
        this._setSessionTokens(response);
        return response;
      })
      .then((response) => {
        this.sessionExpirationHandler();
        return response;
      });
  }

  hasValidToken(): boolean {
    const tokens = this._getSessionTokens();
    return tokens.sessionExpirationTime && tokens.sessionToken
      ? isAfter(new Date(tokens.sessionExpirationTime), new Date())
      : false;
  }

  hasValidRefreshToken(): boolean {
    const tokens = this._getSessionTokens();
    return tokens.refreshExpirationTime && tokens.refreshToken
      ? isAfter(new Date(tokens.refreshExpirationTime), new Date())
      : false;
  }

  resetStateAndSessionTokens(): void {
    this._removeSessionTokens();
    storeFacade.resetState();
  }

  sessionExpirationHandler(): void {
    const tokens = this._getSessionTokens();

    this.clearSessionTimer();
    this.clearRefreshExpirationTimer();

    if (
      tokens.sessionExpirationTime &&
      tokens.refreshExpirationTime &&
      this.hasValidToken()
    ) {
      this._sessionExpirationDialogHandler(
        tokens.sessionExpirationTime,
        tokens.refreshExpirationTime
      );
    } else {
      this.logout(localStorage.getItem("refreshToken") ?? "");
    }
  }

  clearSessionTimer(): void {
    if (this._sessionExpirationTimer) {
      clearTimeout(this._sessionExpirationTimer);
    }
  }

  clearRefreshExpirationTimer(): void {
    if (this._refreshExpirationTimer) {
      clearTimeout(this._refreshExpirationTimer);
    }
  }

  private _sessionExpirationDialogHandler(
    tokenExpirationDate: string,
    refreshTokenExpirationDate: string
  ): void {
    if (
      isBefore(
        new Date(tokenExpirationDate),
        new Date(refreshTokenExpirationDate)
      )
    ) {
      const calculateActiveSessionTime = subMinutes(
        new Date(tokenExpirationDate),
        this.refreshPendingTimeInMinutes
      );
      const sessionExpirationTimeoutTime = differenceInMilliseconds(
        calculateActiveSessionTime,
        new Date()
      );

      this._sessionExpirationTimer = setTimeout(() => {
        if (this._refreshNeeded) {
          this._clearRefreshTimer();
          userFacade.refresh().then(() => {
            this._refreshNeeded = false;
          });
        } else {
          DialogService.open({
            persistent: true,
            maxWidth: 400,
            component: SessionExpiredDialog,
          });
        }
      }, sessionExpirationTimeoutTime);
    } else {
      const selectLatestSessionDate = max([
        new Date(tokenExpirationDate),
        new Date(refreshTokenExpirationDate),
      ]);
      const calculateMaxTime = subMinutes(selectLatestSessionDate, 1);
      const refreshExpirationTimeoutTime = differenceInMilliseconds(
        calculateMaxTime,
        new Date()
      );
      this._refreshExpirationTimer = setTimeout(() => {
        DialogService.open({
          persistent: true,
          maxWidth: 425,
          component: LoginDialog,
        });
      }, refreshExpirationTimeoutTime);
    }
  }

  private _clearRefreshTimer(): void {
    if (this._refreshTimer) {
      clearTimeout(this._refreshTimer);
    }
  }

  refreshSessionHandler = (requestUrl: string): void => {
    if (!this.hasValidToken() && !this.hasValidRefreshToken()) return;
    const tokens = this._getSessionTokens();
    const urlCheck =
      requestUrl && this._refreshExcludedUrls.includes(requestUrl);
    const calculateRefreshTime = addMilliseconds(
      new Date(),
      this._refreshPeriod
    );
    const hasRefreshableToken = isBefore(
      calculateRefreshTime,
      new Date(tokens.refreshExpirationTime as string)
    );
    if (!urlCheck && hasRefreshableToken) {
      this._clearRefreshTimer();
      this._refreshNeeded = true;
      this._refreshTimer = setTimeout(() => {
        this.clearSessionTimer();
        userFacade.refresh().then(() => {
          this._refreshNeeded = false;
        });
      }, this._refreshPeriod);
    }
  };

  private _getSessionTokens(): Record<
    | "sessionToken"
    | "sessionExpirationTime"
    | "refreshToken"
    | "refreshExpirationTime",
    string | null
  > {
    return {
      sessionToken: localStorage.getItem("token"),
      sessionExpirationTime: localStorage.getItem("tokenExpiry"),
      refreshToken: localStorage.getItem("refreshToken"),
      refreshExpirationTime: localStorage.getItem("refreshTokenExpiry"),
    };
  }

  private _setSessionTokens(sessionData: SessionData): void {
    localStorage.setItem("token", sessionData.sessionToken);
    localStorage.setItem("tokenExpiry", sessionData.sessionTokenExpiry);
    localStorage.setItem("refreshToken", sessionData.refreshToken);
    localStorage.setItem("refreshTokenExpiry", sessionData.refreshTokenExpiry);
    localStorage.setItem("loginName", sessionData.loginName);
  }

  private _removeSessionTokens(): void {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiry");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("refreshTokenExpiry");
    localStorage.removeItem("loginName");
  }
}

export default new AuthService();
