import { generateCryptoRandomString } from "@/core/utils/uuid";
import { CustomerADHOC } from "@/core/dataTypes/types";

export const getAdHocCustomer = (): CustomerADHOC => {
  if (!getAdHocLocalStorage()) {
    createNewAdHocCustomer();
  }
  return getAdHocLocalStorage();
};

const createNewAdHocCustomer = () => {
  adHocCustomer.token = generateCryptoRandomString(32);
  localStorage.setItem("adHocCustomer", JSON.stringify(adHocCustomer));
};
export const setAdHocCartCustomerCartId = (id: string | unknown): void => {
  adHocCustomer.cartId = id as string;
  localStorage.setItem("adHocCustomer", JSON.stringify(adHocCustomer));
};

export const removeAdHocCustomer = (): void => {
  localStorage.removeItem("adHocCustomer");
};

const getAdHocLocalStorage = (): CustomerADHOC => {
  return JSON.parse(localStorage.getItem("adHocCustomer") as string);
};

export const getAdHocCartId = (): string | null =>
  getAdHocLocalStorage()?.cartId as string | null;
export const getAdHocCartToken = (): string | null =>
  getAdHocLocalStorage()?.token as string | null;

export const adHocCustomer: CustomerADHOC = {
  token: getAdHocLocalStorage()?.token || null,
  cartId: getAdHocLocalStorage()?.cartId || null,
};

export const downloadPDF = (data: Blob, fileName: string): void => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};
