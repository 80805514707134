




















import { computed, defineComponent } from "@vue/composition-api";
import { userFacade } from "@/store/modules/user/user.facade";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "ChangeEmailActivationEmailSent",
  setup() {
    document.title = i18n.tc(
      "CHANGE_EMAIL_ACTIVATION_EMAIL_SUCCESS.PAGE_TITLE"
    );
    const newEmailAddress = computed(userFacade.newEmailAddress);
    return {
      newEmailAddress,
    };
  },
});
