import { i18n } from "@/locales/i18n";

export const EStickerTypeValue = {
  YEARLY_N: `${i18n.tc("STICKER_CARD_LABEL.YEARLY_N")}`,
  YEARLY_C: `${i18n.tc("STICKER_CARD_LABEL.YEARLY_C")}`,
  MONTHLY_N: `${i18n.tc("STICKER_CARD_LABEL.MONTHLY_N")}`,
  _10DAYS_N: `${i18n.tc("STICKER_CARD_LABEL._10DAYS_N")}`,
  ONEDAY_N: `${i18n.tc("STICKER_CARD_LABEL.ONEDAY_N")}`,
};

/*
export enum EStickerTypeValue {
  YEARLY_N = "Éves országos",
  YEARLY_C = "Éves megyei",
  MONTHLY_N = "Havi országos",
  _10DAYS_N = "Heti (10 napos) országos",
}
*/

export enum EStickerType {
  YEARLY_N = "YEARLY_N",
  YEARLY_C = "YEARLY_C",
  MONTHLY_N = "MONTHLY_N",
  _10DAYS_N = "_10DAYS_N",
  ONEDAY_N = "ONEDAY_N",
}
