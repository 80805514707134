import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context("./", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const lang = localStorage.getItem("currLang");
const splitPath = window.location.pathname.split("/");
const pathLang = splitPath.find((i: string) => i.length === 2);
if (pathLang && pathLang !== lang) {
  localStorage.setItem("currLang", pathLang);
}
const localeLang = pathLang || lang || process.env.VUE_APP_I18N_LOCALE || "hu";
export const i18n = new VueI18n({
  locale: localeLang,
  fallbackLocale: localeLang,
  messages: loadLocaleMessages(),
});
