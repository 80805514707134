import { NavigationGuardNext, Route } from "vue-router";
import AuthService from "@/core/services/auth.service";
import { ERoutes } from "../dataTypes/enum";
import httpService from "../services/http.service";
import { commonFacade } from "@/store/modules/common/common.facade";
import { getLanguageStaticRoute } from "@/core/utils/router.util";

export const notAuthedGuard = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
): void => {
  if (httpService.isPending()) {
    httpService.cancel();
  }

  if (AuthService.hasValidToken()) {
    next(getLanguageStaticRoute(ERoutes.DASHBOARD));
  } else {
    commonFacade.setHeaderVisibility(false);
    next();
  }
};
