
































































import { computed, defineComponent, reactive } from "@vue/composition-api";
import TextInput from "@/components/forms/input/TextInput.vue";
import { ERoutes } from "@/core/dataTypes/enum";
import { userFacade } from "@/store/modules/user/user.facade";
import { alertFacade } from "@/store/modules/alert/alert.facade";
import { ForgottenPasswordRequest } from "@/core/dataTypes/types";
import { getRecaptchaToken } from "@/core/utils/captcha";
import { i18n } from "@/locales/i18n";
import { getLanguageStaticRoute } from "@/core/utils/router.util";

export default defineComponent({
  name: "ForgottenPassword",
  components: {
    TextInput,
  },
  setup() {
    const forgottenPasswordLoading = computed(
      userFacade.forgottenPasswordLoading
    );

    const alert = computed(alertFacade.alert);
    const formValues = reactive({
      email: "",
    });
    const sendEmail = () => {
      getRecaptchaToken("forgottenPasswordToken").then((token: string) => {
        const requestData: ForgottenPasswordRequest = {
          customerType: "HD",
          email: formValues.email,
          recaptchaResponseToken: token,
        };
        userFacade.forgottenPassword(requestData);
      });
    };
    return {
      formValues,
      sendEmail,
      getLanguageStaticRoute,
      forgottenPasswordLoading,
      ERoutes,
      alert,
      i18n,
    };
  },
  metaInfo() {
    return {
      titleTemplate: `${i18n.tc("FORGOTTEN_PASSWORD_PAGE.TITLE")} | %s`,
    };
  },
});
