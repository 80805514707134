import { Module } from "vuex";
import {
  ActiveCartResponse,
  AdhocPaymentResult,
  AdHocValidityCheck,
  BillingAddressRequest,
  CartAdhocExtraData,
  CartElement,
  CartElementsRequest,
  CartHighwayStickerQueryResponse,
  CartHistoryItemLoading,
  CartHistoryQueryRequest,
  CartHistoryQueryRow,
  CartResponse,
  CheckStickerOverlapRequestType,
  CheckStickerOverlapResponseType,
  County,
  CountyListRequest,
  CountyListResponse,
  EmailNotifyValidityType,
  PreemptionResponse,
  StickerOverlapType,
  StickerPriceRequest,
  StickerPriceResponse,
  StickerPurchasePeriodList,
  StickerPurchasePeriodRequest,
  StickerShop,
  StickerType,
  StickerValidityEndRequest,
  StickerValidityStartRequest,
  ValidityYear,
} from "@/core/dataTypes/types/sticker.interface";

import {
  AddressType,
  BillingAddressListResponse,
  CreateAndModifyBillingAddressFormState,
  CreateBillingAddressRequestType,
} from "@/core/dataTypes/types";

import * as HighwayPurchaseService from "@/core/api/highwayPurchaseService.api";
import * as customerServiceApi from "@/core/api/customerService.api";
import * as vposServiceApi from "@/core/api/vposService.api";
import SnackService from "@/core/services/snackbar.service";
import { i18n } from "@/locales/i18n";
import {
  StartTransactionRequest,
  StartTransactionResponse,
} from "@/core/dataTypes/types/vpos.interface";

import router from "@/router";
import { EAlertType, ERoutes } from "@/core/dataTypes/enum";
import { AppState } from "@/store";
import { alertFacade } from "@/store/modules/alert/alert.facade";
import * as StickerService from "@/core/services/sticker.service";
import { getLanguageStaticRoute } from "@/core/utils/router.util";

export interface StickerState {
  stickerShop: StickerShop | undefined;
  stickerTypeList: StickerType[];
  stickerPurchasePeriodList: StickerPurchasePeriodList[];
  stickerPurchasePeriod: number;
  stickerValidityStart: Date | undefined;
  stickerValidityEnd: Date | undefined;
  validityYear: ValidityYear[];
  countyList: County[];
  preemption: boolean;
  preemptionLoading: boolean;
  stickerTypeListLoading: boolean;
  stickerPurchasePeriodListLoading: boolean;
  stickerValidityStartLoading: boolean;
  stickerValidityEndLoading: boolean;
  countyListLoading: boolean;
  stickerPriceLoading: boolean;
  stickerPrice: number;
  productId: string[];
  checkStickerOverlapLoading: boolean;
  cartElementsLoading: boolean;
  stickerOverlap: StickerOverlapType[];
  cartElementsCount: number | undefined;
  activeCartLoading: boolean;
  cartLoading: boolean;
  cartId: string | unknown;
  billingAddressId: string | unknown;
  validityStartNotification: boolean;
  validityEndNotification: boolean;
  putEmailNotificationLoading: boolean;
  cost: number;
  cartElementList: CartElement[] | undefined;
  billingAddressList: Partial<AddressType>[];
  billingAddress: Partial<AddressType> | undefined;
  billingAddressListLoading: boolean;
  billingAddressLoading: boolean;
  createBillingAddressLoading: boolean;
  modifyBillingAddressLoading: boolean;
  deleteCartElementLoading: boolean;
  putBillingAddressLoading: boolean;
  maxBillingAddressNum: number;
  startCartTransactionLoading: boolean;
  getTransactionsLoading: boolean;
  transactionsPaymentLoading: boolean;
  postCartHistoryQueryLoading: boolean;
  adhocPaymentResultLoading: boolean;
  transactionsPaymentProgressLongTime: boolean | unknown;
  transactionId: string | undefined;
  cartStatus: string | undefined;
  cartHistoryQueryRow: CartHistoryQueryRow[] | undefined;
  cartPurchaseHistory: CartHistoryQueryRow[];
  cartAdhocExtraData: CartAdhocExtraData | undefined;
  cartAdhocExtraDataIsValid: boolean;
  getAdhocExtraDataLoading: boolean;
  putAdhocExtraDataLoading: boolean;
  cartPurchaseHistoryLoading: boolean;
  adHocValidityCheckLoading: boolean;
  validAdHocId: boolean;
  getInvoiceLoading: boolean;
  getDocumentLoading: boolean;
}

export const initialStickerState: StickerState = {
  stickerShop: undefined,
  stickerTypeList: [],
  stickerPurchasePeriodList: [],
  stickerPurchasePeriod: 0,
  stickerValidityStart: new Date(),
  stickerValidityEnd: new Date(),
  validityYear: [],
  countyList: [],
  preemption: false,
  preemptionLoading: false,
  stickerTypeListLoading: false,
  stickerPurchasePeriodListLoading: false,
  stickerValidityStartLoading: false,
  stickerValidityEndLoading: false,
  countyListLoading: false,
  stickerPriceLoading: false,
  stickerPrice: 0,
  productId: [],
  checkStickerOverlapLoading: false,
  stickerOverlap: [],
  cartElementsLoading: false,
  cartElementsCount: undefined,
  activeCartLoading: false,
  cartLoading: false,
  cartId: null,
  transactionId: undefined,
  billingAddressId: undefined,
  validityStartNotification: false,
  validityEndNotification: false,
  putEmailNotificationLoading: false,
  cost: 0,
  cartElementList: undefined,
  billingAddressList: [],
  billingAddress: undefined,
  billingAddressListLoading: false,
  billingAddressLoading: false,
  maxBillingAddressNum: 5,
  createBillingAddressLoading: false,
  modifyBillingAddressLoading: false,
  deleteCartElementLoading: false,
  putBillingAddressLoading: false,
  startCartTransactionLoading: false,
  getTransactionsLoading: false,
  transactionsPaymentLoading: false,
  postCartHistoryQueryLoading: false,
  adhocPaymentResultLoading: false,
  getInvoiceLoading: false,
  getDocumentLoading: false,
  cartPurchaseHistoryLoading: false,
  transactionsPaymentProgressLongTime: undefined,
  cartHistoryQueryRow: [] || undefined,
  cartPurchaseHistory: [] || undefined,
  cartStatus: undefined,
  cartAdhocExtraData: undefined,
  getAdhocExtraDataLoading: false,
  putAdhocExtraDataLoading: false,
  adHocValidityCheckLoading: false,
  validAdHocId: false,
  cartAdhocExtraDataIsValid: false,
};

export const stickerModule: Module<StickerState, AppState> = {
  namespaced: true,
  state: initialStickerState,

  mutations: {
    saveStickerShop: (state, payload: StickerShop) => {
      state.stickerShop = {
        ...state.stickerShop,
        ...payload,
      };
    },

    // cart
    cartRequest: (state) => {
      state.cartLoading = true;
    },
    cartSuccess: (state) => {
      state.cartLoading = false;
    },
    cartFail: (state) => {
      state.cartLoading = false;
    },

    setCart: (state, payload: CartResponse) => {
      state.cartId = payload.cartId;
      state.transactionId = payload.transactionId;
      state.billingAddressId = payload.billingAddressId;
      state.validityStartNotification = payload.validityStartNotification;
      state.validityEndNotification = payload.validityEndNotification;
      state.cost = payload.cost;
      state.cartStatus = payload.status;
      state.cartElementList = payload.cartElementList.cartElement;
    },
    setCartId: (state, payload: string) => {
      state.cartId = payload;
    },
    resetCartId: (state) => {
      state.cartId = null;
    },

    resetCart: (state) => {
      state.cartId = null;
      state.billingAddressId = undefined;
      state.billingAddress = {};
      state.cost = 0;
      state.cartElementList = [];
    },

    // activeCart
    activeCartRequest: (state) => {
      state.activeCartLoading = true;
    },
    activeCartSuccess: (state) => {
      state.activeCartLoading = false;
    },
    activeCartFail: (state) => {
      state.activeCartLoading = false;
    },
    setActiveCart: (state, payload: ActiveCartResponse) => {
      state.cartId = payload.cartId;
      state.cartStatus = payload.cartStatus;
      state.cartElementsCount = payload.cartElementsCount;
    },

    // getAdHocValidityCheck
    getAdHocValidityCheckRequest: (state) => {
      state.adHocValidityCheckLoading = true;
    },
    getAdHocValidityCheckSuccess: (state) => {
      state.adHocValidityCheckLoading = false;
    },
    getAdHocValidityCheckFail: (state) => {
      state.adHocValidityCheckLoading = false;
    },
    setAdHocValidityCheck: (state, payload: AdHocValidityCheck) => {
      state.cartStatus = payload.cartStatus;
      state.cartId = payload.cartId;
    },

    // getAdhocExtraData
    getAdhocExtraDataRequest: (state) => {
      state.getAdhocExtraDataLoading = true;
    },
    getAdhocExtraDataSuccess: (state) => {
      state.getAdhocExtraDataLoading = false;
    },
    getAdhocExtraDataFail: (state) => {
      state.getAdhocExtraDataLoading = false;
    },
    setAdhocExtraData: (state, payload: CartAdhocExtraData) => {
      state.cartAdhocExtraData = {
        email: payload.email,
        contactLanguage: payload.contactLanguage,
      };
    },
    setAdhocExtraDataIsValid: (state) => {
      state.cartAdhocExtraDataIsValid = true;
    },

    resetAdhocExtraDataIsValid: (state) => {
      state.cartAdhocExtraDataIsValid = false;
    },

    // putAdhocExtraData
    putAdhocExtraDataRequest: (state) => {
      state.putAdhocExtraDataLoading = true;
    },
    putAdhocExtraDataSuccess: (state) => {
      state.putAdhocExtraDataLoading = false;
    },
    putAdhocExtraDataFail: (state) => {
      state.putAdhocExtraDataLoading = false;
    },

    // billingAddress
    billingAddressRequest: (state) => {
      state.billingAddressLoading = true;
    },
    billingAddressSuccess: (state) => {
      state.billingAddressLoading = false;
    },
    billingAddressFail: (state) => {
      state.billingAddressLoading = false;
    },
    setBillingAddress: (state, payload) => {
      state.billingAddress = payload.billingAddress;
    },
    resetBillingAddress: (state) => {
      state.billingAddress = {};
    },

    // billingAddressList
    billingAddressListRequest: (state) => {
      state.billingAddressListLoading = true;
    },
    billingAddressListSuccess: (state) => {
      state.billingAddressListLoading = false;
    },
    billingAddressListFail: (state) => {
      state.billingAddressListLoading = false;
    },
    setBillingAddressList: (state, payload: BillingAddressListResponse) => {
      state.billingAddressList = payload.billingAddressList.billingAddressType;
      state.maxBillingAddressNum = payload.maxBillingAddressNum;
    },
    resetBillingAddressList: (state) => {
      state.billingAddressList = [];
      state.maxBillingAddressNum = 0;
    },

    // cartElements
    cartElementsRequest: (state) => {
      state.cartElementsLoading = true;
    },
    cartElementsSuccess: (state) => {
      state.cartElementsLoading = false;
    },
    cartElementsFail: (state) => {
      state.cartElementsLoading = false;
    },

    // stickerTypeList
    stickerTypeListRequest: (state) => {
      state.stickerTypeListLoading = true;
    },
    stickerTypeListSuccess: (state) => {
      state.stickerTypeListLoading = false;
    },
    stickerTypeListFail: (state) => {
      state.stickerTypeListLoading = false;
    },
    setStickerTypeList: (state, payload: StickerType[]) => {
      state.stickerTypeList = payload;
    },

    // setStickerPurchasePeriodList
    stickerPurchasePeriodRequest: (state) => {
      state.stickerPurchasePeriodListLoading = true;
    },
    stickerPurchasePeriodSuccess: (state) => {
      state.stickerPurchasePeriodListLoading = false;
    },
    stickerPurchasePeriodFail: (state) => {
      state.stickerPurchasePeriodListLoading = false;
    },
    setStickerPurchasePeriodList: (
      state,
      payload: StickerPurchasePeriodList[]
    ) => {
      state.stickerPurchasePeriodList = payload;
      state.stickerPurchasePeriod = payload.length;
    },

    // stickerValidityStart
    stickerValidityStartRequest: (state) => {
      state.stickerValidityStartLoading = true;
    },
    stickerValidityStartSuccess: (state) => {
      state.stickerValidityStartLoading = false;
    },
    stickerValidityStartFail: (state) => {
      state.stickerValidityStartLoading = false;
    },
    setStickerValidityStart: (state, payload: Date) => {
      state.stickerValidityStart = payload;
    },

    // stickerValidityEnd
    stickerValidityEndRequest: (state) => {
      state.stickerValidityEndLoading = true;
    },
    stickerValidityEndSuccess: (state) => {
      state.stickerValidityEndLoading = false;
    },
    stickerValidityEndFail: (state) => {
      state.stickerValidityEndLoading = false;
    },
    setStickerValidityEnd: (state, payload: Date) => {
      state.stickerValidityEnd = payload;
    },

    //preemption
    preemptionRequest: (state) => {
      state.preemptionLoading = true;
    },
    preemptionSuccess: (state) => {
      state.preemptionLoading = false;
    },
    preemptionFail: (state) => {
      state.preemptionLoading = false;
    },
    setPreemption: (state, payload: PreemptionResponse) => {
      state.preemption = payload.preemption;
      state.validityYear = payload.validityYearList.validityYear;
    },

    //county
    countyListRequest: (state) => {
      state.preemptionLoading = true;
    },
    countyListSuccess: (state) => {
      state.preemptionLoading = false;
    },
    countyListFail: (state) => {
      state.preemptionLoading = false;
    },
    setCountyList: (state, payload: CountyListResponse) => {
      state.countyList = payload.countyList.county;
    },

    // sticker price
    stickerPriceRequest: (state) => {
      state.stickerPriceLoading = true;
    },
    stickerPriceSuccess: (state) => {
      state.stickerPriceLoading = false;
    },
    stickerPriceFail: (state) => {
      state.stickerPriceLoading = false;
    },
    setStickerPrice: (state, payload: StickerPriceResponse) => {
      state.stickerPrice = payload.stickerPrice;
      state.productId = payload.productIdList.productId;
    },

    // email notification
    putEmailNotificationRequest: (state) => {
      state.putEmailNotificationLoading = true;
    },

    putEmailNotificationSuccess: (state) => {
      state.putEmailNotificationLoading = false;
    },

    putEmailNotificationFail: (state) => {
      state.putEmailNotificationLoading = false;
    },

    // createBillingAddress
    createBillingAddressRequest: (state) => {
      state.createBillingAddressLoading = true;
    },
    createBillingAddressSuccess: (state) => {
      state.createBillingAddressLoading = false;
    },
    createBillingAddressFail: (state) => {
      state.createBillingAddressLoading = false;
    },

    // modifyBillingAddress
    modifyBillingAddressRequest: (state) => {
      state.modifyBillingAddressLoading = true;
    },
    modifyBillingAddressSuccess: (state) => {
      state.modifyBillingAddressLoading = false;
    },
    modifyBillingAddressFail: (state) => {
      state.modifyBillingAddressLoading = false;
    },

    // startCartTransaction
    startCartTransactionRequest: (state) => {
      state.startCartTransactionLoading = true;
    },
    startCartTransactionSuccess: (state) => {
      state.startCartTransactionLoading = false;
    },
    startCartTransactionFail: (state) => {
      state.startCartTransactionLoading = false;
    },
    setStartCartTransaction: (state, payload: StartTransactionResponse) => {
      state.transactionId = payload.trnId;
    },

    // postCartHistoryQuery
    postCartHistoryQueryRequest: (state) => {
      state.postCartHistoryQueryLoading = true;
    },
    postCartHistoryQuerySuccess: (state) => {
      state.postCartHistoryQueryLoading = false;
    },
    postCartHistoryQueryFail: (state) => {
      state.postCartHistoryQueryLoading = false;
    },
    setPostCartHistoryQuery: (state, payload: CartHistoryQueryRow[]) => {
      state.cartHistoryQueryRow = payload;
    },

    // adhocPaymentResult
    adhocPaymentResultRequest: (state) => {
      state.adhocPaymentResultLoading = true;
    },
    adhocPaymentResultSuccess: (state) => {
      state.adhocPaymentResultLoading = false;
    },
    adhocPaymentResultFail: (state) => {
      state.adhocPaymentResultLoading = false;
    },
    setAdhocPaymentResult: (state, payload: AdhocPaymentResult) => {
      state.cartHistoryQueryRow = [payload.cart];
    },

    // getInvoice
    getInvoiceRequest: (state) => {
      state.getInvoiceLoading = true;
    },
    getInvoiceSuccess: (state) => {
      state.getInvoiceLoading = false;
    },
    getInvoiceFail: (state) => {
      state.getInvoiceLoading = false;
    },

    // getDocument
    getDocumentRequest: (state) => {
      state.getDocumentLoading = true;
    },
    getDocumentSuccess: (state) => {
      state.getDocumentLoading = false;
    },
    getDocumentFail: (state) => {
      state.getDocumentLoading = false;
    },

    // getCartPurchaseHistory
    getCartPurchaseHistoryRequest: (state) => {
      state.cartPurchaseHistoryLoading = true;
    },
    getCartPurchaseHistorySuccess: (state) => {
      state.cartPurchaseHistoryLoading = false;
    },
    getCartPurchaseHistoryFail: (state) => {
      state.cartPurchaseHistoryLoading = false;
    },
    setCartPurchasetHistory: (state, payload: CartHistoryQueryRow[]) => {
      state.cartPurchaseHistory = payload;
    },
    setInvoiceDownloadLoading: (state, payload: CartHistoryItemLoading) => {
      if (state.cartPurchaseHistory[payload.index]) {
        state.cartPurchaseHistory[payload.index].loading = payload.loading;
      }
    },
    setDocumentDownloadLoading: (state, payload: CartHistoryItemLoading) => {
      if (state.cartPurchaseHistory[payload.index]) {
        const index = payload.cartIndex as number;
        state.cartPurchaseHistory[payload.index].cartElementList.cartElement[
          index
        ].loading = payload.loading;
      }
    },
    resetCartPurchasedHistory: (state) => {
      state.cartPurchaseHistory = [];
    },

    // getTransactions
    getTransactionsRequest: (state) => {
      state.getTransactionsLoading = true;
    },
    getTransactionsSuccess: (state) => {
      state.getTransactionsLoading = false;
    },
    getTransactionsFail: (state) => {
      state.getTransactionsLoading = false;
    },
    resetCardStatus: (state) => {
      state.cartStatus = undefined;
    },

    // reset any states
    resetStickerChoice: (state) => {
      state.stickerTypeList = [];
      state.stickerPurchasePeriodList = [];
      state.stickerValidityStart = undefined;
      state.stickerValidityEnd = undefined;
      state.validityYear = [];
      state.stickerPrice = 0;
      state.preemption = false;
    },

    resetValidationYears: (state) => {
      state.stickerValidityStart = undefined;
      state.stickerValidityEnd = undefined;
    },

    resetStickerPrice: (state) => {
      state.stickerPrice = 0;
    },

    resetHdVehicle: (state) => {
      state.stickerShop = {
        hdVehicle: {},
      };
    },

    //checkStickerOverlap
    checkStickerOverlapRequest: (state) => {
      state.checkStickerOverlapLoading = true;
    },

    checkStickerOverlapSuccess: (state) => {
      state.checkStickerOverlapLoading = false;
    },

    checkStickerOverlapFail: (state) => {
      state.checkStickerOverlapLoading = false;
    },

    // deleteCartElement
    deleteCartElementRequest: (state) => {
      state.deleteCartElementLoading = true;
    },
    deleteCartElementSuccess: (state) => {
      state.deleteCartElementLoading = false;
    },
    deleteCartElementFail: (state) => {
      state.deleteCartElementLoading = false;
    },

    // putBillingAddress
    putBillingAddressRequest: (state) => {
      state.putBillingAddressLoading = true;
    },
    putBillingAddressSuccess: (state) => {
      state.putBillingAddressLoading = false;
    },
    putBillingAddressFail: (state) => {
      state.putBillingAddressLoading = false;
    },

    setcheckStickerOverlap: (
      state,
      payload: CheckStickerOverlapResponseType
    ) => {
      state.stickerOverlap = payload.overlap.stickerOverlap;
    },

    clearStickerOverlap: (state) => {
      state.stickerOverlap = [];
    },

    setEmailNotification: (state, payload: EmailNotifyValidityType) => {
      state.validityStartNotification = payload.notifyValidityStartValue;
      state.validityEndNotification = payload.notifyValidityEndValue;
    },

    setTransactionsPaymentProgressLongTime: (
      state,
      payload: boolean | unknown
    ) => {
      state.transactionsPaymentProgressLongTime = payload;
    },
    setValidAdHocId: (state, payload: boolean) => {
      state.validAdHocId = payload;
    },

    // transactionsPayment
    transactionsPaymentProgress: (state, payload: boolean) => {
      state.transactionsPaymentLoading = payload;
    },
  },

  actions: {
    getStickerTypeList: ({ commit }, payload: string) => {
      commit("stickerTypeListRequest");
      HighwayPurchaseService.getStickerTypeList(payload)
        .then((response) => {
          commit("stickerTypeListSuccess");
          commit("setStickerTypeList", response);
        })
        .catch((error) => {
          commit("stickerTypeListFail");
          throw Error(error);
        });
    },

    postStickerPurchasePeriod: (
      { commit },
      payload: StickerPurchasePeriodRequest
    ) => {
      commit("stickerPurchasePeriodRequest");
      return HighwayPurchaseService.postStickerPurchasePeriod(payload)
        .then((response) => {
          commit("stickerPurchasePeriodSuccess");
          commit("setStickerPurchasePeriodList", response);
        })
        .catch((error) => {
          commit("stickerPurchasePeriodFail");
          throw Error(error);
        });
    },

    postStickerValidityStart: (
      { commit },
      payload: StickerValidityStartRequest
    ) => {
      commit("stickerValidityStartRequest");
      return HighwayPurchaseService.postStickerValidityStart(payload)
        .then((response) => {
          commit("stickerValidityStartSuccess");
          commit("setStickerValidityStart", response.stickerValidityStart);
        })
        .catch((error) => {
          commit("stickerValidityStartFail");
          throw Error(error);
        });
    },

    postStickerValidityEnd: (
      { commit },
      payload: StickerValidityEndRequest
    ) => {
      commit("stickerValidityEndRequest");
      return HighwayPurchaseService.postStickerValidityEnd(payload)
        .then((response) => {
          commit("stickerValidityEndSuccess");
          commit("setStickerValidityEnd", response.stickerValidityEnd);
        })
        .catch((error) => {
          commit("stickerValidityEndFail");
          throw Error(error);
        });
    },

    postCountyList: ({ commit }, payload: CountyListRequest) => {
      commit("countyListRequest");
      return HighwayPurchaseService.postCountyList(payload)
        .then((response) => {
          commit("countyListSuccess");
          commit("setCountyList", response);
        })
        .catch((error) => {
          commit("countyListFail");
          throw Error(error);
        });
    },

    getPreemption: ({ commit }, payload: string) => {
      commit("preemptionRequest");
      return HighwayPurchaseService.getPreemption(payload)
        .then((response) => {
          commit("preemptionSuccess");
          commit("setPreemption", response);
        })
        .catch((error) => {
          commit("preemptionFail");
          throw Error(error);
        });
    },

    postStickerPrice: ({ commit }, payload: StickerPriceRequest) => {
      commit("stickerPriceRequest");
      return HighwayPurchaseService.postStickerPrice(payload)
        .then((response) => {
          commit("stickerPriceSuccess");
          commit("setStickerPrice", response);
        })
        .catch((error) => {
          commit("stickerPriceFail");
          throw Error(error);
        });
    },

    postCheckStickerOverlap: (
      { commit, rootState },
      payload: CheckStickerOverlapRequestType
    ) => {
      const customerId = rootState.user?.user?.customerNumber;
      const requestData = {
        ...payload,
        customerId: customerId,
      };
      commit("clearStickerOverlap");
      commit("checkStickerOverlapRequest");
      return HighwayPurchaseService.postCheckStickerOverlap(requestData)
        .then((response) => {
          commit("checkStickerOverlapSuccess");
          commit("setcheckStickerOverlap", response);
        })
        .catch((error) => {
          commit("checkStickerOverlapFail");
          throw Error(error);
        });
    },

    putEmailNotificationSettings: (
      { commit, state, rootState },
      payload: EmailNotifyValidityType
    ) => {
      const customerId = rootState.user?.user?.customerNumber as string;
      const cartId = state.cartId as string;
      commit("putEmailNotificationRequest");
      return HighwayPurchaseService.putNotificationSettings(
        customerId,
        cartId,
        payload
      )
        .then(() => {
          commit("putEmailNotificationSuccess");
          commit("setEmailNotification", payload);
        })
        .catch((error) => {
          commit("putEmailNotificationFail");
          throw Error(error);
        });
    },

    putBillingAddress: (
      { commit, state, rootState },
      payload: BillingAddressRequest
    ) => {
      const customerId = rootState.user?.user?.customerNumber as string;
      const cartId = state.cartId as string;
      commit("putBillingAddressRequest");
      return HighwayPurchaseService.putBillingAddress(
        customerId,
        cartId,
        payload
      )
        .then(() => {
          commit("putBillingAddressSuccess");
        })
        .catch((error) => {
          commit("putBillingAddressFail");
          throw Error(error);
        });
    },

    deleteCartElement: ({ commit, rootState }, payload: string) => {
      const notLoggedInUser = rootState.user?.notLoggedInUser;
      const customerId = notLoggedInUser
        ? "ADHOC"
        : rootState.user?.user?.customerNumber;
      commit("deleteCartElementRequest");
      return HighwayPurchaseService.deleteCartElement(
        customerId as string,
        payload
      )
        .then(() => {
          commit("deleteCartElementSuccess");
        })
        .catch((error) => {
          commit("deleteCartElementFail");
          throw Error(error);
        });
    },
    getActiveCart: ({ commit, rootState }) => {
      commit("activeCartRequest");
      const customerId = rootState.user?.user?.customerNumber as string;
      if (!rootState.user?.notLoggedInUser) {
        return HighwayPurchaseService.getActiveCart(customerId)
          .then((response) => {
            commit("activeCartSuccess");
            commit("setActiveCart", response);
            return response;
          })
          .catch((error) => {
            commit("activeCartFail");
            throw Error(error);
          });
      } else {
        const cartId = StickerService.getAdHocCartId();
        if (cartId) {
          commit("setCartId", cartId);
        }
        commit("activeCartSuccess");
        return Promise.resolve();
      }
    },

    getAdHocValidityCheck: ({ commit }, payload?: boolean) => {
      commit("getAdHocValidityCheckRequest");
      const cartId = StickerService.getAdHocCartId() as string;
      return HighwayPurchaseService.getAdHocValidityCheck(cartId)
        .then((response) => {
          const isValidAdHocId = response.result !== "INVALID_ADHOC_ID";
          commit("getAdHocValidityCheckSuccess");
          commit("setAdHocValidityCheck", response);
          commit("setValidAdHocId", isValidAdHocId);

          if (!isValidAdHocId) {
            StickerService.removeAdHocCustomer();
            commit("resetCart");
            if (payload) {
              router
                .push(
                  getLanguageStaticRoute(
                    `${ERoutes.STICKER_PURCHASE}/${ERoutes.STICKER_CREATION}`
                  )
                )
                .catch((err) => err);
            }
            alertFacade.setAlert({
              message: i18n.t(`HTTP_ERRORS.INVALID_ADHOC_ID`) as string,
              type: EAlertType.ERROR,
            });
          }
          return isValidAdHocId;
        })
        .catch((error) => {
          commit("getAdHocValidityCheckFail");
          throw Error(error);
        });
    },

    postCartElements: ({ commit, rootState }, payload: CartElementsRequest) => {
      commit("cartElementsRequest");
      const notLoggedInUser = rootState.user?.notLoggedInUser;
      const customerId = notLoggedInUser
        ? "ADHOC"
        : rootState.user?.user?.customerNumber;
      return HighwayPurchaseService.postCartElements(
        customerId as string,
        payload
      )
        .then((response) => {
          commit("cartElementsSuccess");
          if (notLoggedInUser && response?.cartId) {
            commit("setCartId", response?.cartId);
            StickerService.setAdHocCartCustomerCartId(response?.cartId);
          }
        })
        .catch((error) => {
          commit("cartElementsFail");
          throw Error(error);
        });
    },

    getCart: ({ commit, state, rootState }) => {
      commit("cartRequest");
      const notLoggedInUser = rootState.user?.notLoggedInUser;
      const customerId = notLoggedInUser
        ? "ADHOC"
        : rootState.user?.user?.customerNumber;
      const cartId = notLoggedInUser
        ? StickerService.getAdHocCartId()
        : state.cartId;
      return HighwayPurchaseService.getCart(
        customerId as string,
        cartId as string
      )
        .then((response) => {
          // if (!notLoggedInUser) {}
          commit("setCart", response);
          commit("cartSuccess");
          return response;
        })
        .catch((error) => {
          commit("cartFail");
          throw Error(error);
        });
    },

    getAdhocExtraData: ({ commit }) => {
      commit("getAdhocExtraDataRequest");
      const cartId = StickerService.getAdHocCartId();
      return HighwayPurchaseService.getAdhocExtraData(cartId)
        .then((response) => {
          commit("getAdhocExtraDataSuccess");
          commit("setAdhocExtraData", response);
          if (response.email) {
            commit("setAdhocExtraDataIsValid");
          }
        })
        .catch((error) => {
          commit("getAdhocExtraDataFail");
          commit("resetAdhocExtraDataIsValid");
          throw Error(error);
        });
    },

    putAdhocExtraData: ({ commit, state }, payload: CartAdhocExtraData) => {
      commit("putAdhocExtraDataRequest");
      return HighwayPurchaseService.putAdhocExtraData(state.cartId, payload)
        .then(() => {
          commit("putAdhocExtraDataSuccess");
          commit("setAdhocExtraData", payload);
          commit("setAdhocExtraDataIsValid");
          // SnackService.open({
          //   text: "Sikeres mentés!",
          // });
        })
        .catch((error) => {
          commit("putAdhocExtraDataFail");
          throw Error(error);
        });
    },

    getBillingAddressList: ({ commit, rootState }) => {
      commit("billingAddressListRequest");
      const customerId = rootState.user?.user?.customerNumber as string;
      return customerServiceApi
        .getBillingAddressList(customerId)
        .then((response) => {
          commit("billingAddressListSuccess");
          commit("setBillingAddressList", response);
        })
        .catch((error) => {
          commit("billingAddressListFail");
          throw Error(error);
        });
    },

    getBillingAddress: ({ commit, state, rootState }) => {
      commit("billingAddressRequest");
      const billingAddressId = state.billingAddressId as string;
      const notLoggedInUser = rootState.user?.notLoggedInUser;
      const customerId = notLoggedInUser
        ? "ADHOC"
        : rootState.user?.user?.customerNumber;
      if (billingAddressId) {
        return customerServiceApi
          .getBillingAddress(customerId as string, billingAddressId)
          .then((response) => {
            commit("setBillingAddress", response);
          })
          .catch((error) => {
            commit("billingAddressFail");
            throw Error(error);
          });
      } else {
        commit("billingAddressSuccess");
        commit("resetBillingAddress");
        return Promise.resolve();
      }
    },
    createBillingAddress: (
      { commit, rootState },
      payload: Partial<CreateAndModifyBillingAddressFormState>
    ) => {
      commit("createBillingAddressRequest");
      const {
        billingName,
        country,
        zipCode,
        city,
        street,
        streetType,
        streetNumber,
        floor,
        door,
        vatCountry,
        vatNumber,
        invoiceGroupId,
        euVatNumber,
        thirdCountryVatNumber,
      } = payload;
      const notLoggedInUser = rootState.user?.notLoggedInUser;
      const requestData: CreateBillingAddressRequestType = {
        billingAddress: {
          corporation: !!payload.corporation,
          billingName,
          country,
          zipCode,
          city,
          street,
          streetType,
          streetNumber,
          ...(floor && { floor }),
          ...(door && { door }),
          ...(vatCountry && { vatCountry: vatCountry.id }),
          ...(vatNumber && { vatNumber }),
          ...(invoiceGroupId && { invoiceGroupId }),
          ...(euVatNumber && { euVatNumber }),
          ...(thirdCountryVatNumber && { thirdCountryVatNumber }),
        },
        ...(!notLoggedInUser && {
          defaultBillingAddress: !!payload.defaultBillingAddress,
        }),
        ...(notLoggedInUser && {
          cartId: StickerService.getAdHocCartId(),
        }),
      };
      const customerId = rootState.user?.user?.customerNumber;
      return customerServiceApi
        .createBillingAddress(customerId as string, requestData)
        .then((response) => {
          commit("setBillingAddress", response);
          commit("createBillingAddressSuccess");
          SnackService.open({
            text: i18n.tc("USER_MODULE.NEW_BILLLING_ADDRESS_SUCCESS"),
          });
        })
        .catch((error) => {
          commit("createBillingAddressFail");
          throw Error(error);
        });
    },

    modifyBillingAddress: (
      { commit, state, rootState },
      payload: Partial<CreateAndModifyBillingAddressFormState>
    ) => {
      commit("modifyBillingAddressRequest");
      const {
        billingName,
        country,
        zipCode,
        city,
        street,
        streetType,
        streetNumber,
        floor,
        door,
        vatCountry,
        vatNumber,
        invoiceGroupId,
        euVatNumber,
        thirdCountryVatNumber,
      } = payload;

      const requestData: CreateBillingAddressRequestType = {
        billingAddress: {
          corporation: !!payload.corporation,
          billingName,
          country,
          zipCode,
          city,
          street,
          streetType,
          streetNumber,
          ...(floor && { floor }),
          ...(door && { door }),
          ...(vatCountry && { vatCountry: vatCountry.id }),
          ...(vatNumber && { vatNumber }),
          ...(invoiceGroupId && { invoiceGroupId }),
          ...(euVatNumber && { euVatNumber }),
          ...(thirdCountryVatNumber && { thirdCountryVatNumber }),
        },
      };
      const notLoggedInUser = rootState.user?.notLoggedInUser;
      const customerId = notLoggedInUser
        ? "ADHOC"
        : rootState.user?.user?.customerNumber;
      const billingAddressId = state.billingAddress?.customerAddressId;
      return customerServiceApi
        .modifyBillingAddress(customerId, billingAddressId, requestData)
        .then(() => {
          commit("modifyBillingAddressSuccess");
          SnackService.open({
            text: i18n.tc("USER_MODULE.BILLLING_ADDRESS_SUCCESS"),
          });
        })
        .catch((error) => {
          commit("modifyBillingAddressFail");
          throw Error(error);
        });
    },
    startCartTransaction: (
      { commit, rootState },
      payload: StartTransactionRequest
    ) => {
      commit("startCartTransactionRequest");
      const notLoggedInUser = rootState.user?.notLoggedInUser;
      const customerId = notLoggedInUser
        ? "ADHOC"
        : rootState.user?.user?.customerNumber;
      const data = {
        ...payload,
        customerId: customerId as string,
      };
      return vposServiceApi
        .startCartTransaction(data)
        .then((response) => {
          commit("startCartTransactionSuccess");
          commit("setStartCartTransaction", response);
          window.location.href = response.otpurl;
        })
        .catch((error) => {
          commit("startCartTransactionFail");
          throw Error(error);
        });
    },

    getTransactions: ({ commit, state, dispatch }) => {
      commit("getTransactionsRequest");
      commit("transactionsPaymentProgress", true);
      return vposServiceApi
        .getTransactions(state.transactionId as string)
        .then((response) => {
          const resultCode = response.resultCode;
          // const authorizationCode = response.authorizationCode;
          const status = response.status;
          const unsuccessPaymentTitle = i18n.t(
            "STICKER_PAY.UNSUCCESSFULL_TITLE"
          );
          const unsuccessPaymentMessage = i18n.t(
            "STICKER_PAY.UNSUCCESSFULL_PAYMENT",
            { cartId: state.cartId }
          );
          let resultMsg;
          switch (true) {
            case status === "F" && resultCode === "SIKER":
              // !!authorizationCode:
              commit("transactionsPaymentProgress", false);
              commit("resetAdhocExtraDataIsValid");
              router.push(getLanguageStaticRoute(ERoutes.STICKER_SUCCESS));
              break;
            case status === "F" &&
              (resultCode === "VISSZAUTASITOTTLEZARAS" ||
                resultCode === "VISSZAUTASITOTTFIZETES" ||
                resultCode === "FIZETESTIMEOUT" ||
                resultCode === "055" ||
                resultCode === "056" ||
                resultCode === "057" ||
                resultCode === "058" ||
                resultCode === "070" ||
                resultCode === "205" ||
                resultCode === "206" ||
                resultCode === "903" ||
                resultCode === "909"):
              resultMsg = i18n.t(
                `STICKER_PAY.UNSUCCESSFULL_PAYMENT_${resultCode}`
              );
              document.title = i18n.tc(
                "STICKER_PURCHASE_PAGE.UNSUCCESSFUL_PAY_PAGE_TITLE"
              );
              alertFacade.setAlert({
                message: `${unsuccessPaymentTitle}<br>${resultMsg}<br>${unsuccessPaymentMessage}`,
                type: EAlertType.ERROR,
              });
              commit("transactionsPaymentProgress", false);
              commit("resetCardStatus");
              break;

            case status === "F" &&
              (resultCode === "901" || resultCode === "051"):
              resultMsg = i18n.t(
                `STICKER_PAY.UNSUCCESSFULL_PAYMENT_${resultCode}`
              );
              document.title = i18n.tc(
                "STICKER_PURCHASE_PAGE.UNSUCCESSFUL_PAY_PAGE_TITLE"
              );
              alertFacade.setAlert({
                message: `${unsuccessPaymentTitle}<br>${resultMsg}<br>${unsuccessPaymentMessage}`,
                type: EAlertType.ERROR,
              });
              commit("transactionsPaymentProgress", false);
              commit("resetCardStatus");
              break;

            case status === "F" &&
              (resultCode === "902" ||
                resultCode === "059" ||
                resultCode === "072"):
              resultMsg = i18n.t(
                `STICKER_PAY.UNSUCCESSFULL_PAYMENT_${resultCode}`
              );
              document.title = i18n.tc(
                "STICKER_PURCHASE_PAGE.UNSUCCESSFUL_PAY_PAGE_TITLE"
              );
              alertFacade.setAlert({
                message: `${unsuccessPaymentTitle}<br>${resultMsg}<br>${unsuccessPaymentMessage}`,
                type: EAlertType.ERROR,
              });
              commit("resetCardStatus");
              commit("transactionsPaymentProgress", false);
              break;

            case status === "F" &&
              (resultCode === "074" ||
                resultCode === "076" ||
                resultCode === "050" ||
                resultCode === "200" ||
                resultCode === "089"):
              resultMsg = i18n.t(
                `STICKER_PAY.UNSUCCESSFULL_PAYMENT_${resultCode}`
              );
              document.title = i18n.tc(
                "STICKER_PURCHASE_PAGE.UNSUCCESSFUL_PAY_PAGE_TITLE"
              );
              alertFacade.setAlert({
                message: `${unsuccessPaymentTitle}<br>${resultMsg}<br>${unsuccessPaymentMessage}`,
                type: EAlertType.ERROR,
              });
              commit("resetCardStatus");
              commit("transactionsPaymentProgress", false);
              break;

            case status === "F" &&
              (resultCode === "204" || resultCode === "082"):
              resultMsg = i18n.t(
                `STICKER_PAY.UNSUCCESSFULL_PAYMENT_${resultCode}`
              );
              document.title = i18n.tc(
                "STICKER_PURCHASE_PAGE.UNSUCCESSFUL_PAY_PAGE_TITLE"
              );
              alertFacade.setAlert({
                message: `${unsuccessPaymentTitle}<br>${resultMsg}<br>${unsuccessPaymentMessage}`,
                type: EAlertType.ERROR,
              });
              commit("transactionsPaymentProgress", false);
              commit("resetCardStatus");
              break;
            case status == "F" && resultCode == "SIKERESWEBSHOPFIZETESINDITAS":
              dispatch("getTransactions");
              // setTimeout(() => {}, 1000);
              break;
            case status !== "F":
              dispatch("getTransactions");
              // setTimeout(() => {}, 1000);
              break;

            default:
              commit("transactionsPaymentProgress", false);
              resultMsg = i18n.t(`STICKER_PAY.UNSUCCESSFULL_PAYMENT_DEFAULT`);
              document.title = i18n.tc(
                "STICKER_PURCHASE_PAGE.UNSUCCESSFUL_PAY_PAGE_TITLE"
              );
              alertFacade.setAlert({
                message: `${unsuccessPaymentMessage}<br>${resultMsg}`,
                type: EAlertType.ERROR,
              });
              commit("resetCardStatus");
          }
          commit("resetStickerChoice");
          commit("getTransactionsSuccess");
        })
        .catch((error) => {
          commit("resetCardStatus");
          commit("transactionsPaymentProgress", false);
          commit("getTransactionsFail");
          throw Error(error);
        });
    },
    getAdhocPaymentResult: ({ commit }) => {
      commit("adhocPaymentResultRequest");
      const cartId = StickerService.getAdHocCartId();
      return HighwayPurchaseService.getAdhocPaymentResult(cartId as string)
        .then((response) => {
          commit("adhocPaymentResultSuccess");
          commit("setAdhocPaymentResult", response);
          // StickerService.removeAdHocCustomer();
        })
        .catch((error) => {
          commit("adhocPaymentResultFail");
          throw Error(error);
        });
    },

    postCartHistoryQuery: (
      { commit, rootState },
      payload: CartHistoryQueryRequest
    ) => {
      commit("postCartHistoryQueryRequest");
      const customerId = rootState.user?.user?.customerNumber as string;
      return HighwayPurchaseService.postCartHistoryQuery(customerId, payload)
        .then((response) => {
          commit("postCartHistoryQuerySuccess");
          commit("setPostCartHistoryQuery", response);
        })
        .catch((error) => {
          commit("postCartHistoryQueryFail");
          throw Error(error);
        });
    },
    postHighwayStickerQuery: (
      { commit, rootState },
      payload: CartHighwayStickerQueryResponse
    ) => {
      commit("postCartHistoryQueryRequest");
      const customerId = rootState.user?.user?.customerNumber as string;
      return HighwayPurchaseService.postHighwayStickerQuery(customerId, payload)
        .then((response) => {
          commit("postCartHistoryQuerySuccess");
          commit("setPostCartHistoryQuery", response);
          return response;
        })
        .catch((error) => {
          commit("postCartHistoryQueryFail");
          throw Error(error);
        });
    },

    getCartPurchaseHistory: (
      { commit, rootState },
      payload: CartHistoryQueryRequest
    ) => {
      commit("getCartPurchaseHistoryRequest");
      const customerId = rootState.user?.user?.customerNumber as string;
      return HighwayPurchaseService.postCartHistoryQuery(customerId, payload)
        .then((response) => {
          commit("getCartPurchaseHistorySuccess");
          commit("setCartPurchasetHistory", response);
          return response;
        })
        .catch((error) => {
          commit("getCartPurchaseHistoryFail");
          throw Error(error);
        });
    },

    getInvoice: (
      { commit, rootState },
      payload: {
        invoiceId: string;
        index: number;
      }
    ) => {
      commit("getInvoiceRequest");
      const customerId = rootState.user?.user?.customerNumber as string;
      commit("setInvoiceDownloadLoading", {
        index: payload.index,
        loading: true,
      });
      return HighwayPurchaseService.getInvoice(customerId, payload.invoiceId)
        .then((response) => {
          StickerService.downloadPDF(
            response,
            `szamla_${payload.invoiceId}.pdf`
          );
          commit("getInvoiceSuccess");
          commit("setInvoiceDownloadLoading", {
            index: payload.index,
            loading: false,
          });
        })
        .catch((error) => {
          commit("getInvoiceFail");
          commit("setInvoiceDownloadLoading", {
            index: payload.index,
            loading: false,
          });
          throw Error(error);
        });
    },
    getDocument: (
      { commit, rootState },
      payload: {
        requestId: string;
        index?: number;
        cartIndex?: number;
      }
    ) => {
      commit("getDocumentRequest");
      const customerId = rootState.user?.user?.customerNumber as string;
      if (payload.index) {
        commit("setDocumentDownloadLoading", {
          index: payload.index,
          cartIndex: payload.cartIndex,
          loading: true,
        });
      }
      return HighwayPurchaseService.getDocument(customerId, payload.requestId)
        .then((response) => {
          StickerService.downloadPDF(
            response,
            `matrica_${payload.requestId}.pdf`
          );
          commit("getDocumentSuccess");
          if (payload.index) {
            commit("setDocumentDownloadLoading", {
              index: payload.index,
              cartIndex: payload.cartIndex,
              loading: false,
            });
          }
        })
        .catch((error) => {
          commit("getDocumentFail");
          if (payload.index) {
            commit("setDocumentDownloadLoading", {
              index: payload.index,
              cartIndex: payload.cartIndex,
              loading: false,
            });
          }
          return error;
          throw Error(error);
        });
    },
    putFinalizeInvoiceContact: ({ commit, state, rootState }) => {
      commit("getCartPurchaseHistoryRequest");
      commit("startCartTransactionRequest");
      const notLoggedInUser = rootState.user?.notLoggedInUser;
      const customerId = notLoggedInUser
        ? "ADHOC"
        : rootState.user?.user?.customerNumber;
      const cartId = notLoggedInUser
        ? StickerService.getAdHocCartId()
        : (state.cartId as string);
      return HighwayPurchaseService.putFinalizeInvoiceContact(
        customerId as string,
        cartId as string
      )
        .then(() => {
          commit("startCartTransactionRequest");
        })
        .catch((error) => {
          commit("startCartTransactionFail");
          throw Error(error);
        });
    },

    invoiceDownloadLoading: ({ commit }, payload: CartHistoryItemLoading) => {
      commit("setInvoiceDownloadLoading", payload);
    },

    documentDownloadLoading: ({ commit }, payload: CartHistoryItemLoading) => {
      commit("setDocumentDownloadLoading", payload);
    },

    getAdHocId: ({ commit }) => {
      commit("setAdHocId");
    },
    resetCardStatus: ({ commit }) => {
      commit("resetCardStatus");
    },
    transactionsPaymentProgress: ({ commit }, payload: boolean) => {
      commit("transactionsPaymentProgress", payload);
    },
    resetStickerChoice: ({ commit }) => {
      commit("resetStickerChoice");
    },
    resetBillingAddressList: ({ commit }) => {
      commit("resetBillingAddressList");
    },
    resetBillingAddress: ({ commit }) => {
      commit("resetBillingAddress");
    },

    resetStickerPrice: ({ commit }) => {
      commit("resetStickerPrice");
    },

    resetValidationYears: ({ commit }) => {
      commit("resetValidationYears");
    },
    resetCart: ({ commit }) => {
      commit("resetCart");
    },
    resetCartPurchasedHistory: ({ commit }) => {
      commit("resetCartPurchasedHistory");
    },
    saveStickerShop: ({ commit }, payload: StickerShop) => {
      commit("saveStickerShop", payload);
    },

    resetHdVehicle: ({ commit }, payload: StickerShop) => {
      commit("resetHdVehicle", payload);
    },

    resetCartId: ({ commit }) => {
      commit("resetCartId");
    },
    resetAdhocExtraDataIsValid: ({ commit }) => {
      commit("resetAdhocExtraDataIsValid");
    },

    setTransactionsPaymentProgressLongTime: (
      { commit },
      payload: boolean | unknown
    ) => {
      commit("setTransactionsPaymentProgressLongTime", payload);
    },
    setActualBillingAddress: (
      { commit },
      payload: CreateBillingAddressRequestType
    ) => {
      commit("setBillingAddress", payload);
    },
  },
};
