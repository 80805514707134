

























import { defineComponent, reactive } from "@vue/composition-api";
import SnackbarService from "@/core/services/snackbar.service";
import Snackbar from "@/components/snackbar/Snackbar.vue";

export default defineComponent({
  name: "SnackbarWrapper",
  components: {
    Snackbar,
  },
  setup() {
    const snackbarService = reactive(SnackbarService);

    const removeSnackbar = (id: string): void => {
      SnackbarService.close(id);
    };

    return {
      Snackbar,
      snackbarService,
      removeSnackbar,
    };
  },
});
