








































import { defineComponent, ref, Ref } from "@vue/composition-api";
import { CmsMediaCollectionItem } from "@/core/dataTypes/types/cmsService.interface";
import { cmsServiceApi } from "@/core/api/cmsService.api";

export default defineComponent({
  name: "SlideShowComponent",
  props: {
    interval: {
      type: Number,
      default: 5000,
    },
    cycle: {
      type: Boolean,
      default: false,
    },
    dataListId: {
      type: String,
      required: true,
    },
    bottomControlOnMobile: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const dataList: Ref<CmsMediaCollectionItem[]> = ref([]);
    const startStop: Ref<boolean> = ref(props.cycle);

    const slideStopStart = () => {
      startStop.value = !startStop.value;
    };

    cmsServiceApi
      .getMediaCollection(process.env.VUE_APP_TENANT, props.dataListId)
      .then((response) => {
        dataList.value = response.items;
      });

    return {
      dataList,
      startStop,
      slideStopStart,
    };
  },
});
