import { ValidResponseType } from "@/core/dataTypes/types";
import store from "@/store";

const validZIPCode = (): ValidResponseType | undefined =>
  store.state.billingAddressForm?.validZIPCode;
const validZIPCodeLoading = (): boolean | undefined =>
  store.state.billingAddressForm?.validZIPCodeLoading;
const getValidZIPCode = (countryId: string): Promise<void> =>
  store.dispatch("billingAddressForm/getValidZIPCode", countryId);
const validVATNumber = (): ValidResponseType | undefined =>
  store.state.billingAddressForm?.validVATNumber;
const validVATNumberLoading = (): boolean | undefined =>
  store.state.billingAddressForm?.validVATNumberLoading;
const getValidVATNumberCode = (countryId: string): Promise<void> =>
  store.dispatch("billingAddressForm/getValidVATNumberCode", countryId);

export const billingAddressFormFacade = {
  validZIPCode,
  validZIPCodeLoading,
  getValidZIPCode,
  validVATNumber,
  validVATNumberLoading,
  getValidVATNumberCode,
};
