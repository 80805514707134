import {
  MostCommonCountryResponseType,
  PublicPlacesResponseType,
} from "@/core/dataTypes/types";
import { AppState } from "@/store";
import { Module } from "vuex";
import * as PublicServiceApi from "@/core/api/publicService.api";
import * as CmsServiceApi from "@/core/api/cmsService.api";
import {
  CmsLanguage,
  CmsMedia,
  CmsTableData,
  Content,
  ContentCategories,
  Menu,
  MenuItem,
  TenantData,
} from "@/core/dataTypes/types/cmsService.interface";
// import { menuTransformer } from "@/core/transformers/cmsServiceTransformers/menu.transformer";
import { cmsServiceApi } from "@/core/api/cmsService.api";
import { keyvalueFacade } from "@/store/modules/keyvalue/keyvalue.facade";
import { mediaTransformer } from "@/core/transformers/cmsServiceTransformers/media.transformer";
import { CategoryNames } from "@/core/dataTypes/enum/CategoryNames.enum";
import { commonFacade } from "@/store/modules/common/common.facade";

export interface CommonState {
  showHeader: boolean;
  showOnLogin: boolean;
  showBasketIcon: boolean;
  countries: MostCommonCountryResponseType[];
  countriesLoading: boolean;
  publicPlaces: PublicPlacesResponseType[];
  publicPlacesLoading: boolean;
  tenantDataLoading: boolean;
  tenantData: TenantData;
  mainPageLoading: boolean;
  mainPage: Content;
  mainPageBody: Content[];
  featuredNewsBody: Content[];
  featuredNewsMasterContents: Content[];
  // informationPanelContent: Content[];
  menuItemsLoading: boolean;
  menuItems: Menu;
  creditCardsIconsLoading: boolean;
  creditCardsIcons: CmsMedia[];
  currentPageMenuId: string | undefined;
  contactContent: Content;
  contactContentLoading: boolean;
  contentCategories: ContentCategories[];
  contentCategoriesLoading: boolean;
  cookieContent: Content;
  cookieContentLoading: boolean;
  pageTitle: string;
  isCmsMenu: boolean;
  aszf: string;
  contactCategory: string;
  dataProtection: string;
}

export const initialCommonState: CommonState = {
  showHeader: true,
  showOnLogin: false,
  showBasketIcon: false,
  countries: [],
  countriesLoading: false,
  publicPlaces: [],
  publicPlacesLoading: false,
  tenantDataLoading: false,
  tenantData: {} as TenantData,
  featuredNewsMasterContents: [],
  // informationPanelContent: [],
  mainPageLoading: false,
  mainPage: {} as Content,
  mainPageBody: [],
  featuredNewsBody: [],
  menuItemsLoading: false,
  menuItems: {} as Menu,
  creditCardsIconsLoading: false,
  creditCardsIcons: [],
  currentPageMenuId: "",
  contactContent: {} as Content,
  contactContentLoading: false,
  contentCategories: [],
  contentCategoriesLoading: false,
  cookieContent: {} as Content,
  cookieContentLoading: false,
  pageTitle: "",
  isCmsMenu: false,
  aszf: "",
  contactCategory: "",
  dataProtection: "",
};

export const commonModule: Module<CommonState, AppState> = {
  namespaced: true,
  state: initialCommonState,
  mutations: {
    setHeader: (state, payload: boolean) => {
      state.showHeader = payload;
    },
    setShowOnLogin: (state, payload: boolean) => {
      state.showOnLogin = payload;
    },
    showBasketIcon: (state, payload: boolean) => {
      state.showBasketIcon = payload;
    },
    countriesRequest: (state) => {
      state.countriesLoading = true;
    },
    countriesSuccess: (state, payload: MostCommonCountryResponseType[]) => {
      state.countries = payload;
      state.countriesLoading = false;
    },
    countriesFail: (state) => {
      state.countriesLoading = false;
    },
    publicPlacesRequest: (state) => {
      state.publicPlacesLoading = true;
    },
    publicPlacesSuccess: (state, payload: PublicPlacesResponseType[]) => {
      state.publicPlaces = payload;
      state.publicPlacesLoading = false;
    },
    publicPlacesFail: (state) => {
      state.publicPlacesLoading = false;
    },
    getTenantDataRequest: (state) => {
      state.tenantDataLoading = true;
    },
    tenantDataSuccess: (state, payload: TenantData) => {
      state.tenantDataLoading = false;
      state.tenantData = payload;
    },
    setPageTitle: (state, title: string) => {
      state.pageTitle = title;
    },
    tenantDataFail: (state) => {
      state.tenantDataLoading = false;
    },
    getCreditCardIconsRequest: (state) => {
      state.creditCardsIconsLoading = true;
    },
    creditCardIconsSuccess: (state, payload: CmsMedia[]) => {
      state.creditCardsIconsLoading = false;
      state.creditCardsIcons = payload;
    },
    creditCardIconsFail: (state) => {
      state.creditCardsIconsLoading = false;
    },
    getMainPageRequest: (state) => {
      state.mainPageLoading = true;
    },
    getMainPageSuccess: (state) => {
      state.mainPageLoading = false;
    },
    getMainPageFail: (state) => {
      state.mainPageLoading = false;
    },
    mainPageSuccess: (state, mainPage: Content) => {
      state.mainPageBody.push(mainPage);
    },
    featuredBodySuccess: (state, featured: Content) => {
      state.featuredNewsBody.push(featured);
    },
    resetMainPageContent: (state) => {
      state.featuredNewsBody = [];
      state.mainPageBody = [];
    },
    getMenuItemsRequest: (state) => {
      state.menuItemsLoading = true;
    },
    menuItemsSuccess: (state, payload: Menu) => {
      state.menuItemsLoading = false;
      state.menuItems = payload;
    },
    menuItemsFail: (state) => {
      state.menuItemsLoading = false;
    },
    currentPageMenuId: (state, payload: string | undefined) => {
      state.currentPageMenuId = payload;
    },
    contactContentRequest: (state) => {
      state.contactContentLoading = true;
    },
    contactContentSuccess: (state, payload: Content) => {
      state.contactContent = payload;
      state.contactContentLoading = false;
    },
    contactContentFail: (state) => {
      state.contactContentLoading = false;
    },
    contentCategoriesRequest: (state) => {
      state.contentCategoriesLoading = true;
    },
    contentCategoriesSuccess: (state, payload: ContentCategories[]) => {
      state.contentCategories = payload;
      state.contentCategoriesLoading = false;
    },
    contentCategoriesFail: (state) => {
      state.contentCategoriesLoading = false;
    },
    cookieContentRequest: (state) => {
      state.cookieContentLoading = true;
    },
    cookieContentSuccess: (state, payload: Content) => {
      state.cookieContent = payload;
      state.cookieContentLoading = false;
    },
    cookieContentFail: (state) => {
      state.cookieContentLoading = false;
    },
    resetCookieContent: (state) => {
      state.cookieContent = {} as Content;
    },
    setIsCmsMenu: (state, payload: boolean) => {
      state.isCmsMenu = payload;
    },

    setAszf: (state, payload: string) => {
      state.aszf = payload;
    },
    setContactCategory: (state, payload: string) => {
      state.contactCategory = payload;
    },
    setDataProtection: (state, payload: string) => {
      state.dataProtection = payload;
    },
  },
  actions: {
    setHeaderVisibility: ({ commit }, payload: boolean) => {
      commit("setHeader", payload);
    },
    setShowOnLogin: ({ commit }, payload: boolean) => {
      commit("setShowOnLogin", payload);
    },
    setShowBasketIcon: ({ commit }, payload: boolean) => {
      commit("showBasketIcon", payload);
    },
    getTenantData: ({ commit }) => {
      commit("getTenantDataRequest");
      return CmsServiceApi.getTenantData()
        .then((response) => {
          commit("tenantDataSuccess", response);
        })
        .catch((error) => {
          commit("tenantDataFail");
          throw Error(error);
        });
    },
    getCookieContent: async ({ commit, rootState }) => {
      const cookieId = keyvalueFacade.getCookieId()?.value;
      if (!!cookieId as boolean) {
        commit("cookieContentRequest");
        const categoryContent = await cmsServiceApi.getContents({
          take: 1,
          skip: 0,
          filter: {
            contentCategoryId: cookieId,
            languageCode: rootState.currentLanguage as string,
          },
        });
        if (!categoryContent.data.length) {
          commit("cookieContentFail");
          return Promise.resolve(false);
        }
        const content = await cmsServiceApi.contentBySlug(
          categoryContent.data[0].slug
        );
        commit("cookieContentSuccess", content);
        return Promise.resolve(true);
      } else {
        return Promise.resolve(false);
      }
    },
    getCreditCardIcons: ({ commit }) => {
      const mediaCollectionId =
        keyvalueFacade.getCardIconsMediaCollectionId()?.value;
      if (mediaCollectionId != null) {
        commit("getCreditCardIconsRequest");
        return cmsServiceApi
          .getCreditCardIcons(mediaCollectionId)
          .then((response) => {
            commit(
              "creditCardIconsSuccess",
              mediaTransformer.transformCreditCardIcons(response.items)
            );
          })
          .catch((error) => {
            commit("creditCardIconsFail");
            throw Error(error);
          });
      }
    },
    getMenuItems: ({ commit, rootState }) => {
      commit("getMenuItemsRequest");
      return cmsServiceApi
        .getMenuItems(rootState.currentLanguage as string)
        .then((response) => {
          commit("menuItemsSuccess", response);
          return response;
        })
        .catch((error) => {
          commit("menuItemsFail");
          throw Error(error);
        });
    },
    resetInformationPanelContent: ({ commit }) => {
      commit("resetInformationPanelContent");
    },
    getInformationPanelContent: ({ commit }) => {
      const keyValue = keyvalueFacade.getInformationPanelId()?.value;
      return cmsServiceApi
        .getInformationPanelContent(keyValue as string)
        .then((res) => {
          const data = res.data[0];
          commit("setInformationPanelContent", data);
          return [data];
        });
    },
    getMainPageContent: ({ commit }) => {
      commit("getMainPageRequest");
      return cmsServiceApi
        .getContentsByContentCategoryId(keyvalueFacade.getMainPageId())
        .then((response: CmsTableData) => {
          const featuredContents = response.data.filter(
            (item) => item.featuredContentCalculated
          );

          const mainPageContent = response.data
            .filter((item) => !item.featuredContentCalculated)
            .pop();

          const pageBodyPromises = [];
          if (featuredContents != null) {
            for (let i = 0; i < featuredContents.length; i++) {
              pageBodyPromises.push(
                cmsServiceApi.getContentBodyBySlug(featuredContents[i].slug)
              );
            }
          }
          if (mainPageContent != null) {
            pageBodyPromises.push(
              cmsServiceApi.getContentBodyBySlug(mainPageContent.slug)
            );
          }
          return Promise.all(pageBodyPromises);
        })
        .then(([...contents]) => {
          for (let i = 0; i < contents.length; i++) {
            if (contents[i].featuredContentCalculated) {
              commit("featuredBodySuccess", contents[i]);
            } else {
              commit("mainPageSuccess", contents[i]);
            }
          }
          commit("getMainPageSuccess");
        })
        .catch((error) => {
          commit("getMainPageFail");
          throw Error(error);
        });
    },
    resetMainPageContent: ({ commit }) => {
      commit("resetMainPageContent");
    },
    getCountries: ({ commit }) => {
      commit("countriesRequest");
      return PublicServiceApi.mostCommonCountries()
        .then((response) => {
          commit("countriesSuccess", response);
        })
        .catch((error) => {
          commit("countriesFail");
          throw Error(error);
        });
    },
    getPublicPlaces: ({ commit }) => {
      commit("publicPlacesRequest");
      return PublicServiceApi.publicPlaces()
        .then((response) => {
          commit("publicPlacesSuccess", response);
        })
        .catch((error) => {
          commit("publicPlacesFail");
          throw Error(error);
        });
    },
    setCurrentPageMenuId: ({ commit }, menuId: string | undefined) => {
      commit("currentPageMenuId", menuId);
    },
    setLinkData: ({ commit }) => {
      const lang =
        localStorage.getItem("currLang") ||
        (commonFacade.currentLanguage() as string);

      const aszf = `${process.env.VUE_APP_NEMZETI_UTDIJ}${
        keyvalueFacade.getAszf(lang)?.value
      }`;
      const dataProtection = `${process.env.VUE_APP_NEMZETI_UTDIJ}${
        keyvalueFacade.getDataProtection(lang)?.value
      }`;
      const contactCategory = `${process.env.VUE_APP_NEMZETI_UTDIJ}${
        keyvalueFacade.getContactCategory(lang)?.value
      }`;
      commit("setAszf", aszf);
      commit("setContactCategory", contactCategory);
      commit("setDataProtection", dataProtection);
    },
    getContentCategories: ({ commit }) => {
      commit("contentCategoriesRequest");
      return cmsServiceApi
        .getContentCategories()
        .then((slug) => {
          commit("contentCategoriesSuccess", slug);
        })
        .catch((error) => {
          commit("contentCategoriesFail");
          throw Error(error);
        });
    },
    getContactContent: async ({ commit, dispatch, rootState, getters }) => {
      try {
        commit("contactContentRequest");
        if (!getters.getContactCategoryId) {
          await dispatch("getContentCategories");
        }
        const categoryContent = await cmsServiceApi.getContents({
          take: 1,
          skip: 0,
          filter: {
            contentCategoryId: getters.getContactCategoryId,
            languageCode: rootState.currentLanguage as string,
          },
        });
        if (!categoryContent.data.length) {
          commit("contactContentFail");
          return;
        }
        const content = await cmsServiceApi.contentBySlug(
          categoryContent.data[0].slug
        );
        commit("contactContentSuccess", content);
      } catch (error) {
        commit("contactContentFail");
        throw Error(error as string);
      }
    },
    setPageTitle: ({ commit }, payload: string) => {
      commit("setPageTitle", payload);
    },
    setIsCmsMenu: ({ commit }, payload: boolean) => {
      commit("setIsCmsMenu", payload);
    },
  },
  getters: {
    flattedMenu: (state) =>
      Object.values(state.menuItems).reduce(
        (acc: MenuItem[], curr: MenuItem[]): MenuItem[] => {
          return [...acc, ...curr.flat()];
        },
        []
      ),
    getContactCategoryId: (state) =>
      state.contentCategories.find(
        (category) => category.name === CategoryNames.CONTENT
      )?.id,
    languageCodes: (state) =>
      state.tenantData.languages.reduce(
        (acc: string[], curr: CmsLanguage): string[] => {
          return [...acc, curr.code];
        },
        []
      ),
  },
};
