




















































































import {
  computed,
  defineComponent,
  reactive,
  onUnmounted,
} from "@vue/composition-api";
import { userFacade } from "@/store/modules/user/user.facade";
import { LoginRequest } from "@/core/dataTypes/types";
import { cryptoSHA1 } from "@/core/utils/crypto/cryptoHelpers";
import { dialogCloseEmitter } from "@/core/utils/dialog/dialogEmitter";
import TextInput from "@/components/forms/input/TextInput.vue";
import {
  getRecaptchaToken,
  showRecaptchaLogo,
  hideRecaptchaLogo,
} from "@/core/utils/captcha";
import Alert from "@/components/alert/Alert.vue";
import { alertFacade } from "@/store/modules/alert/alert.facade";

export default defineComponent({
  name: "LoginDialog",
  components: {
    TextInput,
    Alert,
  },
  setup(props, ctx) {
    const alert = computed(alertFacade.alert);
    const loginLoading = computed(userFacade.loginLoading);
    const logoutLoading = computed(userFacade.logoutLoading);
    const campaignMessageLoading = computed(
      userFacade.campaignMessageListLoading
    );
    const formValues = reactive({
      password: "",
      email: "",
    });

    alertFacade.resetAlert();

    const login = () => {
      alertFacade.resetAlert();
      getRecaptchaToken("login").then((token: string) => {
        const form: LoginRequest = {
          login: {
            password: cryptoSHA1(formValues.password),
            login: formValues.email,
          },
          recaptchaResponseToken: token,
        };
        userFacade
          .login({ requestData: form, firstLogin: false })
          .then(() => dialogCloseEmitter(ctx));
      });
    };

    const logout = () => {
      alertFacade.resetAlert();
      userFacade.logout();
      dialogCloseEmitter(ctx);
    };
    showRecaptchaLogo();
    onUnmounted(() => {
      hideRecaptchaLogo();
    });

    return {
      formValues,
      loginLoading,
      logoutLoading,
      campaignMessageLoading,
      login,
      logout,
      alert,
    };
  },
});
