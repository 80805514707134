import { AlertData } from "@/core/dataTypes/types";
import { AppState } from "@/store";
import { Module } from "vuex";
export interface AlertState {
  alert: AlertData | undefined;
  visibleInDialog: boolean;
}

export const initialAlertState: AlertState = {
  alert: undefined,
  visibleInDialog: false,
};

export const alertModule: Module<AlertState, AppState> = {
  namespaced: true,
  state: initialAlertState,
  mutations: {
    setAlert: (state, alert: AlertData) => {
      state.alert = alert;
    },
    resetAlert: (state) => {
      state.alert = undefined;
    },
    setAlertVisibility: (state, payload: boolean) => {
      state.visibleInDialog = payload;
    },
  },
  actions: {
    setAlert: ({ commit }, payload: AlertData) => {
      window.scrollTo(0, 0);
      commit("setAlert", payload);
    },
    resetAlert: ({ commit }) => {
      commit("resetAlert");
    },
    setAlertVisibility: ({ commit }, payload: boolean) => {
      commit("setAlertVisibility", payload);
    },
  },
};
