/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CountryCode, MostCommonCountryCodeResponse } from "../dataTypes/types";

export const transformCountryCodes = (
  data: MostCommonCountryCodeResponse
): CountryCode[] => {
  const countryData = data.mostCommonCountryCodeList?.mostCommonCountryCode;
  /*const firstCountryCode = "H";
  const firstCountryElement = countryData?.filter(
    (item) => item.countryCode === firstCountryCode
  ) as any;
  countryData = data.mostCommonCountryCodeList?.mostCommonCountryCode?.filter(
    (item) => item.countryCode !== firstCountryCode
  );
  countryData.unshift(firstCountryElement[0]);*/
  return countryData?.map((country: CountryCode) => {
    return {
      ...country,
      fullName: `${country.countryCode} - ${country.name}`,
    };
  });
};
