































































































import { computed, defineComponent, reactive } from "@vue/composition-api";
import TextInput from "@/components/forms/input/TextInput.vue";
import { ERoutes } from "@/core/dataTypes/enum";
import { LoginRequest } from "@/core/dataTypes/types";
import { userFacade } from "@/store/modules/user/user.facade";
import { alertFacade } from "@/store/modules/alert/alert.facade";
import { cryptoSHA1 } from "@/core/utils/crypto/cryptoHelpers";
import { i18n } from "@/locales/i18n";
import { tabFocusService } from "@/core/services/tabFocus.service";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";

export default defineComponent({
  name: "Login",
  components: {
    TextInput,
  },
  setup() {
    tabFocusService.setFocus("nuszBack");
    const loginLoading = computed(userFacade.loginLoading);
    const adHocValidityCheckLoading = computed(
      stickerFacade.adHocValidityCheckLoading
    );
    const alert = computed(alertFacade.alert);
    const campaignMessageLoading = computed(
      userFacade.campaignMessageListLoading
    );
    const formValues = reactive({
      email: "",
      password: "",
    });
    const login = () => {
      const form: LoginRequest = {
        login: {
          password: cryptoSHA1(formValues.password),
          login: formValues.email,
        },
      };
      userFacade.login({ requestData: form, firstLogin: true });
    };
    return {
      formValues,
      loginLoading,
      alert,
      campaignMessageLoading,
      adHocValidityCheckLoading,
      ERoutes,
      login,
    };
  },
  metaInfo() {
    return {
      titleTemplate: `${i18n.tc("LOGINPAGE.TITLE")} | %s`,
    };
  },
});
