













import { computed, defineComponent, watch } from "@vue/composition-api";
import CampaignMessageComponent from "@/views/CampaignPage/CampaignMessage/CampaignMessage.vue";
import { userFacade } from "@/store/modules/user/user.facade";
import router from "@/router";
import { ERoutes } from "@/core/dataTypes/enum";
import { getLanguageStaticRoute } from "@/core/utils/router.util";

export default defineComponent({
  name: "CampaignPage",
  components: {
    CampaignMessageComponent,
  },
  setup() {
    const campaignMessageList = computed(userFacade.campaignMessageList);
    const campaignMessageListLoading = computed(
      userFacade.campaignMessageListLoading
    );

    watch(
      () => campaignMessageList.value,
      (currentValue) => {
        if (!currentValue?.length) {
          router.push(getLanguageStaticRoute(ERoutes.DASHBOARD));
        }
      }
    );

    return {
      campaignMessageList,
      campaignMessageListLoading,
    };
  },
});
