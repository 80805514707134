import { NavigationGuardNext, Route } from "vue-router";
import { EAlertType, ERoutes } from "../dataTypes/enum";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import { userFacade } from "@/store/modules/user/user.facade";
import * as StickerService from "@/core/services/sticker.service";
import { getLanguageStaticRoute } from "@/core/utils/router.util";
import { alertFacade } from "@/store/modules/alert/alert.facade";
import { i18n } from "@/locales/i18n";

export const stickerPayGuard = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
): void => {
  const notLoggedInUser = userFacade.notLoggedInUser();
  const cartId = !notLoggedInUser
    ? stickerFacade.cartId()
    : StickerService.getAdHocCartId();
  switch (true) {
    case notLoggedInUser && !!cartId:
      stickerFacade.getAdHocValidityCheck().then(() => {
        if (!stickerFacade.validAdHocId()) {
          setTimeout(() => {
            alertFacade.setAlert({
              message: i18n.t(`HTTP_ERRORS.INVALID_ADHOC_ID`) as string,
              type: EAlertType.ERROR,
            });
          });

          next(
            getLanguageStaticRoute(
              `${ERoutes.STICKER_PURCHASE}/${ERoutes.STICKER_CREATION}`
            )
          );
        } else {
          next();
        }
      });
      break;
    case !notLoggedInUser && !!cartId:
      stickerFacade.getActiveCart().then((response) => {
        if (response.cartId) {
          next();
        } else {
          next(
            getLanguageStaticRoute(
              `${ERoutes.STICKER_PURCHASE}/${ERoutes.STICKER_CREATION}`
            )
          );
        }
      });
      break;

    default:
      if (cartId) {
        next();
      } else {
        next(
          getLanguageStaticRoute(
            `${ERoutes.STICKER_PURCHASE}/${ERoutes.STICKER_CREATION}`
          )
        );
      }
  }
};
