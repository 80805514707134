/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  MostCommonCountryResponseType,
  PublicPlacesResponseType,
} from "../dataTypes/types";

export const transformMostCommonContries = (
  data: any
): MostCommonCountryResponseType[] =>
  data && data.mostCommonCountryList?.mostCommonCountry;

export const transformPublicPlaces = (data: any): PublicPlacesResponseType[] =>
  data && data.publicPlaceList?.publicPlace;
