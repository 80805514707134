import {
  MostCommonCountryResponseType,
  PublicPlacesResponseType,
  ValidResponseType,
} from "../dataTypes/types";
import httpService from "../services/http.service";
import {
  transformMostCommonContries,
  transformPublicPlaces,
} from "../transformers/publicService.transformer";

export const getValidZIPCode = (
  countryId: string
): Promise<ValidResponseType> =>
  httpService
    .get<never>(`/PublicService/public/getValidZIPCode/${countryId}`)
    .then((response) => response.data);

export const getValidVATNumber = (
  countryId: string
): Promise<ValidResponseType> =>
  httpService
    .get<never>(`PublicService/public/getValidVATNumber/${countryId}`)
    .then((response) => response.data);

export const mostCommonCountries = (): Promise<
  MostCommonCountryResponseType[]
> =>
  httpService
    .get("/PublicService/public/countries/mostCommon")
    .then((response) => transformMostCommonContries(response.data));

export const publicPlaces = (): Promise<PublicPlacesResponseType[]> =>
  httpService
    .get("/PublicService/public/publicPlaces")
    .then((response) => transformPublicPlaces(response.data));
