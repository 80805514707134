
























import {
  defineComponent,
  reactive,
  watch,
  onUnmounted,
} from "@vue/composition-api";
import {
  lowerUpperCaseCharacter,
  minCharacter,
  numberCharacter,
  specialCharacter,
} from "@/core/utils/validation/customValidations";

import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "PasswordValidator",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const passwordRules = reactive([
      {
        id: 1,
        title: i18n.tc("PASSWORD_VALIDATOR.RULE1"),
        class: "checkbox-blank-circle-outline",
        validator: minCharacter,
        isValid: false,
      },
      {
        id: 2,
        title: i18n.tc("PASSWORD_VALIDATOR.RULE4"),
        class: "checkbox-blank-circle-outline",
        validator: specialCharacter,
        isValid: false,
      },
      {
        id: 3,
        title: i18n.tc("PASSWORD_VALIDATOR.RULE3"),
        class: "checkbox-blank-circle-outline",
        validator: numberCharacter,
        isValid: false,
      },
      {
        id: 4,
        title: i18n.tc("PASSWORD_VALIDATOR.RULE2"),
        class: "checkbox-blank-circle-outline",
        validator: lowerUpperCaseCharacter,
        isValid: false,
      },
    ]);
    const stopWatch = watch(
      () => props.value,
      (value: string) => {
        passwordRules.forEach((item) => {
          const validationResult = item.validator(value);
          item.isValid = validationResult;
          item.class =
            validationResult && typeof validationResult === "boolean"
              ? "checkbox-marked-circle valid"
              : "close-circle invalid";
          if (!value.length) {
            item.isValid = false;
            item.class = "checkbox-blank-circle-outline";
          }
        });
      }
    );

    onUnmounted(() => {
      stopWatch();
    });
    return {
      passwordRules,
    };
  },
});
