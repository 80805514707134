import { commonFacade } from "@/store/modules/common/common.facade";
import { NavigationGuardNext, Route } from "vue-router";
import { userFacade } from "@/store/modules/user/user.facade";
import AuthService from "@/core/services/auth.service";

export const languageGuard = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
): void => {
  const lang = to.params.lang;
  const hasLang = commonFacade.languageCodes().includes(lang);
  const params = to.params;
  const isNotLoggedInUser = userFacade.notLoggedInUser();
  const isLoggedInUser = AuthService.hasValidToken();
  if (isNotLoggedInUser && isLoggedInUser) {
    window.location.href = to.path;
    // window.location.href = "/" + process.env.VUE_APP_I18N_LOCALE;
    next(false);
    return;
  }
  if (hasLang && commonFacade.currentLanguage() !== lang) {
    commonFacade.setCurrentLanguage(lang);
  }
  if (hasLang) {
    commonFacade.setIsCmsMenu(!!params.slug1);
    next();
  } else {
    setTimeout(() => {
      window.location.href = "/" + process.env.VUE_APP_I18N_LOCALE;
    }, 50);
  }
};
