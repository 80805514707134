























































import { defineComponent, onUnmounted, ref, watch } from "@vue/composition-api";

export default defineComponent({
  name: "BillingTypeSelector",
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      default: 0,
    },
    id: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const picked = ref(props.value);
    const stopWatch = watch(
      () => props.value,
      (newVal, oldVal) => {
        if (oldVal !== newVal) {
          picked.value = newVal;
        }
      }
    );
    const onChange = () => {
      emit("input", picked);
    };
    onUnmounted(() => {
      stopWatch();
    });
    return {
      picked,
      onChange,
    };
  },
});
