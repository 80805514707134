




















import { defineComponent, Ref, ref } from "@vue/composition-api";
import { CmsMediaCollectionItem } from "@/core/dataTypes/types/cmsService.interface";
import { cmsServiceApi } from "@/core/api/cmsService.api";

export default defineComponent({
  name: "DocumentList",
  props: {
    dataListId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const dataList: Ref<CmsMediaCollectionItem[]> = ref([]);
    const caption = ref("");

    cmsServiceApi
      .getMediaCollection(process.env.VUE_APP_TENANT, props.dataListId)
      .then((response) => {
        caption.value = response.name;
        dataList.value = response.items;
      });

    return {
      caption,
      dataList,
    };
  },
});
