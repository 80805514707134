import { RegistrationFormState } from "@/core/dataTypes/types";
import store from "@/store";

const registrationForm = (): Partial<RegistrationFormState> | undefined =>
  store.state.registration?.registrationForm;
const saveRegistrationForm = (
  form: Partial<RegistrationFormState>
): Promise<void> => store.dispatch("registration/saveRegistrationForm", form);
const resetRegistrationForm = (): Promise<void> =>
  store.dispatch("registration/resetRegistrationForm");

const registrationLoading = (): boolean | undefined =>
  store.state.registration?.registrationLoading;
const registration = (): Promise<void> =>
  store.dispatch("registration/registration");

export const registrationFacade = {
  registrationForm,
  saveRegistrationForm,
  resetRegistrationForm,
  registrationLoading,
  registration,
};
