
































































































































import { computed, defineComponent, reactive } from "@vue/composition-api";
import TextInput from "@/components/forms/input/TextInput.vue";
import PasswordValidator from "@/components/passwordValidator/PasswordValidator.vue";
import { PasswordChangeRequest } from "@/core/dataTypes/types";
import { userFacade } from "@/store/modules/user/user.facade";
import { ERoutes } from "@/core/dataTypes/enum";
import { cryptoSHA1 } from "@/core/utils/crypto/cryptoHelpers";
import { getRecaptchaToken } from "@/core/utils/captcha";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "ChangePassword",
  components: {
    TextInput,
    PasswordValidator,
  },
  setup() {
    document.title = i18n.tc("CHANGE_PASSWORD_PAGE.TITLE");
    const changePasswordForm = reactive({
      currentPassword: "",
      newPassword: "",
      newPasswordAgain: "",
    });
    const changePasswordLoading = computed(userFacade.changePasswordLoading);

    const changePassword = () => {
      getRecaptchaToken("changePassword").then(
        (recaptchaResponseToken: string) => {
          const requestData: PasswordChangeRequest = {
            oldPassword: cryptoSHA1(changePasswordForm.currentPassword),
            newPassword: cryptoSHA1(changePasswordForm.newPassword),
            recaptchaResponseToken,
          };
          userFacade.changePassword(requestData);
        }
      );
    };

    return {
      changePasswordForm,
      changePasswordLoading,
      ERoutes,
      changePassword,
    };
  },
});
