import { i18n } from "@/locales/i18n";
export const ERoutes = {
  ADD_NEW_BILLING_ADDRESS: i18n.tc("ROUTER.ADD_NEW_BILLING_ADDRESS"),
  CAMPAIGN: i18n.tc("ROUTER.CAMPAIGN"),
  CHANGE_EMAIL: i18n.tc("ROUTER.CHANGE_EMAIL"),
  CHANGE_EMAIL_ACTIVATION: i18n.tc("ROUTER.CHANGE_EMAIL_ACTIVATION"),
  CHANGE_EMAIL_ACTIVATION_EMAIL_FAILED: i18n.tc(
    "ROUTER.CHANGE_EMAIL_ACTIVATION_EMAIL_FAILED"
  ),
  CHANGE_EMAIL_ACTIVATION_EMAIL_SENT: i18n.tc(
    "ROUTER.CHANGE_EMAIL_ACTIVATION_EMAIL_SENT"
  ),
  CHANGE_EMAIL_TOKEN_VALIDATION: i18n.tc(
    "ROUTER.CHANGE_EMAIL_TOKEN_VALIDATION"
  ),
  CHANGE_PASSWORD: i18n.tc("ROUTER.CHANGE_PASSWORD"),
  CHANGE_PROFILE_DETAILS: i18n.tc("ROUTER.CHANGE_PROFILE_DETAILS"),
  DASHBOARD: i18n.tc("ROUTER.DASHBOARD"),
  EMPTY_CART: i18n.tc("ROUTER.EMPTY_CART"),
  EXPIRED_PASSWORD: i18n.tc("ROUTER.EXPIRED_PASSWORD"),
  FORGOTTEN_PASSWORD: i18n.tc("ROUTER.FORGOTTEN_PASSWORD"),
  FORGOTTEN_PASSWORD_EMAIL_SENT: i18n.tc(
    "ROUTER.FORGOTTEN_PASSWORD_EMAIL_SENT"
  ),
  LOGIN: i18n.tc("ROUTER.LOGIN"),
  MAIN_PAGE: i18n.tc("ROUTER.MAIN_PAGE"),
  MODIFY_BILLING_ADDRESS: i18n.tc("ROUTER.MODIFY_BILLING_ADDRESS"),
  MY_PURCHASES: i18n.tc("ROUTER.MY_PURCHASES"),
  NEW_PASSWORD: i18n.tc("ROUTER.NEW_PASSWORD"),
  PROFILE: i18n.tc("ROUTER.PROFILE"),
  REGISTRATION: i18n.tc("ROUTER.REGISTRATION"),
  REGISTRATION_ACTIVATION: i18n.tc("ROUTER.REGISTRATION_ACTIVATION"),
  REGISTRATION_SENT: i18n.tc("ROUTER.REGISTRATION_SENT"),
  STICKER_CREATION: i18n.tc("ROUTER.STICKER_CREATION"),
  STICKER_PAY: i18n.tc("ROUTER.STICKER_PAY"),
  STICKER_PURCHASE: i18n.tc("ROUTER.STICKER_PURCHASE"),
  STICKER_SUCCESS: i18n.tc("ROUTER.STICKER_SUCCESS"),
  STICKER_VALID: i18n.tc("ROUTER.STICKER_VALID"),
  VEHICLE: i18n.tc("ROUTER.VEHICLE"),
  VEHICLE_NEW: i18n.tc("ROUTER.VEHICLE_NEW"),
};
// console.log('DATA');
// export enum ERoutes {
//   DASHBOARD = "dashboard",
//   MAIN_PAGE = "fooldal",
//   LOGIN = "belepes",
//   EXPIRED_PASSWORD = "lejart-jelszo",
//   FORGOTTEN_PASSWORD = "elfelejtett-jelszo",
//   NEW_PASSWORD = "uj-jelszo",
//   REGISTRATION = "regisztracio",
//   FORGOTTEN_PASSWORD_EMAIL_SENT = "email-elkuldve",
//   REGISTRATION_SENT = "regisztracio-elkuldve",
//   REGISTRATION_ACTIVATION = "aktivalas",
//   CHANGE_PASSWORD = "jelszo-modositas",
//   PROFILE = "profil",
//   CHANGE_PROFILE_DETAILS = "profileadatok-modositasa",
//   ADD_NEW_BILLING_ADDRESS = "uj-szamlazasi-cim",
//   MODIFY_BILLING_ADDRESS = "szamlazasi-cim-modositasa",
//   CHANGE_EMAIL = "e-mail-cim-modositasa",
//   CHANGE_EMAIL_TOKEN_VALIDATION = "email-aktivalas",
//   CHANGE_EMAIL_ACTIVATION = "email-aktivalas-jelszo",
//   CHANGE_EMAIL_ACTIVATION_EMAIL_SENT = "e-mail-cim-modositas-elkuldve",
//   CHANGE_EMAIL_ACTIVATION_EMAIL_FAILED = "sikertelen-e-mail-cim-aktivalas",
//   CAMPAIGN = "kampany",
//   VEHICLE = "jarmukezeles",
//   VEHICLE_NEW = "uj-jarmu",
//   STICKER_VALID = "ervenyes-matricak",
//   STICKER_PURCHASE = "matricavasarlas",
//   STICKER_CREATION = "jarmuadatok-matricavalasztas",
//   STICKER_PAY = "osszegzes-fizetes",
//   EMPTY_CART = "ures-kosar",
//   STICKER_SUCCESS = "sikeres-vasarlas",
//   MY_PURCHASES = "vasarlasaim",
// }
