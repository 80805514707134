import { NavigationGuardNext, Route } from "vue-router";
import { ERoutes } from "../dataTypes/enum";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import { userFacade } from "@/store/modules/user/user.facade";
import * as StickerService from "@/core/services/sticker.service";
import { getLanguageStaticRoute } from "@/core/utils/router.util";

export const stickerEmptyGuard = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
): void => {
  const notLoggedInUser = userFacade.notLoggedInUser();
  const hasCampaignMessage = !!userFacade.campaignMessageList()?.length;
  const cartId = !notLoggedInUser
    ? stickerFacade.cartId()
    : StickerService.getAdHocCartId();
  switch (true) {
    case hasCampaignMessage:
      next(false);
      break;
    case cartId:
      next(
        getLanguageStaticRoute(
          `${ERoutes.STICKER_PURCHASE}/${ERoutes.STICKER_PAY}`
        )
      );
      break;
    default:
      next();
  }
  /*const notLoggedInUser = userFacade.notLoggedInUser();
  const cartId = !notLoggedInUser
    ? stickerFacade.cartId()
    : StickerService.getAdHocCartId();
  if (cartId) {
    next(
      getLanguageStaticRoute(
        `${ERoutes.STICKER_PURCHASE}/${ERoutes.STICKER_PAY}`
      )
    );
  } else {
    next();
  }*/
};
