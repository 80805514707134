/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getVehiclePriceCategoryIcon } from "@/core/utils/vehicle";
import {
  HdCustomerVehicle,
  HdCustomerVehicleListResponse,
} from "@/core/dataTypes/types";

export const transformVehicleList = (
  data: HdCustomerVehicleListResponse
): HdCustomerVehicleListResponse => {
  return {
    ...data,
    hdCustomerVehicleList: {
      // hdCustomerVehicleList: [],
      hdCustomerVehicleList:
        data.hdCustomerVehicleList.hdCustomerVehicleList.map(
          (item: HdCustomerVehicle) => {
            return {
              ...item,
              icon: getVehiclePriceCategoryIcon(item?.hdVehicleCategoryId),
            };
          }
        ),
    },
  };
};
