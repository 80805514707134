import {
  MostCommonCountryResponseType,
  PublicPlacesResponseType,
} from "@/core/dataTypes/types";
import store from "@/store";
import { transformCmsLanguages } from "@/core/transformers/cmsServiceTransformers/language.transformer";
import { HeaderLanguage } from "@/core/dataTypes/types/common.interface";
import {
  CmsMedia,
  Content,
  Menu,
  MenuItem,
  TenantData,
} from "@/core/dataTypes/types/cmsService.interface";

const showHeader = (): boolean | undefined => store.state.common?.showHeader;
const showOnLogin = (): boolean | undefined => store.state.common?.showOnLogin;
const showOnBasketIcon = (): boolean | undefined =>
  store.state.common?.showBasketIcon;

const setHeaderVisibility = (payload: boolean): Promise<void> =>
  store.dispatch("common/setHeaderVisibility", payload);

const getTenantData = (): Promise<void> =>
  store.dispatch("common/getTenantData");
const getCreditCardIcons = (): Promise<void> =>
  store.dispatch("common/getCreditCardIcons");

const getLanguages = (): HeaderLanguage[] => {
  if (store.state.common?.tenantData?.languages != null) {
    return transformCmsLanguages(store.state.common?.tenantData?.languages);
  } else {
    return [];
  }
};

const currentPageMenuId = (): string | undefined =>
  store.state.common?.currentPageMenuId;
const setCurrentPageMenuId = (menuId: string | undefined): Promise<void> =>
  store.dispatch("common/setCurrentPageMenuId", menuId);
const setPageTitle = (pageTitle: string): Promise<void> =>
  store.dispatch("common/setPageTitle", pageTitle);

const getMenuItems = (): Promise<void> => store.dispatch("common/getMenuItems");
const getMainPageContent = (): Promise<void> =>
  store.dispatch("common/getMainPageContent");

const menuItems = (): Menu | null | undefined => store.state.common?.menuItems;
const tenantData = (): TenantData | null | undefined =>
  store.state.common?.tenantData;
const pageTitle = (): string | undefined => store.state.common?.pageTitle;
const tenantLogo = (): string | null | undefined =>
  store.state.common?.tenantData?.logo?.url;
const creditCardsIcons = (): CmsMedia[] | null | undefined =>
  store.state.common?.creditCardsIcons;
const creditCardsIconsLoading = (): boolean | undefined =>
  store.state.common?.creditCardsIconsLoading;
const tenantDataLoading = (): boolean | null | undefined =>
  store.state.common?.tenantDataLoading;
const menuItemsLoading = (): boolean | undefined =>
  store.state.common?.menuItemsLoading;
const mainPageLoading = (): boolean | undefined =>
  store.state.common?.mainPageLoading;
const flattedMenu = (): MenuItem[] => store.getters["common/flattedMenu"];
const languageCodes = (): string[] => store.getters["common/languageCodes"];
const mainPageBody = (): Content[] | undefined =>
  store.state.common?.mainPageBody;
const featuredNewsBody = (): Content[] | undefined =>
  store.state.common?.featuredNewsBody;
const setShowOnLogin = (payload: boolean): Promise<void> =>
  store.dispatch("common/setShowOnLogin", payload);
const resetMainPageContent = (): Promise<void> =>
  store.dispatch("common/resetMainPageContent");
const setShowBasketIcon = (payload: boolean): Promise<void> =>
  store.dispatch("common/setShowBasketIcon", payload);
const setIsCmsMenu = (payload: boolean): Promise<void> =>
  store.dispatch("common/setIsCmsMenu", payload);
const isCmsMenu = (): boolean | undefined => store.state.common?.isCmsMenu;
const countries = (): MostCommonCountryResponseType[] | undefined =>
  store.state.common?.countries;
const countriesLoading = (): boolean | undefined =>
  store.state.common?.countriesLoading;
const getCountries = (): Promise<void> => store.dispatch("common/getCountries");

const publicPlaces = (): PublicPlacesResponseType[] | undefined =>
  store.state.common?.publicPlaces;
const publicPlacesLoading = (): boolean | undefined =>
  store.state.common?.publicPlacesLoading;
const getPublicPlaces = (): Promise<void> =>
  store.dispatch("common/getPublicPlaces");
const currentLanguage = (): string | undefined => store.state.currentLanguage;
const setCurrentLanguage = (languageCode: string): Promise<void> =>
  store.dispatch("setCurrentLanguage", languageCode);

const contactContent = (): Content | undefined =>
  store.state.common?.contactContent;
const contactContentLoading = (): boolean | undefined =>
  store.state.common?.contactContentLoading;
const getContactContent = (): Promise<void> =>
  store.dispatch("common/getContactContent");
const getContactCategoryId = (): string | undefined =>
  store.getters["common/getContactCategoryId"];
const getCookieContent = (): Promise<boolean> =>
  store.dispatch("common/getCookieContent");
const setLinkData = (): Promise<void> => store.dispatch("common/setLinkData");
const cookieContent = (): Content | undefined =>
  store.state.common?.cookieContent;
const cookieContentLoading = (): boolean | undefined =>
  store.state.common?.cookieContentLoading;
const aszf = (): string | undefined => store.state.common?.aszf;
const contactCategory = (): string | undefined =>
  store.state.common?.contactCategory;
const dataProtection = (): string | undefined =>
  store.state.common?.dataProtection;
export const commonFacade = {
  showOnLogin,
  setShowOnLogin,
  showHeader,
  setHeaderVisibility,
  countries,
  countriesLoading,
  getCountries,
  publicPlaces,
  publicPlacesLoading,
  getPublicPlaces,
  getTenantData,
  tenantData,
  tenantDataLoading,
  getMenuItems,
  menuItems,
  menuItemsLoading,
  currentLanguage,
  setCurrentLanguage,
  getLanguages,
  currentPageMenuId,
  setCurrentPageMenuId,
  setShowBasketIcon,
  flattedMenu,
  languageCodes,
  showOnBasketIcon,
  getMainPageContent,
  mainPageBody,
  featuredNewsBody,
  getCreditCardIcons,
  creditCardsIcons,
  creditCardsIconsLoading,
  tenantLogo,
  contactContent,
  contactContentLoading,
  getContactContent,
  getContactCategoryId,
  mainPageLoading,
  resetMainPageContent,
  setPageTitle,
  pageTitle,
  getCookieContent,
  cookieContent,
  cookieContentLoading,
  setIsCmsMenu,
  isCmsMenu,
  setLinkData,
  aszf,
  contactCategory,
  dataProtection,
};
