

















import { defineComponent, onMounted } from "@vue/composition-api";
import { userFacade } from "@/store/modules/user/user.facade";
import { ChangeEmailActivationTokenRequest } from "@/core/dataTypes/types";

export default defineComponent({
  name: "ChangeEmailTokenValidationPage",
  setup(props, { root }) {
    const tokenFromUrl = root.$route.query.token as string;
    const idFromUrl = root.$route.query.id as string;

    onMounted(() => {
      if (tokenFromUrl) {
        const request: ChangeEmailActivationTokenRequest = {
          customerNumberEncrypted: idFromUrl,
          token: tokenFromUrl,
        };
        userFacade.changeEmailToken(request);
      }
    });

    return {};
  },
});
