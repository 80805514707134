























































import TextInput from "@/components/forms/input/TextInput.vue";
import { defineComponent, reactive, computed } from "@vue/composition-api";
import { userFacade } from "@/store/modules/user/user.facade";
import { ERoutes } from "@/core/dataTypes/enum";
import { cryptoSHA1 } from "@/core/utils/crypto/cryptoHelpers";
import { getRecaptchaToken } from "@/core/utils/captcha";
import { ChangeEmailRequest } from "@/core/dataTypes/types";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "ChangeEmailActivationPage",
  components: {
    TextInput,
  },
  setup() {
    document.title = i18n.tc("CHANGE_EMAIL_ACTIVATION_EMAIL_PAGE.PAGE_TITLE");
    const changeEmailActivationLoading = computed(
      userFacade.changeEmailActivationLoading
    );
    const formValues = reactive({
      password: "",
    });

    const changeEmailActivation = () => {
      getRecaptchaToken("putChangeEmailActive").then(
        (recaptchaResponseToken: string) => {
          const requestData: ChangeEmailRequest = {
            recaptchaResponseToken,
            password: cryptoSHA1(formValues.password),
          };

          userFacade.putChangeEmailActive(requestData);
        }
      );
    };

    return {
      formValues,
      changeEmailActivation,
      changeEmailActivationLoading,
      ERoutes,
    };
  },
});
