import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { ERoutes } from "@/core/dataTypes/enum";
import { commonFacade } from "@/store/modules/common/common.facade";

let vueInstance: Vue;

// reCAPTCHA visible pages
const reCaptchaPageUsed = [
  ERoutes.LOGIN,
  ERoutes.FORGOTTEN_PASSWORD,
  ERoutes.NEW_PASSWORD,
  ERoutes.CHANGE_EMAIL,
  ERoutes.CHANGE_EMAIL_ACTIVATION,
  ERoutes.EXPIRED_PASSWORD,
  ERoutes.CHANGE_PASSWORD,
];

// reCAPTCHA init
export const initRecaptcha = (vm: Vue): void => {
  vueInstance = vm;
  visibleRecaptchaLogo();
};
// reCAPTCHA options
const reCaptchaOptions = {
  siteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
  loaderOptions: {
    autoHideBadge: false,
  },
};

// reCAPTCHA token
export const getRecaptchaToken = (action: string): Promise<string> => {
  return vueInstance.$recaptcha(action);
};

// reCAPTCHA logo hide
export const hideRecaptchaLogo = (): void => {
  vueInstance.$recaptchaInstance.hideBadge();
};

// reCAPTCHA logo show
export const showRecaptchaLogo = (): void => {
  vueInstance.$recaptchaInstance.showBadge();
};

// reCAPTCHA visible handler
export const visibleRecaptchaLogo = (): void => {
  const path =
    localStorage.getItem("currLang") || commonFacade.currentLanguage();
  const isMatchUrl = reCaptchaPageUsed.find(
    (page) => `/${path}/${page}` === vueInstance.$route.path
  );

  vueInstance.$recaptchaLoaded().then(() => {
    if (isMatchUrl) {
      showRecaptchaLogo();
    } else {
      hideRecaptchaLogo();
    }
  });
};

Vue.use(VueReCaptcha, reCaptchaOptions);
