






























































































































import { computed, defineComponent, reactive } from "@vue/composition-api";
import TextInput from "@/components/forms/input/TextInput.vue";
import { ERoutes } from "@/core/dataTypes/enum";
import { userFacade } from "@/store/modules/user/user.facade";
import { alertFacade } from "@/store/modules/alert/alert.facade";
import { ChangeProfileDetails, DialogResultType } from "@/core/dataTypes/types";
import CardSelector from "@/components/forms/CardSelector/CardSelector.vue";
import dialogService from "@/core/services/dialog.service";
import { i18n } from "@/locales/i18n";
import { languages } from "@/core/utils/languages";
export default defineComponent({
  name: "ChangeProfileDetailsPage",
  components: {
    TextInput,
    CardSelector,
  },
  setup() {
    document.title = i18n.tc("CHANGE_PROFILE_DETAILS_PAGE.PAGE_TITLE");
    const changeProfileDetailsLoading = computed(
      userFacade.changeProfileDetailsLoading
    );
    const deleteCustomerLoading = computed(userFacade.deleteCustomerLoading);
    const user = computed(userFacade.user);
    const alert = computed(alertFacade.alert);
    const formValues = reactive({
      customerName: user.value?.customerName,
      notificationPhone: user.value?.notificationPhone,
      language: user.value?.language,
    });
    const changeProfileDetails = () => {
      const form: ChangeProfileDetails = {
        customerId: "", //ezt majd az auth.service-ben töltjük ki
        customerName: formValues.customerName,
        language: formValues.language,
        ...(formValues?.notificationPhone && {
          notificationPhone: formValues?.notificationPhone,
        }),
      };
      userFacade.changeProfileDetails(form);
    };

    const onDeleteCustomer = (): void => {
      dialogService
        .confirmDialog({
          maxWidth: 424,
          componentProps: {
            title: i18n.tc("CHANGE_PROFILE_DETAILS_PAGE.VALUE.TITLE"),
            content: i18n.tc("CHANGE_PROFILE_DETAILS_PAGE.VALUE.CONTENT"),
            submitText: i18n.tc("CHANGE_PROFILE_DETAILS_PAGE.VALUE.SUBMITTEXT"),
            cancelText: i18n.tc("CHANGE_PROFILE_DETAILS_PAGE.VALUE.CANCELTEXT"),
          },
        })
        .$on("dialogResult", (result: DialogResultType): void => {
          if (result.payload) {
            userFacade.deleteCustomer();
          }
        });
    };

    return {
      formValues,
      changeProfileDetails,
      onDeleteCustomer,
      deleteCustomerLoading,
      alert,
      changeProfileDetailsLoading,
      ERoutes,
      user,
      languages,
    };
  },
});
