import store from "@/store";

const dialogOpen = (): string | undefined =>
  store.state.accessibility?.dialogOpen;
const setDialogOpen = (payload: boolean): Promise<void> =>
  store.dispatch("accessibility/setDialogOpen", payload);
const setTopDialogLevel = (payload: number): Promise<void> =>
  store.dispatch("accessibility/setTopDialogLevel", payload);
const topDialogLevel = (): number | undefined =>
  store.state.accessibility?.topDialogLevel;

export const accessibilityFacade = {
  dialogOpen,
  setDialogOpen,
  setTopDialogLevel,
  topDialogLevel,
};
