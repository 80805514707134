import {
  CustomerRegistrationRequestType,
  RegistrationFormState,
} from "@/core/dataTypes/types";
import { AppState } from "@/store";
import { Module } from "vuex";
import * as CustomerServiceApi from "@/core/api/customerService.api";
import { PartialDeep } from "type-fest";
import router from "@/router";
import { ERoutes } from "@/core/dataTypes/enum";
import { getLanguageStaticRoute } from "@/core/utils/router.util";

export interface RegistrationState {
  registrationForm: Partial<RegistrationFormState> | undefined;
  registrationLoading: boolean;
}

export const initialRegistrationState: RegistrationState = {
  registrationForm: undefined,
  registrationLoading: false,
};

export const registrationModule: Module<RegistrationState, AppState> = {
  namespaced: true,
  state: initialRegistrationState,
  mutations: {
    saveRegistrationForm: (state, payload: Partial<RegistrationFormState>) => {
      state.registrationForm = {
        ...state.registrationForm,
        ...payload,
      };
    },
    resetRegistrationForm: (state) => {
      state.registrationForm = undefined;
    },
    registrationRequest: (state) => {
      state.registrationLoading = true;
    },
    registrationSuccess: (state) => {
      state.registrationLoading = false;
    },
    registrationFail: (state) => {
      state.registrationLoading = false;
    },
  },
  actions: {
    saveRegistrationForm: (
      { commit },
      payload: Partial<RegistrationFormState>
    ) => {
      return commit("saveRegistrationForm", payload);
    },
    resetRegistrationForm: ({ commit }) => {
      commit("resetRegistrationForm");
    },
    registration: ({ commit, rootState }) => {
      commit("registrationRequest");
      const {
        email,
        password,
        customerName,
        notificationPhone,
        language,
        corporation,
        billingName,
        country,
        zipCode,
        city,
        street,
        streetType,
        streetNumber,
        floor,
        door,
        vatCountry,
        vatNumber,
        invoiceGroupId,
        euVatNumber,
        thirdCountryVatNumber,
      } = rootState.registration
        ?.registrationForm as Partial<RegistrationFormState>;
      const form: PartialDeep<CustomerRegistrationRequestType> = {
        basicRegistrationData: {
          email,
          password,
          customerName,
          ...(notificationPhone && { notificationPhone }),
          language,
        },
        billingAddress: {
          corporation: !!corporation,
          billingName,
          country,
          zipCode,
          city,
          street,
          streetType,
          streetNumber,
          ...(floor && { floor }),
          ...(door && { door }),
          ...(vatCountry && { vatCountry: vatCountry.id }),
          ...(vatNumber && { vatNumber }),
          ...(invoiceGroupId && { invoiceGroupId }),
          ...(euVatNumber && { euVatNumber }),
          ...(thirdCountryVatNumber && { thirdCountryVatNumber }),
        },
      };

      CustomerServiceApi.createCustomer(form)
        .then((response) => {
          commit("registrationSuccess");
          commit("user/setUser", response, { root: true });
          commit("resetRegistrationForm");
          router.push(getLanguageStaticRoute(ERoutes.REGISTRATION_SENT));
        })
        .catch((error) => {
          commit("registrationFail");
          throw Error(error);
        });
    },
  },
};
