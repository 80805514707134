var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex flex-column align-center fill-height main-content-padding",attrs:{"fluid":"","tag":"section"}},[_c('v-card',{staticClass:"pa-2 pa-sm-16 remove-card-shadow mt-sm-11",attrs:{"width":"560"}},[_c('img',{staticClass:"card-top-logo",attrs:{"src":require("@/assets/ematrica-logo.svg"),"alt":"E-matrica logo"}}),_c('h1',{staticClass:"text-h4 blue--text text--darken-4 pb-sm-6"},[_vm._v(" "+_vm._s(_vm.$t("CHANGE_PASSWORD_PAGE.HEADER"))+" ")]),_c('div',{staticClass:"pt-4 pt-sm-4 pb-8 blue--text text--darken-4 body-1"},[_vm._v(" "+_vm._s(_vm.$t("CHANGE_PASSWORD_PAGE.MESSAGE1"))+" ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.changePassword.apply(null, arguments)}}},[_c('div',{staticClass:"item-controller"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('CHANGE_PASSWORD_PAGE.FIELDS.PASSWORD'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"id":"expiredPasswordPage--currentPassword","name":"CurrentPassword","showPasswordEye":true,"type":"password","label":_vm.$t('CHANGE_PASSWORD_PAGE.FIELDS.PASSWORD'),"aria-label":_vm.$t('CHANGE_PASSWORD_PAGE.FIELDS.PASSWORD'),"aria-required":true,"error-messages":errors,"required":""},model:{value:(_vm.formValues.currentPassword),callback:function ($$v) {_vm.$set(_vm.formValues, "currentPassword", $$v)},expression:"formValues.currentPassword"}})]}}],null,true)})],1),_c('div',{staticClass:"item-controller"},[_c('ValidationProvider',{attrs:{"rules":"customRequired|extendedPassword","vid":"newPassword","name":_vm.$t('CHANGE_PASSWORD_PAGE.FIELDS.NEW_PASSWORD'),"customMessages":{
              min: _vm.$t('CHANGE_PASSWORD_PAGE.FIELDS.PASSWORD_ERROR'),
              lowerUpperCase: _vm.$t(
                'CHANGE_PASSWORD_PAGE.FIELDS.PASSWORD_ERROR'
              ),
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('TextInput',{attrs:{"id":"expiredPasswordPage--newPassword","name":"NewPassword","showPasswordEye":true,"type":"password","label":_vm.$t('CHANGE_PASSWORD_PAGE.FIELDS.NEW_PASSWORD'),"aria-label":_vm.$t('CHANGE_PASSWORD_PAGE.FIELDS.NEW_PASSWORD'),"aria-required":true,"aria-described-by":'password-description',"error-messages":errors,"aria-live":'polite',"required":""},model:{value:(_vm.formValues.newPassword),callback:function ($$v) {_vm.$set(_vm.formValues, "newPassword", $$v)},expression:"formValues.newPassword"}})]}}],null,true)})],1),_c('PasswordValidator',{attrs:{"value":_vm.formValues.newPassword}}),_c('div',{staticClass:"item-controller pt-6"},[_c('ValidationProvider',{attrs:{"rules":"customRequired|confirmed:newPassword","name":_vm.$t('CHANGE_PASSWORD_PAGE.FIELDS.NEW_PASSWORD_AGAIN')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":_vm.$t('CHANGE_PASSWORD_PAGE.FIELDS.NEW_PASSWORD_AGAIN'),"aria-label":_vm.$t('CHANGE_PASSWORD_PAGE.FIELDS.NEW_PASSWORD_AGAIN'),"id":"expiredPasswordPage--newPasswordAgain","showPasswordEye":true,"type":"password","error-messages":errors,"tab-index":3,"required":""},model:{value:(_vm.formValues.newPasswordAgain),callback:function ($$v) {_vm.$set(_vm.formValues, "newPasswordAgain", $$v)},expression:"formValues.newPasswordAgain"}})]}}],null,true)})],1),_c('div',{staticClass:"submit-content"},[_c('v-btn',{staticClass:"black white--text mt-5",attrs:{"block":"","id":"expiredPasswordPage--saveNewPassword","type":"submit","color":"primary","disabled":invalid,"loading":_vm.changePasswordLoading,"aria-label":_vm.$t('CHANGE_PASSWORD_PAGE.BUTTONS.SAVE_NEW_PASSWORD')}},[_vm._v(_vm._s(_vm.$t("CHANGE_PASSWORD_PAGE.BUTTONS.SAVE_NEW_PASSWORD"))+" ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }