var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pb-6 d-flex"},[_c('v-icon',{attrs:{"aria-label":_vm.$t('GENERAL.BUTTONS.BACK')},on:{"click":_vm.prevStep}},[_vm._v("mdi-arrow-left")]),_c('h1',{staticClass:"text-h4 blue--text text--darken-4 pl-2"},[_vm._v(" "+_vm._s(_vm.$t("USER_PASSWORD_FORM.HEADER"))+" ")]),_c('div',{staticClass:"d-sr-only"},[_vm._v(_vm._s(_vm.$t("USER_PASSWORD_FORM.STEP2_LABEL")))])],1),_c('div',{staticClass:"pb-8 blue--text text--darken-4 body-1"},[_vm._v(" "+_vm._s(_vm.$t("USER_PASSWORD_FORM.MESSAGE1"))+" ")]),_c('ValidationObserver',{on:{"submit":function($event){$event.preventDefault();return _vm.nextStep.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('div',{staticClass:"item-controller"},[_c('ValidationProvider',{attrs:{"rules":"customRequired|extendedPassword","vid":"password","name":_vm.$t('USER_PASSWORD_FORM.FIELDS.PASSWORD'),"customMessages":{
            min: _vm.$t('USER_PASSWORD_FORM.FIELDS.PASSWORD_ERROR'),
            lowerUpperCase: _vm.$t('USER_PASSWORD_FORM.FIELDS.PASSWORD_ERROR'),
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":_vm.$t('USER_PASSWORD_FORM.FIELDS.PASSWORD'),"id":'userPasswordForm--loginPassword',"show-password-eye":true,"required":true,"aria-label":_vm.$t('USER_PASSWORD_FORM.FIELDS.PASSWORD'),"aria-required":true,"aria-described-by":'password-description',"aria-live":'polite',"error-messages":errors},model:{value:(_vm.formValues.password),callback:function ($$v) {_vm.$set(_vm.formValues, "password", $$v)},expression:"formValues.password"}})]}}],null,true)})],1),_c('PasswordValidator',{attrs:{"value":_vm.formValues.password}}),_c('div',{staticClass:"item-controller pt-6"},[_c('ValidationProvider',{attrs:{"rules":"customRequired|confirmed:password","name":_vm.$t('USER_PASSWORD_FORM.FIELDS.PASSWORD_AGAIN')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":_vm.$t('USER_PASSWORD_FORM.FIELDS.PASSWORD_AGAIN'),"id":'userPasswordForm--loginPasswordConfirmation',"type":'password',"aria-label":_vm.$t('USER_PASSWORD_FORM.FIELDS.PASSWORD_AGAIN'),"aria-required":true,"error-messages":errors,"show-password-eye":true,"required":true},model:{value:(_vm.formValues.confirmation),callback:function ($$v) {_vm.$set(_vm.formValues, "confirmation", $$v)},expression:"formValues.confirmation"}})]}}],null,true)})],1),_c('div',{staticClass:"submit-content"},[_c('v-btn',{attrs:{"block":"","id":"userPasswordForm--nextButton","depressed":true,"color":"primary","type":"submit","aria-label":_vm.$t('USER_PASSWORD_FORM.BUTTON.NEXT'),"disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t("USER_PASSWORD_FORM.BUTTON.NEXT"))+" ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }