import { AppState } from "@/store";
import { Module } from "vuex";

export interface AccessibilityState {
  dialogOpen: string;
  topDialogLevel: number;
}

export const initialAccessibilityState: AccessibilityState = {
  dialogOpen: "false",
  topDialogLevel: -1,
};

export const accessibilityModule: Module<AccessibilityState, AppState> = {
  namespaced: true,
  state: initialAccessibilityState,
  mutations: {
    setDialogOpen: (state: AccessibilityState, status: boolean) => {
      state.dialogOpen = status.toString();
    },
    setTopDialogLevel: (state: AccessibilityState, level: number) => {
      state.topDialogLevel = level;
    },
  },
  actions: {
    setDialogOpen: ({ commit }, payload: boolean) => {
      commit("setDialogOpen", payload);
    },
    setTopDialogLevel: ({ commit }, payload: number) => {
      commit("setTopDialogLevel", payload);
    },
  },
};
