





























import { defineComponent } from "@vue/composition-api";
import { ERoutes } from "@/core/dataTypes/enum";
import router from "@/router";
import { i18n } from "@/locales/i18n";
import { getLanguageStaticRoute } from "@/core/utils/router.util";

export default defineComponent({
  name: "UnSuccesFullEmailActivationPage",
  setup() {
    document.title = i18n.tc("CHANGE_EMAIL_ACTIVATION_EMAIL_FAILED.PAGE_TITLE");
    const onTryAgain = () => {
      router.push(getLanguageStaticRoute(ERoutes.CHANGE_EMAIL));
    };

    return {
      onTryAgain,
    };
  },
});
