/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  User,
  LoginResponse,
  SessionData,
  ProfileDetails,
  CampaignMessage,
} from "../dataTypes/types";

export const transformLoginResponse = (data: any): LoginResponse =>
  data && {
    sessionData: transformSessionData(data.sessionData),
    customerLogin: transformUser(data.customerLogin),
  };

export const transformSessionData = (data: any): SessionData =>
  data && {
    loginName: data.loginName,
    sessionToken: data.sessionToken,
    sessionTokenExpiry: data.sessionTokenExpiry,
    refreshToken: data.refreshToken,
    refreshTokenExpiry: data.refreshTokenExpiry,
  };

export const transformUser = (data: any): User =>
  data && {
    customerName: data.customerName,
    customerNamePrefix: data.customerNamePrefix,
    customerNumber: data.customerNumber,
    emailAddress: data.emailAddress,
    expiredPassword: data.expiredPassword,
    language: data.language,
  };

export const transformCustomerRegistration = (data: any): User =>
  data && {
    customerName: data.customerName,
    customerNumber: data.customerId,
    emailAddress: data.email,
  };

export const transformProfileDetails = (data: any): ProfileDetails =>
  data && {
    basicRegistrationData: {
      customerName: data.basicRegistrationData?.customerName,
      customerNumber: data.basicRegistrationData?.customerId,
      emailAddress: data.basicRegistrationData?.email,
      language: data.basicRegistrationData?.language,
      notificationPhone: data.basicRegistrationData?.notificationPhone,
    },
    billingAddressList: data.billingAddressList?.billingAddress,
    defaultBillingAddress: data.defaultBillingAddress,
    maxBillingAddressSize: data.maxBillingAddressSize,
  };

export const transformCampaignMessages = (data: any): CampaignMessage[] => {
  return data.campaignMessageList?.campaignMessageList ?? [];
};
