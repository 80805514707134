import {
  CountryCode,
  CreateHdCustomerVehicleRequest,
  HdCustomerVehicleListResponse,
  PlateNumberValidationRequest,
  PlateNumberValidationResponse,
  VehiclePriceCategoryListResponse,
} from "../dataTypes/types";
import httpService from "../services/http.service";
import { transformCountryCodes } from "@/core/transformers/accountService.transformer";
import { transformVehicleList } from "@/core/transformers/vehicleService.transformer";
import http from "@/core/services/http.service";

export const getHdCustomerVehicleList = (
  customerId: string
): Promise<HdCustomerVehicleListResponse> =>
  httpService
    .get<never>(`AccountService/vehicle/hd/${customerId}/list`)
    .then((response) => transformVehicleList(response.data));

export const deleteHdCustomerVehicle = (
  customerId: string,
  vehicleId: string
): Promise<void> =>
  httpService
    .delete<never>(`AccountService/vehicle/hd/${customerId}/${vehicleId}`)
    .then((response) => response.data);

export const setDefaultHdCustomerVehicle = (
  customerId: string,
  vehicleId: string
): Promise<void> =>
  httpService
    .get<never>(`AccountService/vehicle/hd/${customerId}/${vehicleId}`)
    .then((response) => response.data);

export const getVehiclePriceCategories =
  (): Promise<VehiclePriceCategoryListResponse> =>
    httpService
      .get<never>(`/PublicService/public/vehiclePriceCategories`)
      .then((response) => response.data);

export const createHdCustomerVehicle = (
  requestData: CreateHdCustomerVehicleRequest,
  customerId: string
): Promise<void> =>
  http
    .post<never>(
      `/AccountService/vehicle/hd/customer/${customerId}`,
      requestData
    )
    .then((response) => response.data);

export const getCountryCodes = (): Promise<CountryCode[]> =>
  httpService
    .get<never>(`/PublicService/public/countryCodes/mostCommon`)
    .then((response) => transformCountryCodes(response.data));

export const plateNumberValid = (
  requestData: PlateNumberValidationRequest
): Promise<PlateNumberValidationResponse> =>
  httpService
    .post<never>("/PublicService/public/validatePlateNumber", requestData)
    .then((response) => response.data);

export const getVersion =
  (): /* eslint-disable @typescript-eslint/no-explicit-any */ Promise<any> =>
    httpService
      .get<never>("/PublicService/public/version/EUSZI")
      .then((response) => response.data);
