




















import { defineComponent, onMounted } from "@vue/composition-api";
import { userFacade } from "@/store/modules/user/user.facade";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "RegistrationActivation",
  setup(props, { root }) {
    document.title = i18n.tc("REGISTRATION_ACTIVATION_PAGE.PAGE_TITLE");
    const token = root.$route.query.code as string;
    onMounted(() => {
      if (token) {
        userFacade.registrationToken(token);
      }
    });
    return {};
  },
});
