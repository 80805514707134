




















import {
  computed,
  defineComponent,
  reactive,
  watch,
} from "@vue/composition-api";
import { RegistrationFormState } from "@/core/dataTypes/types";
import NewBillingDetailForm from "@/components/forms/NewBillingDetailsForm/NewBillingDetailsForm.vue";
import { registrationFacade } from "@/store/modules/registration/registration.facade";
import { alertFacade } from "@/store/modules/alert/alert.facade";

export default defineComponent({
  name: "UserBillingDetails",
  components: { NewBillingDetailForm },
  setup(props, { emit }) {
    const registrationFormState = computed(registrationFacade.registrationForm);
    const registrationLoading = computed(
      registrationFacade.registrationLoading
    );
    const formValues = reactive<Partial<RegistrationFormState>>({
      corporation: 0,
      billingName: registrationFormState.value?.customerName,
      country: "",
      city: "",
      zipCode: undefined,
      street: "",
      streetNumber: "",
      streetType: "",
      floor: "",
      door: undefined,
      vatCountry: undefined,
      vatNumber: "",
      invoiceGroupId: "",
      euVatNumber: "",
      thirdCountryVatNumber: "",
      hasNotEUTaxNumber: false,
    });
    watch(
      () => registrationFormState.value?.customerName,
      (customerName) => (formValues.billingName = customerName)
    );

    const submit = () => {
      alertFacade.resetAlert();
      registrationFacade.saveRegistrationForm(formValues).then(() => {
        registrationFacade.registration();
      });
    };
    const prevStep = () => {
      alertFacade.resetAlert();
      emit("changeStep", 2);
    };

    return {
      formValues,
      registrationLoading,
      prevStep,
      submit,
    };
  },
});
