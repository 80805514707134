



















































































































import { computed, defineComponent, reactive } from "@vue/composition-api";
import TextInput from "@/components/forms/input/TextInput.vue";
import PasswordValidator from "@/components/passwordValidator/PasswordValidator.vue";
import { PasswordChangeRequest } from "@/core/dataTypes/types";
import { userFacade } from "@/store/modules/user/user.facade";
import { ERoutes } from "@/core/dataTypes/enum";
import { cryptoSHA1 } from "@/core/utils/crypto/cryptoHelpers";
import { getRecaptchaToken } from "@/core/utils/captcha";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "ExpiredPasswordPage",
  components: {
    TextInput,
    PasswordValidator,
  },
  setup() {
    const formValues = reactive({
      currentPassword: "",
      newPassword: "",
      newPasswordAgain: "",
    });
    const changePasswordLoading = computed(
      userFacade.expiredPasswordChangeLoading
    );

    const changePassword = () => {
      getRecaptchaToken("expiredPasswordChange").then(
        (recaptchaResponseToken: string) => {
          const requestData: PasswordChangeRequest = {
            recaptchaResponseToken,
            oldPassword: cryptoSHA1(formValues.currentPassword),
            newPassword: cryptoSHA1(formValues.newPassword),
          };
          userFacade.expiredPasswordChange(requestData);
        }
      );
    };

    return {
      formValues,
      changePasswordLoading,
      ERoutes,
      changePassword,
    };
  },
  metaInfo() {
    return {
      titleTemplate: `${i18n.tc("CHANGE_PASSWORD_PAGE.TITLE")} | %s`,
    };
  },
});
