import { NavigationGuardNext, Route } from "vue-router";
import AuthService from "@/core/services/auth.service";
import { ERoutes } from "../dataTypes/enum";
import httpService from "../services/http.service";
import { commonFacade } from "@/store/modules/common/common.facade";
import store from "@/store";
import { userFacade } from "@/store/modules/user/user.facade";
import { getLanguageStaticRoute } from "@/core/utils/router.util";

const waitUntilRefresh = async (): Promise<void> => {
  return new Promise((resolve) => {
    if (userFacade.refreshLoading()) {
      const waitRefreshUnsubscribe = store.subscribe((mutation, state) => {
        if (!state.user?.refreshLoading) {
          waitRefreshUnsubscribe();
          resolve();
        }
      });
    } else {
      resolve();
    }
  });
};

export const authedGuard = async (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
): Promise<void> => {
  try {
    await waitUntilRefresh();
    if (httpService.isPending()) {
      httpService.cancel();
    }
    const hasCampaignMessage = !!userFacade.campaignMessageList()?.length;
    const isNotRequiresAuthMeta = to.meta?.notRequiresAuth;
    const isNotLoggedInUser = userFacade.notLoggedInUser();
    switch (true) {
      case isNotRequiresAuthMeta && isNotLoggedInUser:
        commonFacade.setHeaderVisibility(false);
        next();
        break;
      case AuthService.hasValidToken() &&
        hasCampaignMessage &&
        to.path !== getLanguageStaticRoute(ERoutes.CAMPAIGN):
        commonFacade.setHeaderVisibility(true);
        from.path === getLanguageStaticRoute(ERoutes.CAMPAIGN)
          ? next(false)
          : next(getLanguageStaticRoute(ERoutes.CAMPAIGN));
        break;
      case AuthService.hasValidToken():
        commonFacade.setHeaderVisibility(true);
        next();
        break;
      default:
        AuthService.resetStateAndSessionTokens();
        next(getLanguageStaticRoute(ERoutes.LOGIN));
    }
  } catch (error) {
    throw Error(error);
  }
};

/*
if (
  AuthService.hasValidToken() &&
  hasCampaignMessage &&
  to.path !== ERoutes.CAMPAIGN
) {
  commonFacade.setHeaderVisibility(true);
  return from.path === ERoutes.CAMPAIGN
    ? next(false)
    : next(ERoutes.CAMPAIGN);
} else if (AuthService.hasValidToken()) {
  commonFacade.setHeaderVisibility(true);
  return next();
} else {
  AuthService.resetStateAndSessionTokens();
  return next(ERoutes.LOGIN);
}
*/
