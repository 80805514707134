







































































































































































import { defineComponent, reactive, ref, computed } from "@vue/composition-api";
import TextInput from "@/components/forms/input/TextInput.vue";
import CardSelector from "@/components/forms/CardSelector/CardSelector.vue";
import CheckboxInput from "@/components/forms/checkbox/CheckboxInput.vue";
import { ERoutes } from "@/core/dataTypes/enum";
import { registrationFacade } from "@/store/modules/registration/registration.facade";
import { languages } from "@/core/utils/languages";
import { commonFacade } from "@/store/modules/common/common.facade";

export default defineComponent({
  name: "UserDataForm",
  components: {
    TextInput,
    CardSelector,
    CheckboxInput,
  },
  setup(props, { emit }) {
    const termsAndConditions = ref(false);
    const aszfLink = computed(commonFacade.aszf);
    const dataProtectionLink = computed(commonFacade.dataProtection);
    const dataManagementStatement = ref(false);
    const formValues = reactive({
      email: "",
      customerName: "",
      notificationPhone: "",
      language: "HU",
    });

    const nextStep = () => {
      registrationFacade.saveRegistrationForm(formValues);
      emit("changeStep", 2);
    };
    return {
      formValues,
      termsAndConditions,
      dataManagementStatement,
      nextStep,
      ERoutes,
      languages,
      CardSelector,
      aszfLink,
      dataProtectionLink,
    };
  },
});
