





























import { DialogResultType } from "@/core/dataTypes/types";
import {
  defineComponent,
  onUnmounted,
  ref,
  computed,
} from "@vue/composition-api";
import { accessibilityFacade } from "@/store/modules/accessibility/accessibility.facade";
import dialogService from "@/core/services/dialog.service";

export default defineComponent({
  name: "DialogWrapper",
  props: {
    fullscreen: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [String, Number],
      default: "none",
    },
    maxWidth: {
      type: [String, Number],
      default: "auto",
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    retainFocus: {
      type: Boolean,
      default: true,
    },
    attach: [Boolean, String],
    closeDelay: Number,
    eager: Boolean,
    hideOverlay: Boolean,
    noClickAnimation: Boolean,
    openDelay: Number,
    overlayColor: String,
    overlayOpacity: [Number, String],
    customClass: String,
    component: Object,
    componentProps: [Object, Array, Boolean, String, Number],
    dialogLevel: {
      type: Number,
      required: true,
    },
  },
  setup(props, ctx) {
    accessibilityFacade.setDialogOpen(true);
    accessibilityFacade.setTopDialogLevel(props.dialogLevel);
    const ariaHidden = computed((): string => {
      const topDialogLevel = accessibilityFacade.topDialogLevel() as number;
      return (props.dialogLevel < topDialogLevel).toString();
    });
    const open = ref<boolean>(true);
    const dialogClose = (): void => {
      ctx.emit("dialogClose");
      const topDialogLevel = accessibilityFacade.topDialogLevel() as number;
      accessibilityFacade.setTopDialogLevel(topDialogLevel - 1);
      if (dialogService.getDialogInstancesCount() === 0) {
        accessibilityFacade.setDialogOpen(false);
      }
    };

    const dialogOutsideClose = (): void => {
      if (!props.persistent) {
        dialogClose();
      }
    };

    const dialogResult = (result: DialogResultType): void => {
      ctx.emit("dialogResult", result);
    };

    onUnmounted((): void => {
      ctx.emit("dialogUnmounted");
    });

    return {
      open,
      dialogClose,
      dialogResult,
      dialogOutsideClose,
      ariaHidden,
    };
  },
});
