import { NavigationGuardNext, Route } from "vue-router";
import { ERoutes } from "../dataTypes/enum";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import { userFacade } from "@/store/modules/user/user.facade";
import * as StickerService from "@/core/services/sticker.service";
import { getLanguageStaticRoute } from "@/core/utils/router.util";

export const stickerCreationGuard = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
): void => {
  const notLoggedInUser = userFacade.notLoggedInUser();
  const isPaymentProgress = stickerFacade.cartStatus() === "6";
  const cartId = !notLoggedInUser
    ? stickerFacade.cartId()
    : StickerService.getAdHocCartId();
  switch (true) {
    case !notLoggedInUser:
      stickerFacade.getActiveCart().then(() => {
        if (stickerFacade.cartStatus() === "6") {
          next(
            getLanguageStaticRoute(
              `${ERoutes.STICKER_PURCHASE}/${ERoutes.STICKER_PAY}`
            )
          );
        } else {
          next();
        }
      });
      break;
    case notLoggedInUser && !!cartId:
      stickerFacade.getAdHocValidityCheck().then(() => {
        if (
          stickerFacade.validAdHocId() &&
          stickerFacade.cartStatus() === "6"
        ) {
          next(
            getLanguageStaticRoute(
              `${ERoutes.STICKER_PURCHASE}/${ERoutes.STICKER_PAY}`
            )
          );
        } else {
          next();
        }
      });
      break;
    case notLoggedInUser && !cartId:
      stickerFacade.resetCartId();
      next();
      break;
    case !notLoggedInUser && isPaymentProgress:
      next(
        getLanguageStaticRoute(
          `${ERoutes.STICKER_PURCHASE}/${ERoutes.STICKER_PAY}`
        )
      );
      break;
    default:
      next();
  }
};
