

















































import {
  defineComponent,
  ref,
  SetupContext,
  watch,
} from "@vue/composition-api";

export default defineComponent({
  name: "Combobox",
  props: {
    id: String,
    value: [Number, String, Array, Object],
    items: Array,
    filled: {
      type: Boolean,
      default: true,
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
    label: String,
    chips: {
      type: Boolean,
      default: false,
    },
    hint: String,
    autoSelectFirst: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    searchInput: String,
    loading: Boolean,
    itemText: [String, Array, Function],
    autocomplete: String,
    itemValue: String,
    errorMessages: [String, Array],
    required: Boolean,
    customListItem: {
      type: Boolean,
      default: false,
    },
    tabindex: Number,
    separateListsNumber: {
      type: [Boolean, Number],
      default: false,
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setup(props: any, { emit }: SetupContext) {
    const innerValue = ref(props.value);
    const stopWatch = watch(
      () => props.value,
      (newVal, oldVal) => {
        if (oldVal !== newVal) {
          innerValue.value = newVal;
          stopWatch();
        }
      }
    );

    let isBlur = false;
    const onInput = () => {
      if (typeof innerValue.value === "object") {
        // TODO: itt az a probléma, hogy BLUR kikattintáskor is lefut ez az EVENT, pedig nem szabadna!! ezt egy nagyon csúnya megoldás, amint megjavul ennek a event kezelése ennek a komponensek javítani kell!
        if (!isBlur) {
          emit("input", innerValue.value[props.itemValue as string]);
        }
      } else {
        emit("input", innerValue.value);
      }
    };
    const onChange = () => {
      if (typeof innerValue.value === "object") {
        // TODO: itt az a probléma, hogy BLUR kikattintáskor is lefut ez az EVENT, pedig nem szabadna!! ezt egy nagyon csúnya megoldás, amint megjavul ennek a event kezelése ennek a komponensek javítani kell!
        emit("input", innerValue.value[props.itemValue as string]);
      } else {
        emit("input", innerValue.value);
      }
    };
    const onBlur = (ev: FocusEvent) => {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const target = ev.target as any;
      isBlur = !!target.value.length;
    };
    const onKeyUp = (ev: KeyboardEvent) => {
      isBlur = false;
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const target = ev.target as any; // ev.target után a .value-t megfogja a linter, első körben nem tudtam hozzá rendelni typescript típust, amit elfogad.
      emit("input", target.value);
    };
    return {
      innerValue,
      onKeyUp,
      onInput,
      onBlur,
      onChange,
    };
  },
});
