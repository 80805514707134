




















import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "Alert",
  props: {
    border: {
      type: String, // top | right | bottom | left.
    },
    closeIcon: {
      type: String,
      default: "$cancel",
    },
    id: {
      type: String,
      required: true,
    },
    icon: [String],
    type: {
      type: String,
      default: "info", //success, info, warning or error
    },
    text: {
      type: String,
    },
    value: {
      type: Boolean,
      default: false,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    coloredBorder: Boolean,
    width: [String, Number],
    maxWidth: {
      type: [String, Number],
      default: 446,
    },
    elevation: {
      type: [String, Number],
      default: 2,
    },
  },
  setup(props, { emit }) {
    const icon = ref(props.icon);
    const selectedIcon = ref<string>();
    const show = ref(props.value);
    const onClose = () => {
      show.value = !show.value;
      emit("close", false);
    };

    switch (icon.value) {
      case "error":
        selectedIcon.value = "mdi-alert-circle";
        break;
      case "success":
        selectedIcon.value = "mdi-check-circle";
        break;
      case "warning":
        selectedIcon.value = "mdi-warning";
        break;
      case "info":
        selectedIcon.value = "mdi-info";
        break;
      default:
        selectedIcon.value = icon.value;
    }

    return {
      show,
      onClose,
      selectedIcon,
    };
  },
});
