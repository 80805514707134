import {
  LoginRequest,
  LoginResponse,
  SessionData,
  User,
  ForgottenPasswordRequest,
  NewForgottenPasswordRequest,
  ChangeProfileDetails,
  AddressType,
  ProfileDetails,
  ChangeEmailTokenRequestType,
  ChangeEmailActivationTokenRequest,
  ValidResponseType,
  CreateAndModifyBillingAddressFormState,
  CreateBillingAddressRequestType,
  PasswordChangeRequest,
  ChangeEmailRequest,
  CampaignMessage,
  ActiveCartResponse,
} from "@/core/dataTypes/types";
import {
  EAlertType,
  ECampaignMessageType,
  ERoutes,
} from "@/core/dataTypes/enum";
import { AppState } from "@/store";
import { Module } from "vuex";
import AuthService from "@/core/services/auth.service";
import router from "@/router";
import * as customerServiceApi from "@/core/api/customerService.api";
import { alertFacade } from "@/store/modules/alert/alert.facade";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import SnackService from "@/core/services/snackbar.service";
import * as PublicServiceApi from "@/core/api/publicService.api";
import { i18n } from "@/locales/i18n";
import dialogService from "@/core/services/dialog.service";
import PasswordExpiredDialog from "@/components/dialog/PasswordExpiredDialog.vue";
import { getRecaptchaToken } from "@/core/utils/captcha";
import { getLanguageStaticRoute } from "@/core/utils/router.util";
import { userFacade } from "@/store/modules/user/user.facade";

export interface UserState {
  session: SessionData | undefined;
  user: Partial<User> | undefined;
  userLoading: boolean;
  loginLoading: boolean;
  logoutLoading: boolean;
  refreshLoading: boolean;
  changePasswordLoading: boolean;
  changeProfileDetailsLoading: boolean;
  changeEmailLoading: boolean;
  changeEmailActivationLoading: boolean;
  forgottenPasswordLoading: boolean;
  putForgottenPasswordLoading: boolean;
  forgottenPasswordActive: boolean;
  forgottenPasswordEmailAddress: string;
  forgottenPasswordTokenLoading: boolean;
  deleteCustomerLoading: boolean;
  deleteBillingAddressLoading: boolean;
  billingAddressList: Partial<AddressType>[];
  defaultBillingAddress: Partial<AddressType>;
  newEmailAddress: string;
  customerNumberEncrypted: string;
  expiredCustomerNumber: string | undefined;
  createBillingAddressLoading: boolean;
  validZIPCode: ValidResponseType | undefined;
  validZIPCodeLoading: boolean;
  validVATNumber: ValidResponseType | undefined;
  validVATNumberLoading: boolean;
  modifyBillingAddressLoading: boolean;
  billingAddress: CreateBillingAddressRequestType;
  expiredPasswordLoading: boolean;
  campaignMessageList: CampaignMessage[];
  campaignMessageListLoading: boolean;
  acceptCampaignMessageLoading: boolean;
  maxBillingAddressSize: number;
  notLoggedInUser: boolean;
}

export const initialUserState: UserState = {
  session: undefined,
  user: undefined,
  userLoading: false,
  loginLoading: false,
  logoutLoading: false,
  forgottenPasswordLoading: false,
  putForgottenPasswordLoading: false,
  forgottenPasswordActive: true,
  forgottenPasswordEmailAddress: "",
  refreshLoading: false,
  changePasswordLoading: false,
  changeProfileDetailsLoading: false,
  changeEmailLoading: false,
  changeEmailActivationLoading: false,
  forgottenPasswordTokenLoading: false,
  deleteCustomerLoading: false,
  deleteBillingAddressLoading: false,
  billingAddressList: [],
  defaultBillingAddress: {} as AddressType,
  newEmailAddress: "",
  customerNumberEncrypted: "",
  expiredCustomerNumber: undefined,
  createBillingAddressLoading: false,
  billingAddress: {} as CreateBillingAddressRequestType,
  modifyBillingAddressLoading: false,
  validZIPCode: undefined,
  validZIPCodeLoading: false,
  validVATNumber: undefined,
  validVATNumberLoading: false,
  expiredPasswordLoading: false,
  campaignMessageList: [],
  campaignMessageListLoading: false,
  acceptCampaignMessageLoading: false,
  maxBillingAddressSize: 5,
  notLoggedInUser: true,
};

export const userModule: Module<UserState, AppState> = {
  namespaced: true,
  state: initialUserState,
  mutations: {
    setUser: (state, payload: Partial<User>) => {
      state.user = payload;
    },
    loginRequest: (state) => {
      state.loginLoading = true;
    },
    loginSuccess: (state) => {
      state.loginLoading = false;
    },
    loginFail: (state) => {
      state.loginLoading = false;
    },
    setExperience: (state, payload: LoginResponse) => {
      state.user = payload.customerLogin;
    },
    setLoginSuccess: (state, payload: LoginResponse) => {
      state.user = payload.customerLogin;
      state.session = payload?.sessionData;
    },
    logoutRequest: (state) => {
      state.logoutLoading = true;
    },
    logoutSuccess: (state) => {
      state.logoutLoading = false;
    },
    logoutFail: (state) => {
      state.logoutLoading = false;
    },
    resetLogout: (state) => {
      state.logoutLoading = false;
    },
    forgottenPasswordRequest: (state) => {
      state.forgottenPasswordLoading = true;
    },
    forgottenPasswordSuccess: (state) => {
      state.forgottenPasswordLoading = false;
    },
    forgottenPasswordFail: (state) => {
      state.forgottenPasswordLoading = false;
    },
    putForgottenPasswordRequest: (state) => {
      state.putForgottenPasswordLoading = true;
    },
    putForgottenPasswordSuccess: (state) => {
      state.putForgottenPasswordLoading = false;
    },
    putForgottenPasswordFail: (state) => {
      state.putForgottenPasswordLoading = false;
    },
    forgottenPasswordActive: (state) => {
      state.forgottenPasswordActive = false;
    },
    forgottenPasswordTokenRequest: (state) => {
      state.forgottenPasswordTokenLoading = true;
    },
    forgottenPasswordTokenSuccess: (state) => {
      state.forgottenPasswordTokenLoading = false;
    },
    forgottenPasswordTokenFail: (state) => {
      state.forgottenPasswordTokenLoading = true;
    },
    forgottenPasswordEmailAddress: (state, payload: string) => {
      state.forgottenPasswordEmailAddress = payload;
    },
    refreshRequest: (state) => {
      state.refreshLoading = true;
    },
    refreshSuccess: (state, payload: SessionData) => {
      state.session = payload;
      state.refreshLoading = false;
    },
    refreshFail: (state) => {
      state.refreshLoading = false;
    },
    changePasswordRequest: (state) => {
      state.changePasswordLoading = true;
    },
    changePasswordSuccess: (state) => {
      state.changePasswordLoading = false;
    },
    changePasswordFail: (state) => {
      state.changePasswordLoading = false;
    },

    changeProfileDetailsRequest: (state) => {
      state.changeProfileDetailsLoading = true;
    },
    changeProfileDetailsSuccess: (state) => {
      state.changeProfileDetailsLoading = false;
    },
    changeProfileDetailsFail: (state) => {
      state.changeProfileDetailsLoading = false;
    },

    changeEmailRequest: (state) => {
      state.changeEmailLoading = true;
    },
    changeEmailSuccess: (state) => {
      state.changeEmailLoading = false;
    },
    changeEmailFail: (state) => {
      state.changeEmailLoading = false;
    },

    saveEncryptedCustomerNumber: (state, customerNumberEncrypted: string) => {
      state.customerNumberEncrypted = customerNumberEncrypted;
    },

    changeEmailActivationLoadingRequest: (state) => {
      state.changeEmailActivationLoading = true;
    },
    changeEmailActivationLoadingSuccess: (state) => {
      state.changeEmailActivationLoading = false;
    },
    changeEmailActivationLoadingFail: (state) => {
      state.changeEmailActivationLoading = false;
    },

    changeNewEmailAddress: (state, emailAddress: string) => {
      state.newEmailAddress = emailAddress;
    },

    changeModifyBillingAddress: (state, payload) => {
      state.billingAddress = payload;
    },

    deleteCustomerRequest: (state) => {
      state.deleteCustomerLoading = true;
    },
    deleteCustomerSuccess: (state) => {
      state.deleteCustomerLoading = false;
    },
    deleteCustomerFail: (state) => {
      state.deleteCustomerLoading = false;
    },

    deleteBillingAddressRequest: (state) => {
      state.deleteBillingAddressLoading = true;
    },
    deleteBillingAddressSuccess: (state) => {
      state.deleteBillingAddressLoading = false;
    },
    deleteBillingAddressFail: (state) => {
      state.deleteBillingAddressLoading = false;
    },
    userRequest: (state) => {
      state.userLoading = true;
    },
    userSuccess: (state, payload: Partial<User>) => {
      state.user = payload;
      state.userLoading = false;
    },
    userFail: (state) => {
      state.userLoading = false;
    },
    setBillingAddress: (state, payload: ProfileDetails) => {
      state.billingAddressList = payload.billingAddressList;
      state.defaultBillingAddress = payload.defaultBillingAddress;
      state.maxBillingAddressSize = payload.maxBillingAddressSize;
    },
    createBillingAddressRequest: (state) => {
      state.createBillingAddressLoading = true;
    },
    createBillingAddressSuccess: (state) => {
      state.createBillingAddressLoading = false;
    },
    createBillingAddressFail: (state) => {
      state.createBillingAddressLoading = false;
    },
    modifyBillingAddressRequest: (state) => {
      state.modifyBillingAddressLoading = true;
    },
    modifyBillingAddressSuccess: (state) => {
      state.modifyBillingAddressLoading = false;
    },
    modifyBillingAddressFail: (state) => {
      state.modifyBillingAddressLoading = false;
    },
    validZIPCodeRequest: (state) => {
      state.validZIPCodeLoading = true;
    },
    validZIPCodeSuccess: (state, payload: ValidResponseType) => {
      state.validZIPCode = payload;
      state.validZIPCodeLoading = false;
    },
    validZIPCodeFail: (state) => {
      state.validZIPCodeLoading = false;
    },

    validVATNumberCodeRequest: (state) => {
      state.validVATNumberLoading = true;
    },
    validVATNumberCodeSuccess: (state, payload: ValidResponseType) => {
      state.validVATNumber = payload;
      state.validVATNumberLoading = false;
    },
    validVATNumberCodeFail: (state) => {
      state.validVATNumberLoading = false;
    },

    expiredPasswordRequest: (state) => {
      state.expiredPasswordLoading = true;
    },
    expiredPasswordSuccess: (state) => {
      state.expiredPasswordLoading = false;
    },
    expiredPasswordFail: (state) => {
      state.expiredPasswordLoading = false;
    },

    campaignMessagesRequest: (state) => {
      state.campaignMessageListLoading = true;
    },
    campaignMessagesSuccess: (state, payload: CampaignMessage[]) => {
      state.campaignMessageList = payload;
      state.campaignMessageListLoading = false;
    },
    campaignMessagesFail: (state) => {
      state.campaignMessageListLoading = false;
    },
    setCampaignMessageList: (state, payload: CampaignMessage[]) => {
      state.campaignMessageList = payload;
    },
    acceptCampaignMessageRequest: (state) => {
      state.acceptCampaignMessageLoading = true;
    },
    acceptCampaignMessageSuccess: (state) => {
      state.acceptCampaignMessageLoading = false;
    },
    acceptCampaignMessageFail: (state) => {
      state.acceptCampaignMessageLoading = false;
    },
    // setNotLoggedInUser
    setNotLoggedInUser: (state, payload: boolean) => {
      // console.log('setNotLoggedInUser', payload);
      state.notLoggedInUser = payload;
    },
    setExpiredCustomerNumber: (state, payload: string) => {
      state.expiredCustomerNumber = payload;
    },
  },
  getters: {
    campaignListHasCheckItem: (state) => {
      const filteredCheckTypeCampaign = state.campaignMessageList.filter(
        (campaignMessage) =>
          campaignMessage.messageType === ECampaignMessageType.CHECK
      );
      return !!filteredCheckTypeCampaign.length;
    },
  },
  actions: {
    login: (
      { commit, dispatch, getters, state },
      payload: { requestData: LoginRequest; firstLogin: boolean }
    ): Promise<void> => {
      alertFacade.resetAlert();
      commit("loginRequest");
      commit("setNotLoggedInUser", false);
      return getRecaptchaToken("login").then((token: string) => {
        const requestData = {
          ...payload.requestData,
          recaptchaResponseToken: token,
        };
        return AuthService.login(requestData)
          .then((response) => {
            // ExpiredPassword password
            if (response.customerLogin?.expiredPassword) {
              userFacade
                .setExpiredCustomerNumber(response.customerLogin.customerNumber)
                .then(() => {
                  dialogService.open({
                    persistent: true,
                    maxWidth: 425,
                    component: PasswordExpiredDialog,
                  });
                  commit("setExperience", response);
                  commit("setNotLoggedInUser", true);
                  commit("loginFail");
                });
            } else {
              commit("setLoginSuccess", response);
              return stickerFacade.getActiveCart().then(
                (cart: ActiveCartResponse) => {
                  if (cart?.cartStatus === "6") {
                    router.push(
                      getLanguageStaticRoute(
                        `${ERoutes.STICKER_PURCHASE}/${ERoutes.STICKER_PAY}`
                      )
                    );
                    commit("loginSuccess");
                  } else {
                    return dispatch("campaignMessages").then(() => {
                      commit("loginSuccess");
                      const campaignResponse = state.campaignMessageList;
                      if (
                        (payload.firstLogin && campaignResponse.length) ||
                        (!payload.firstLogin &&
                          getters.campaignListHasCheckItem)
                      ) {
                        router.push(getLanguageStaticRoute(ERoutes.CAMPAIGN));
                      } else if (payload.firstLogin) {
                        router.push(getLanguageStaticRoute(ERoutes.DASHBOARD));
                      } else if (
                        !payload.firstLogin &&
                        campaignResponse.length
                      ) {
                        commit("setCampaignMessageList", []);
                      }

                      SnackService.open({
                        text: i18n.tc("USER_MODULE.LOGIN_SUCCESS"),
                        timeout: 1500,
                      });
                    });
                  }
                },
                () => {
                  router.push(getLanguageStaticRoute(ERoutes.DASHBOARD));
                  commit("loginFail");
                }
              );
            }
          })
          .catch((error) => {
            commit("loginFail");
            commit("setNotLoggedInUser", true);
            throw Error(error);
          });
      });

      /*return getRecaptchaToken("login").then((token: string) => {
        const requestData = {
          ...payload.requestData,
          recaptchaResponseToken: token,
        };
        return AuthService.login(requestData)
          .then((response) => {
            commit("setLoginSuccess", response);
            stickerFacade.getActiveCart().then(
              (cart: ActiveCartResponse) => {
                if (response.customerLogin?.expiredPassword) {
                  dialogService.open({
                    persistent: true,
                    maxWidth: 425,
                    component: PasswordExpiredDialog,
                  });
                } else if (cart?.cartStatus === "6") {
                  router.push(
                    getLanguageStaticRoute(
                      `${ERoutes.STICKER_PURCHASE}/${ERoutes.STICKER_PAY}`
                    )
                  );
                  commit("loginSuccess");
                } else {
                  return dispatch("campaignMessages").then(() => {
                    commit("loginSuccess");
                    const campaignResponse = state.campaignMessageList;
                    if (
                      (payload.firstLogin && campaignResponse.length) ||
                      (!payload.firstLogin && getters.campaignListHasCheckItem)
                    ) {
                      router.push(getLanguageStaticRoute(ERoutes.CAMPAIGN));
                    } else if (payload.firstLogin) {
                      router.push(getLanguageStaticRoute(ERoutes.DASHBOARD));
                    } else if (!payload.firstLogin && campaignResponse.length) {
                      commit("setCampaignMessageList", []);
                    }

                    SnackService.open({
                      text: i18n.tc("USER_MODULE.LOGIN_SUCCESS"),
                      timeout: 1500,
                    });
                  });
                }
              },
              () => {
                commit("loginFail");
                router.push(getLanguageStaticRoute(ERoutes.DASHBOARD));
              }
            );
          })
          .catch((error) => {
            commit("loginFail");
            commit("setNotLoggedInUser", true);
            throw Error(error);
          });
      });*/
    },
    logout: ({ commit, state }) => {
      commit("logoutRequest");
      return AuthService.logout(state.session?.refreshToken as string)
        .then(() => {
          commit("logoutSuccess");
        })
        .catch((error) => {
          commit("logoutFail");
          throw Error(error);
        });
    },
    forgottenPassword: ({ commit }, payload: ForgottenPasswordRequest) => {
      commit("forgottenPasswordRequest");
      alertFacade.resetAlert();
      customerServiceApi
        .postForgottenPassword(payload)
        .then(() => {
          commit("forgottenPasswordSuccess");
          commit("forgottenPasswordEmailAddress", payload.email);
          router.push(
            getLanguageStaticRoute(ERoutes.FORGOTTEN_PASSWORD_EMAIL_SENT)
          );
        })
        .catch(() => {
          commit("forgottenPasswordFail");
        });
    },
    forgottenPasswordToken: ({ commit }, payload: string) => {
      commit("forgottenPasswordTokenRequest");
      customerServiceApi
        .getForgottenPasswordToken(payload)
        .then(() => {
          commit("forgottenPasswordTokenSuccess");
          commit("forgottenPasswordActive");
        })
        .catch(() => {
          commit("forgottenPasswordActive");
          commit("forgottenPasswordTokenFail");
        });
    },
    changeForgottenPassword: (
      { commit },
      payload: NewForgottenPasswordRequest
    ) => {
      commit("putForgottenPasswordRequest");
      alertFacade.resetAlert();
      return customerServiceApi
        .putForgottenPassword(payload)
        .then(() => {
          commit("putForgottenPasswordSuccess");
          commit("forgottenPasswordEmailAddress");
          router.push(getLanguageStaticRoute(ERoutes.LOGIN));
          SnackService.open({
            text: i18n.tc("USER_MODULE.PASSWORD_CHANGE_SUCCESS"),
          });
        })
        .catch(() => {
          commit("putForgottenPasswordFail");
        });
    },
    refresh: ({ commit, dispatch }) => {
      commit("refreshRequest");
      return AuthService.refresh(localStorage.getItem("refreshToken") as string)
        .then((response) => {
          commit("refreshSuccess", response);
        })
        .catch((error) => {
          commit("refreshFail");
          console.error(error);
          return dispatch("logout");
        });
    },
    changePassword: ({ commit, state }, payload: PasswordChangeRequest) => {
      commit("changePasswordRequest");
      alertFacade.resetAlert();
      customerServiceApi
        .changePassword(payload, state.user?.customerNumber as string)
        .then(() => {
          commit("changePasswordSuccess");
          router.push(getLanguageStaticRoute(ERoutes.PROFILE));
          SnackService.open({
            text: i18n.tc("USER_MODULE.PASSWORD_CHANGE_SUCCESS"),
          });
        })
        .catch(() => {
          commit("changePasswordFail");
        });
    },

    changeProfileDetails: (
      { commit, state },
      payload: Omit<ChangeProfileDetails, "customerId">
    ) => {
      commit("changeProfileDetailsRequest");

      const requestData = {
        ...payload,
        customerId: state.user?.customerNumber as string,
      };

      alertFacade.resetAlert();

      customerServiceApi
        .changeProfileDetails(requestData)
        .then(() => {
          commit("changeProfileDetailsSuccess");
          router.push(getLanguageStaticRoute(ERoutes.PROFILE));
          SnackService.open({
            text: i18n.tc("USER_MODULE.SAVE_PROFILE_SUCCESS"),
          });
        })
        .catch((error) => {
          commit("changeProfileDetailsFail");
          throw Error(error);
        });
    },

    changeEmail: ({ commit, state }, payload: ChangeEmailTokenRequestType) => {
      commit("changeEmailRequest");
      payload.originalEmail = state.user?.emailAddress as string;

      alertFacade.resetAlert();
      customerServiceApi
        .changeEmail(payload)
        .then((response) => {
          commit("changeEmailSuccess", response);
          commit("changeNewEmailAddress", payload.newEmail);
          router.push(
            getLanguageStaticRoute(ERoutes.CHANGE_EMAIL_ACTIVATION_EMAIL_SENT)
          );
        })
        .catch((error) => {
          commit("changeEmailFail");
          throw Error(error);
        });
    },

    changeEmailActivationTokenRequest: (
      { commit },
      payload: ChangeEmailActivationTokenRequest
    ) => {
      customerServiceApi
        .getChangeEmailToken(payload)
        .then(() => {
          commit(
            "saveEncryptedCustomerNumber",
            payload.customerNumberEncrypted
          );
          router.push({
            path: getLanguageStaticRoute(ERoutes.CHANGE_EMAIL_ACTIVATION),
          });
        })
        .catch(() => {
          router.push({
            path: getLanguageStaticRoute(
              ERoutes.CHANGE_EMAIL_ACTIVATION_EMAIL_FAILED
            ),
          });
        });
    },

    putChangeEmailActive: ({ commit, state }, payload: ChangeEmailRequest) => {
      commit("changeEmailActivationLoadingRequest");
      customerServiceApi
        .putChangeEmail(state.customerNumberEncrypted as string, payload)
        .then(() => {
          commit("changeEmailActivationLoadingSuccess");
          AuthService.resetStateAndSessionTokens();
          AuthService.clearSessionTimer();
          AuthService.clearRefreshExpirationTimer();

          alertFacade.setAlert({
            message: i18n.tc("USER_MODULE.ACTIVE_EMAIL"),
            type: EAlertType.SUCCESS,
          });
          router.push({
            path: getLanguageStaticRoute(ERoutes.LOGIN),
            query: { alert: "1" },
          });
        })
        .catch((error) => {
          commit("changeEmailActivationLoadingFail");
          if (error.response.data.faultType !== "WRONG_PASSWORD") {
            router.push({
              path: getLanguageStaticRoute(
                ERoutes.CHANGE_EMAIL_ACTIVATION_EMAIL_FAILED
              ),
            });
          }
        });
    },
    getCustomer: ({ commit, state }) => {
      commit("userRequest");
      customerServiceApi
        .getCustomer(state.user?.customerNumber as string)
        .then((response) => {
          commit("userSuccess", response?.basicRegistrationData);
          commit("setBillingAddress", response);
        })
        .catch((error) => {
          commit("userFail");
          throw Error(error);
        });
    },
    deleteCustomer: ({ commit, dispatch, state }) => {
      commit("deleteCustomerRequest");
      customerServiceApi
        .deleteCustomer(state.user?.customerNumber as string)
        .then(() => {
          commit("deleteCustomerSuccess");
          dispatch("logout");
        })
        .catch((error) => {
          commit("deleteCustomerFail");
          throw Error(error);
        });
    },

    getBillingAddress: ({ commit, state }, payload: string) => {
      const notLoggedInUser = state.notLoggedInUser;
      const customerId = notLoggedInUser ? "ADHOC" : state.user?.customerNumber;
      return customerServiceApi
        .getBillingAddress(customerId as string, payload)
        .then((response) => {
          commit("changeModifyBillingAddress", response);
        })
        .catch((error) => {
          throw Error(error);
        });
    },

    activateCustomer: (ctx, payload: string) => {
      customerServiceApi
        .activateCustomer(payload)
        .then(() => {
          alertFacade.setAlert({
            message: i18n.tc("USER_MODULE.ACTIVE_CUSTOMER"),
            type: EAlertType.SUCCESS,
          });
          router.push({
            path: getLanguageStaticRoute(ERoutes.LOGIN),
            query: { alert: "1" },
          });
        })
        .catch((error) => {
          router.push({
            path: getLanguageStaticRoute(ERoutes.LOGIN),
            query: { alert: "1" },
          });
          throw Error(error);
        });
    },
    deleteBillingAddress: ({ commit, dispatch, state }, payload: string) => {
      commit("deleteBillingAddressRequest");
      customerServiceApi
        .deleteBillingAddress(state.user?.customerNumber as string, payload)
        .then(() => {
          dispatch("getCustomer");
          SnackService.open({
            text: i18n.tc("USER_MODULE.DELETE_BILLING_ADDRESS_SUCCESS"),
          });
          commit("deleteBillingAddressSuccess");
        })
        .catch((error) => {
          commit("deleteBillingAddressFail");
          throw Error(error);
        });
    },
    createBillingAddress: (
      { commit, state },
      payload: Partial<CreateAndModifyBillingAddressFormState>
    ) => {
      commit("createBillingAddressRequest");
      const {
        billingName,
        country,
        zipCode,
        city,
        street,
        streetType,
        streetNumber,
        floor,
        door,
        vatCountry,
        vatNumber,
        invoiceGroupId,
        euVatNumber,
        thirdCountryVatNumber,
      } = payload;
      const requestData: CreateBillingAddressRequestType = {
        billingAddress: {
          corporation: !!payload.corporation,
          billingName,
          country,
          zipCode,
          city,
          street,
          streetType,
          streetNumber,
          ...(floor && { floor }),
          ...(door && { door }),
          ...(vatCountry && { vatCountry: vatCountry.id }),
          ...(vatNumber && { vatNumber }),
          ...(invoiceGroupId && { invoiceGroupId }),
          ...(euVatNumber && { euVatNumber }),
          ...(thirdCountryVatNumber && { thirdCountryVatNumber }),
        },
        defaultBillingAddress: !!payload.defaultBillingAddress,
      };

      return customerServiceApi
        .createBillingAddress(state.user?.customerNumber as string, requestData)
        .then(() => {
          commit("createBillingAddressSuccess");
          router.push(getLanguageStaticRoute(ERoutes.PROFILE));
          SnackService.open({
            text: i18n.tc("USER_MODULE.NEW_BILLLING_ADDRESS_SUCCESS"),
          });
        })
        .catch((error) => {
          commit("createBillingAddressFail");
          throw Error(error);
        });
    },
    modifyBillingAddress: (
      { commit, state },
      payload: Partial<CreateAndModifyBillingAddressFormState>
    ) => {
      commit("modifyBillingAddressRequest");
      const addressId = router.app.$route.params.addressId;
      const {
        billingName,
        country,
        zipCode,
        city,
        street,
        streetType,
        streetNumber,
        floor,
        door,
        vatCountry,
        vatNumber,
        invoiceGroupId,
        euVatNumber,
        thirdCountryVatNumber,
      } = payload;
      const requestData: CreateBillingAddressRequestType = {
        billingAddress: {
          corporation: !!payload.corporation,
          billingName,
          country,
          zipCode,
          city,
          street,
          streetType,
          streetNumber,
          ...(floor && { floor }),
          ...(door && { door }),
          ...(vatCountry && { vatCountry: vatCountry.id }),
          ...(vatNumber && { vatNumber }),
          ...(invoiceGroupId && { invoiceGroupId }),
          ...(euVatNumber && { euVatNumber }),
          ...(thirdCountryVatNumber && { thirdCountryVatNumber }),
        },
        defaultBillingAddress: !!payload.defaultBillingAddress,
      };

      customerServiceApi
        .modifyBillingAddress(
          state.user?.customerNumber as string,
          addressId,
          requestData
        )
        .then(() => {
          commit("modifyBillingAddressSuccess");
          router.push(getLanguageStaticRoute(ERoutes.PROFILE));
          SnackService.open({
            text: i18n.tc("USER_MODULE.BILLLING_ADDRESS_SUCCESS"),
          });
        })
        .catch((error) => {
          commit("modifyBillingAddressFail");
          throw Error(error);
        });
    },
    getValidZIPCode: ({ commit }, countryId) => {
      commit("validZIPCodeRequest");
      PublicServiceApi.getValidZIPCode(countryId)
        .then((response) => {
          commit("validZIPCodeSuccess", response);
        })
        .catch((error) => {
          commit("validZIPCodeFail");
          throw Error(error);
        });
    },
    getValidVATNumberCode: ({ commit }, countryId) => {
      commit("validVATNumberCodeRequest");
      PublicServiceApi.getValidVATNumber(countryId)
        .then((response) => {
          commit("validVATNumberCodeSuccess", response);
        })
        .catch((error) => {
          commit("validVATNumberCodeFail");
          throw Error(error);
        });
    },
    expiredPasswordChange: (
      { commit, state },
      payload: PasswordChangeRequest
    ) => {
      commit("expiredPasswordRequest");
      customerServiceApi
        .putChangeExpiredPassword(
          payload,
          state.expiredCustomerNumber as string
        )
        .then(() => {
          userFacade.setExpiredCustomerNumber(undefined);
          commit("expiredPasswordSuccess");
          SnackService.open({
            text: i18n.tc("PASSWORD_EXPIRED_PAGE.CHANGE_SUCCESS"),
          });
          router.push(getLanguageStaticRoute(ERoutes.LOGIN));
        })
        .catch((error) => {
          commit("expiredPasswordFail");
          throw Error(error);
        });
    },
    campaignMessages: ({ commit, dispatch, state }): Promise<void> => {
      const userId = state.user?.customerNumber as string;
      commit("campaignMessagesRequest");
      return customerServiceApi
        .getCampaignMessages(userId)
        .then((response) => {
          commit("campaignMessagesSuccess", response);
        })
        .catch((error) => {
          commit("campaignMessagesFail");
          dispatch("logout");
          throw Error(error);
        });
    },
    removeFirstCampaignMessageFromList: ({ commit, state }) => {
      const campaignList = state.campaignMessageList;
      campaignList.shift();
      // TODO: ennél a shiftelésnél kivizsgálni miért dob hibát
      commit("setCampaignMessageList", campaignList);
    },
    acceptCampaignMessageRequest: (
      { commit, state },
      campaignMessageId: string
    ) => {
      commit("acceptCampaignMessageRequest");
      return customerServiceApi
        .getAcknowledgeCampaignMessage(
          state.user?.customerNumber as string,
          campaignMessageId
        )
        .then(() => {
          commit("acceptCampaignMessageSuccess");
        })
        .catch((error) => {
          commit("acceptCampaignMessageFail");
          throw Error(error);
        });
    },

    setNotLoggedInUser: ({ commit }, payload: boolean) => {
      commit("setNotLoggedInUser", payload);
    },
    resetLogout: ({ commit }) => {
      commit("resetLogout");
    },
    setExpiredCustomerNumber: ({ commit }, payload: string) => {
      commit("setExpiredCustomerNumber", payload);
    },
  },
};
