import httpService from "../services/http.service";
import {
  GetTransactionResponse,
  StartTransactionRequest,
  StartTransactionResponse,
} from "@/core/dataTypes/types/vpos.interface";
import { userFacade } from "@/store/modules/user/user.facade";
import * as StickerService from "@/core/services/sticker.service";

export const startCartTransaction = (
  requestData: StartTransactionRequest
): Promise<StartTransactionResponse> => {
  const config = {
    headers: {
      ...(userFacade.notLoggedInUser() && {
        "X-ADHOC-ID": StickerService.getAdHocCartToken(),
        login: "ADHOC",
      }),
    },
  };
  return httpService
    .post<never>(`VPOSService/vpos/carttransaction`, requestData, config)
    .then((response) => response.data);
};

export const getTransactions = (
  trnId: string
): Promise<GetTransactionResponse> => {
  const config = {
    headers: {
      ...(userFacade.notLoggedInUser() && {
        "X-ADHOC-ID": StickerService.getAdHocCartToken(),
      }),
    },
  };
  return httpService
    .get<never>(`VPOSService/vpos/transaction/${trnId}`, config)
    .then((response) => response.data);
};
