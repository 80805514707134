import { AppState } from "@/store";
import { cloneDeep } from "lodash/fp";
import { Module } from "vuex";
import { KeyValue } from "@/core/dataTypes/types/cmsService.interface";
import { cmsServiceApi } from "@/core/api/cmsService.api";

export interface KeyValueState {
  data: KeyValue[];
  dataLoading: boolean;
}

export const initialKeyValueState: KeyValueState = {
  data: [],
  dataLoading: false,
};

export const keyvalueModule: Module<KeyValueState, AppState> = {
  namespaced: true,
  state: initialKeyValueState,
  mutations: {
    dataRequest: (state) => {
      state.dataLoading = true;
    },
    dataSuccess: (state, payload: KeyValue[]) => {
      state.data = payload;
      state.dataLoading = false;
    },
    dataFail: (state) => {
      state.dataLoading = false;
    },
    resetModule: (state) => {
      Object.assign(state, cloneDeep(initialKeyValueState));
    },
  },
  actions: {
    resetModule: ({ commit }) => {
      commit("resetModule");
    },
    getData: ({ commit }) => {
      commit("dataRequest");
      return cmsServiceApi
        .getKeyValueData()
        .then((response) => {
          commit("dataSuccess", response);
        })
        .catch((error) => {
          commit("dataFail");
          throw Error(error);
        });
    },
  },
};
