import store, { AppState } from "@/store";

const resetState = (): Promise<void> => store.dispatch("resetState");
const reloadState = (payload: AppState): Promise<void> =>
  store.dispatch("reloadState", payload);

export const storeFacade = {
  resetState,
  reloadState,
};
