





















import { defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  name: "SwitchesInput",
  props: {
    value: Boolean,
    label: String,
    id: {
      type: String,
      required: true,
    },
    color: String,
    disabled: Boolean,
    hideDetails: {
      type: [Boolean, String],
      default: false,
    },
    hint: String,
    loading: Boolean,
    readonly: Boolean,
    ariaLabel: String,
  },

  setup(props, { emit }) {
    const innerValue = ref(props.value);
    watch(
      () => props.value,
      (newVal, oldVal) => {
        if (oldVal !== newVal) {
          innerValue.value = newVal;
        }
      }
    );

    function onChange(value: boolean): void {
      emit("input", value);
    }
    return {
      innerValue,
      onChange,
    };
  },
});
