import VuexPersistence from "vuex-persist";
import Vue from "vue";
import Vuex from "vuex";
import { cloneDeep, isEmpty } from "lodash";
import {
  userModule,
  UserState,
  initialUserState,
} from "./modules/user/user.module";
import {
  alertModule,
  AlertState,
  initialAlertState,
} from "./modules/alert/alert.module";
import {
  initialRegistrationState,
  registrationModule,
  RegistrationState,
} from "./modules/registration/registration.module";
import {
  commonModule,
  CommonState,
  initialCommonState,
} from "./modules/common/common.module";

import {
  initialVehicleState,
  vehicleModule,
  VehicleState,
} from "./modules/vehicle/vehicle.module";

import {
  initialStickerState,
  stickerModule,
  StickerState,
} from "@/store/modules/sticker/sticker.module";

import {
  initialBillingAddressFormState,
  billingAddressFormModule,
  BillingAddressFormState,
} from "@/store/modules/billingAddressForm/billingAddressForm.module";
import {
  customModule,
  CustomState,
  initialCustomState,
} from "./modules/custom/custom.module";
import {
  keyvalueModule,
  initialKeyValueState,
  KeyValueState,
} from "@/store/modules/keyvalue/keyvalue.module";
import { Content } from "@/core/dataTypes/types/cmsService.interface";
import {
  AccessibilityState,
  initialAccessibilityState,
  accessibilityModule,
} from "@/store/modules/accessibility/accessibility.module";

Vue.use(Vuex);

export interface AppState {
  user?: UserState;
  alert?: AlertState;
  common?: CommonState;
  custom?: CustomState;
  registration?: RegistrationState;
  vehicle?: VehicleState;
  sticker?: StickerState;
  billingAddressForm?: BillingAddressFormState;
  keyvalue?: KeyValueState;
  currentLanguage?: string;
  isCmsMenu?: boolean;
  accessibility?: AccessibilityState;
}

const initialState: AppState = {
  user: initialUserState,
  alert: initialAlertState,
  common: initialCommonState,
  custom: initialCustomState,
  registration: initialRegistrationState,
  vehicle: initialVehicleState,
  sticker: initialStickerState,
  billingAddressForm: initialBillingAddressFormState,
  keyvalue: initialKeyValueState,
  accessibility: initialAccessibilityState,
  currentLanguage:
    localStorage.getItem("currLang") || process.env.VUE_APP_I18N_LOCALE,
};

const vuexPersist = new VuexPersistence<AppState>({
  strictMode: process.env.NODE_ENV !== "production",
  storage: localStorage,
  key: "ematrica",
  // reducer: (state) => ({ user: state.user })  // csak a user modult menti el
});

export default new Vuex.Store<AppState>({
  strict: process.env.NODE_ENV !== "production",
  mutations: {
    RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION,
    resetState: (state) => {
      const aszf = cloneDeep(state.common?.aszf);
      const dataProtection = cloneDeep(state.common?.dataProtection);
      const cookieContent = cloneDeep(state.common?.cookieContent);
      const pageTitle = cloneDeep(state.common?.pageTitle);
      const tenantData = cloneDeep(state.common?.tenantData);
      const menuItems = cloneDeep(state.common?.menuItems);
      const keyValues = cloneDeep(state.keyvalue?.data);
      const expiredCustomerNumber = state.user?.expiredCustomerNumber;
      Object.assign(state, cloneDeep(initialState));

      // on logout, we should still have the menu items and tenant data on the site
      if (state.common && !isEmpty(cookieContent)) {
        state.common.cookieContent = cookieContent as Content;
      }
      if (state.common && aszf != null) {
        state.common.aszf = aszf;
      }
      if (state.common && dataProtection != null) {
        state.common.dataProtection = dataProtection;
      }
      if (state.common && pageTitle != null) {
        state.common.pageTitle = pageTitle;
      }
      if (state.common && tenantData != null) {
        state.common.tenantData = tenantData;
      }
      if (state.common && menuItems != null) {
        state.common.menuItems = menuItems;
      }
      if (state.keyvalue && keyValues != null) {
        state.keyvalue.data = keyValues;
      }
      if (state.user && expiredCustomerNumber != null) {
        state.user.expiredCustomerNumber = expiredCustomerNumber;
      }
    },
    reloadState: (state, payload) => {
      Object.assign(state, cloneDeep(payload));
    },
    currentLanguage: (state, payload: string) => {
      state.currentLanguage = payload;
    },
  },
  actions: {
    resetState: ({ commit }) => {
      commit("resetState");
    },
    reloadState: ({ commit }, payload: AppState) => {
      commit("reloadState", payload);
    },
    setCurrentLanguage: ({ commit }, languageCode: string) => {
      commit("currentLanguage", languageCode);
    },
  },
  modules: {
    user: userModule,
    alert: alertModule,
    common: commonModule,
    custom: customModule,
    registration: registrationModule,
    vehicle: vehicleModule,
    sticker: stickerModule,
    billingAddressForm: billingAddressFormModule,
    keyvalue: keyvalueModule,
    accessibility: accessibilityModule,
  },
  plugins: [vuexPersist.plugin],
});
