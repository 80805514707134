















































































import { defineComponent, reactive } from "@vue/composition-api";
import TextInput from "@/components/forms/input/TextInput.vue";
import PasswordValidator from "@/components/passwordValidator/PasswordValidator.vue";
import { registrationFacade } from "@/store/modules/registration/registration.facade";
import { cryptoSHA1 } from "@/core/utils/crypto/cryptoHelpers";

export default defineComponent({
  name: "UserPasswordForm",
  components: {
    TextInput,
    PasswordValidator,
  },
  setup(props, { emit }) {
    const formValues = reactive({
      password: "",
      confirmation: "",
    });
    const nextStep = () => {
      registrationFacade.saveRegistrationForm({
        password: cryptoSHA1(formValues.password),
      });
      emit("changeStep", 3);
    };
    const prevStep = () => {
      emit("changeStep", 1);
    };
    return {
      nextStep,
      prevStep,
      formValues,
    };
  },
});
