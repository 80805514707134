



















































import { dialogCloseEmitter } from "@/core/utils/dialog/dialogEmitter";
import { computed, defineComponent, onUnmounted } from "@vue/composition-api";
import { userFacade } from "@/store/modules/user/user.facade";
import AuthService from "@/core/services/auth.service";
import { alertFacade } from "@/store/modules/alert/alert.facade";
import { EAlertType } from "@/core/dataTypes/enum";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "SessionExpiredDialog",
  setup(props, ctx) {
    const logoutLoading = computed(userFacade.logoutLoading);
    const refreshLoading = computed(userFacade.refreshLoading);

    const timerTime = AuthService.refreshPendingTimeInMinutes * 1000 * 60;

    const renewal = (): void => {
      userFacade
        .refresh()
        .then(() => dialogCloseEmitter(ctx))
        .catch(() => dialogCloseEmitter(ctx));
    };

    const checkOut = (): void => {
      userFacade.logout().then(() => dialogCloseEmitter(ctx));
    };

    const timer = setTimeout(() => {
      userFacade.logout().then(() => {
        dialogCloseEmitter(ctx);
        alertFacade.setAlert({
          message: i18n.tc("SESSION_EXPIRED_DIALOG.MESSAGE4"),
          type: EAlertType.ERROR,
        });
      });
    }, timerTime);

    onUnmounted(() => {
      clearTimeout(timer);
    });

    return {
      checkOut,
      renewal,
      logoutLoading,
      refreshLoading,
    };
  },
});
