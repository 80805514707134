import axios, { AxiosError } from "axios";
import SnackService from "@/core/services/snackbar.service";
import { i18n } from "@/locales/i18n";

export const httpCMSErrorHandler = (error: AxiosError): void => {
  try {
    if (axios.isCancel(error)) return;
    SnackService.open({
      text: i18n.tc("HTTP_ERRORS.CMS_ERROR"),
    });
  } catch (error) {
    SnackService.open({
      text: i18n.tc("HTTP_ERRORS.CMS_ERROR"),
    });
  }
};
