





























































import {
  defineComponent,
  computed,
  ref,
  onMounted,
} from "@vue/composition-api";
import { isEmpty } from "lodash";
import { Route } from "vue-router";
import router from "@/router";
import Header from "@/components/headers/Header/Header.vue";
import Footer from "@/components/headers/Footer/Footer.vue";
import SnackbarWrapper from "@/components/snackbar/snackbarWrapper/SnackbarWrapper.vue";
import { CmsLocalization } from "@/core/dataTypes/types/cmsService.interface";
import { alertFacade } from "@/store/modules/alert/alert.facade";
import { commonFacade } from "@/store/modules/common/common.facade";
import { visibleRecaptchaLogo } from "@/core/utils/captcha";
import isAfter from "date-fns/isAfter";
import addDays from "date-fns/addDays";
import Alert from "@/components/alert/Alert.vue";
import CookieAlert from "@/components/cookieAlert/CookieAlert.vue";
import { accessibilityFacade } from "@/store/modules/accessibility/accessibility.facade";
import AuthService from "@/core/services/auth.service";
import { ERoutes } from "@/core/dataTypes/enum";
import HTMLContentRenderer from "@/components/HTMLContentRenderer/HTMLContentRenderer.vue";
import { keyvalueFacade } from "@/store/modules/keyvalue/keyvalue.facade";
import { cmsServiceApi } from "@/core/api/cmsService.api";

export default defineComponent({
  components: {
    Footer,
    Header,
    SnackbarWrapper,
    Alert,
    CookieAlert,
    HTMLContentRenderer,
  },
  setup() {
    accessibilityFacade.setDialogOpen(false);
    accessibilityFacade.setTopDialogLevel(-1);
    const dialogOpen = computed(accessibilityFacade.dialogOpen);
    const informationPanelContent = ref({});
    const alert = computed(alertFacade.alert);
    const visibleInDialog = computed(alertFacade.visibleInDialog);
    const tenant = computed(commonFacade.tenantData);
    const lang =
      commonFacade.currentLanguage() || process.env.VUE_APP_I18N_LOCALE;
    const path = ref(
      `/${lang}/${
        AuthService.hasValidToken() ? ERoutes.DASHBOARD : ERoutes.LOGIN
      }`
    );
    const currentLanguage = computed(commonFacade.currentLanguage);
    const isCmsMenu = computed(commonFacade.isCmsMenu);

    const cookieAlertVisibility = ref(false);
    const cookieAcceptedTime = localStorage.getItem("nuszCookieAcceptedDate");
    const envName = ref(process.env.VUE_APP_ENVIRONMENT);
    const cookieInit = () => {
      if (
        !cookieAcceptedTime ||
        (cookieAcceptedTime &&
          isAfter(new Date(), addDays(new Date(cookieAcceptedTime), 30)))
      ) {
        localStorage.removeItem("nuszCookieAcceptedDate");
        cookieAlertVisibility.value = true;
      }
    };

    const hideCookieAlert = () => {
      cookieAlertVisibility.value = false;
    };

    router.beforeEach((to: Route, from: Route, next) => {
      // path.value = to.path;
      if (!to.query?.alert) {
        alertFacade.resetAlert();
      }
      next();
    });
    router.afterEach((to: Route) => {
      if (to.query?.alert) {
        const modifiedQuery = to.query;
        delete modifiedQuery.alert;
        history.pushState(modifiedQuery, "", to.path);
      }
      visibleRecaptchaLogo();
    });
    const getInformationPanelContent = () => {
      const keyValue = keyvalueFacade.getInformationPanelId()?.value;
      cmsServiceApi
        .getInformationPanelContent(keyValue as string)
        .then((res) => {
          informationPanelContent.value = res.data[0];
        });
    };

    onMounted(() => {
      getInformationPanelContent();
      alertFacade.resetAlert();
      commonFacade.setLinkData();
      if (!cookieAcceptedTime) {
        commonFacade.getCookieContent().then((hasCookie) => {
          if (hasCookie) {
            cookieInit();
          }
        });
      }
    });
    return {
      tenant,
      currentLanguage,
      cookieAlertVisibility,
      alert,
      visibleInDialog,
      path,
      dialogOpen,
      isCmsMenu,
      hideCookieAlert,
      envName,
      informationPanelContent,
      isEmpty,
    };
  },
  metaInfo() {
    const lang = this.currentLanguage as string;
    const favicon = this.tenant?.icon?.url as string;
    const description = this.tenant?.description as string;
    const localization = this.tenant?.localization as CmsLocalization[];
    const title = localization.find(
      (item: { languageCode: string }) => item.languageCode === lang
    )?.websiteTitle;
    return {
      title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description,
        },
      ],
      link: [{ rel: "icon", href: favicon }],
      htmlAttrs: { lang },
    };
  },
});
