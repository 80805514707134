var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"main-content-padding"},[_c('div',{staticClass:"sticker-purchase-content"},[(_vm.transactionsPaymentLoading)?_c('div',{staticClass:"px-4 px-sm-0 pb-8 text-center"},[_c('v-progress-circular',{attrs:{"size":"50","width":"2","color":"blue","indeterminate":""}}),_c('div',{staticClass:"pt-8 text-h4 blue--text text--darken-3",attrs:{"role":"status"}},[_vm._v(" "+_vm._s(_vm.$t("STICKER_PAY.INPROGRESS_MESSAGE1"))+" ")]),(!_vm.transactionsPaymentProgressLongTime)?[_c('div',{staticClass:"pt-4 text-body-2 grey--text text--darken-2",attrs:{"role":"status"}},[_vm._v(" "+_vm._s(_vm.$t("STICKER_PAY.INPROGRESS_MESSAGE2"))+" ")])]:[_c('div',{staticClass:"pt-4 text-body-2 grey--text text--darken-2",domProps:{"innerHTML":_vm._s(
            _vm.$t('STICKER_PAY.INPROGRESS_TIMEOUT', {
              cartId: _vm.cartId,
              transactionId: _vm.transactionId,
            })
          )}})]],2):_vm._e(),_c('div',{staticClass:"stepper-content px-4 px-sm-0 pb-8 pb-sm-6"},[_c('div',{staticClass:"stepper-pointer-content"},[_c('div',{staticClass:"stepper-pointer",class:{
            active: _vm.step >= 1,
            complete: _vm.step >= 2,
          }}),_c('div',{staticClass:"stepper-line",class:{ complete: _vm.step >= 2 }}),_c('div',{staticClass:"stepper-pointer",class:{
            active: _vm.step >= 2,
            complete: _vm.step >= 3,
          }}),_c('div',{staticClass:"stepper-line",class:{ complete: _vm.step >= 3 }}),_c('div',{staticClass:"stepper-pointer",class:{
            active: _vm.step === 3,
            complete: _vm.step >= 4,
          }})]),_c('ul',{staticClass:"stepper-step-content pt-4 d-none d-sm-flex justify-sm-space-between"},[_c('li',{staticClass:"stepper-step text-subtitle-2 grey--text darken-2",class:{ active: _vm.step >= 1 }},[_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(_vm.$t("STICKER_PURCHASE_PAGE.STEPPER1_PRE_TEXT")))]),_vm._v(" "+_vm._s(_vm.$t("STICKER_PURCHASE_PAGE.STEPPER1"))+" "),(_vm.step === 1)?_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(_vm.$t("STICKER_PURCHASE_PAGE.STEPPER_ACTIVE")))]):_vm._e()]),_c('li',{staticClass:"stepper-step text-subtitle-2 grey--text darken-2",class:{ active: _vm.step >= 2 }},[_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(_vm.$t("STICKER_PURCHASE_PAGE.STEPPER2_PRE_TEXT")))]),_vm._v(" "+_vm._s(_vm.$t("STICKER_PURCHASE_PAGE.STEPPER2"))+" "),(_vm.step === 2)?_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(_vm.$t("STICKER_PURCHASE_PAGE.STEPPER_ACTIVE")))]):_vm._e()]),_c('li',{staticClass:"stepper-step text-subtitle-2 grey--text darken-2",class:{ active: _vm.step >= 3 }},[_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(_vm.$t("STICKER_PURCHASE_PAGE.STEPPER3_PRE_TEXT")))]),_vm._v(" "+_vm._s(_vm.$t("STICKER_PURCHASE_PAGE.STEPPER3"))+" "),(_vm.step === 3)?_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(_vm.$t("STICKER_PURCHASE_PAGE.STEPPER_ACTIVE")))]):_vm._e()])])])]),[_c('router-view',{on:{"changeStep":_vm.onChangeStep}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }