import { commonFacade } from "@/store/modules/common/common.facade";

export const getLanguageStaticRoute = (route: string): string => {
  // console.log('getLanguageStaticRoute', "/" + commonFacade.currentLanguage() + "/" + route);
  return "/" + commonFacade.currentLanguage() + "/" + route;
};

export const memoizedSlugs = (): ((path: string) => string | undefined) => {
  const cache: { [key: string]: string | undefined } = {};
  return (path: string): string | undefined => {
    if (path in cache) {
      return cache[path];
    }
    const slugs = path.split("/");
    const slug = slugs[slugs.length - 1]
      ? slugs[slugs.length - 1]
      : slugs[slugs.length - 2];

    if (["hu", "en", "de"].includes(slug)) {
      cache[path] = undefined;
      return;
    }
    cache[path] = slug;
    return slug;
  };
};
