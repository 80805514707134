














































































































































































































































































































































































































































import {
  computed,
  defineComponent,
  reactive,
  watch,
  ref,
  SetupContext,
} from "@vue/composition-api";
import { CreateAndModifyBillingAddressFormState } from "@/core/dataTypes/types";
import CheckboxInput from "@/components/forms/checkbox/CheckboxInput.vue";
import TextInput from "@/components/forms/input/TextInput.vue";
import BillingTypeSelector from "@/components/forms/billingTypeSelector/BillingTypeSelector.vue";
import AutocompleteInput from "@/components/forms/autocomplete/AutocompleteInput.vue";
import SwitchesInput from "@/components/forms/switch/SwitchesInput.vue";
import Combobox from "@/components/forms/combobox/Combobox.vue";
import { billingAddressFormFacade } from "@/store/modules/billingAddressForm/billingAddressForm.facade";
import { commonFacade } from "@/store/modules/common/common.facade";
import { validationRegExp } from "@/core/utils/validation/validationRegExp";

export default defineComponent({
  name: "NewBillingDetailsForm",
  components: {
    TextInput,
    BillingTypeSelector,
    AutocompleteInput,
    Combobox,
    CheckboxInput,
    SwitchesInput,
  },
  props: {
    form: {
      type: Object, // CreateAndModifyBillingAddressFormState
      required: true,
    },
    submitLabel: String,
    loading: Boolean,
    defaultCheckBox: Boolean,
    pageId: {
      type: String,
      default: "",
    },
  },
  setup(
    props,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { emit, refs }: Omit<SetupContext, "refs"> & { [key: string]: any }
  ) {
    const countries = computed(commonFacade.countries);
    const countriesLoading = computed(commonFacade.countriesLoading);
    const publicPlaces = computed(commonFacade.publicPlaces);
    const publicPlacesLoading = computed(commonFacade.publicPlacesLoading);

    const validZIPCode = computed(billingAddressFormFacade.validZIPCode);
    const validZIPCodeLoading = computed(
      billingAddressFormFacade.validZIPCodeLoading
    );
    const validVATNumber = computed(billingAddressFormFacade.validVATNumber);
    const validVATNumberLoading = computed(
      billingAddressFormFacade.validVATNumberLoading
    );

    const invoiceGroupIdIsVisible = ref<boolean>(false);
    const formValues = reactive<
      Partial<CreateAndModifyBillingAddressFormState>
    >(props.form);

    watch(
      () => countries.value,
      (countries) => {
        formValues.country = countries?.length ? countries[0].id : "";
        if (formValues.corporation) {
          formValues.vatCountry = countries?.length ? countries[0] : undefined;
        }
      }
    );

    watch(
      () => formValues.vatCountry,
      (vatCountry, oldVatCountry) => {
        if (vatCountry !== oldVatCountry) {
          if (vatCountry) {
            billingAddressFormFacade.getValidVATNumberCode(vatCountry.id);
          }
          formValues.vatNumber = "";
          formValues.invoiceGroupId = "";
          formValues.euVatNumber = "";
          formValues.thirdCountryVatNumber = "";
        }
      }
    );

    watch(
      () => formValues.vatNumber,
      (newVal, oldVal) => {
        if (newVal !== oldVal && formValues.vatNumber) {
          const splitTaxNumber: string[] = formValues.vatNumber.split("-");
          invoiceGroupIdIsVisible.value = splitTaxNumber[1] === "4";
        }
      }
    );

    const billingTypeChanged = () => {
      if (formValues.corporation) {
        formValues.vatCountry = countries.value?.length
          ? countries.value[0]
          : undefined;
      } else {
        formValues.vatCountry = undefined;
        formValues.vatNumber = "";
        formValues.invoiceGroupId = "";
        formValues.euVatNumber = "";
        formValues.thirdCountryVatNumber = "";
        formValues.hasNotEUTaxNumber = false;
      }
    };

    const hasNotEUTaxNumberChanged = () => {
      formValues.euVatNumber = "";
      formValues.thirdCountryVatNumber = "";
    };

    const countrySelectionChanged = () => {
      if (formValues.country) {
        billingAddressFormFacade.getValidZIPCode(formValues.country);
      }
    };

    const vatNumberChanged = () => {
      if (refs.form?.fields?.vatNumber?.valid) {
        formValues.invoiceGroupId = "";
      }
    };

    const submit = () => {
      emit("onSubmit");
    };

    commonFacade.getCountries();
    commonFacade.getPublicPlaces();

    return {
      formValues,
      countries,
      countriesLoading,
      publicPlaces,
      publicPlacesLoading,
      validZIPCode,
      validZIPCodeLoading,
      validVATNumber,
      validVATNumberLoading,
      invoiceGroupIdIsVisible,
      validationRegExp,
      hasNotEUTaxNumberChanged,
      countrySelectionChanged,
      vatNumberChanged,
      billingTypeChanged,
      submit,
    };
  },
});
