import { uuid } from "@/core/utils/uuid";
import { SnackbarOptions } from "@/core/dataTypes/types";

class SnackService {
  snackbars: SnackbarOptions[] = [];

  open(snackbarOptions: Omit<SnackbarOptions, "id">) {
    const options = {
      ...snackbarOptions,
      id: uuid(),
    };

    this.snackbars.push(options);
  }

  close(id: string): void {
    this.snackbars = this.snackbars.filter((i) => i.id !== id);
  }
}

export default new SnackService();
